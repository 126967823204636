import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { apiGatewayService } from '../services/index';
import { questionService } from '../services/questionService';
import { CheckBadgeIcon, XMarkIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';

interface LoadingOverlayProps {
  message?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ message = 'Processing...' }) => (
  <div className="absolute inset-0 bg-white flex items-center justify-center z-50">
    <div className="flex flex-col items-center space-y-4">
      <div className="animate-spin rounded-full h-9 w-9 border-[2.5px] border-gray-200 border-t-gray-600"></div>
      <div className="text-base text-gray-600 font-normal">{message}</div>
    </div>
  </div>
);

// Updated interface to match the AI service response format
interface Option {
  text: string;
  id?: string;
}

interface TextAnswer {
  type: 'plain_text' | 'form_field';
  field_id?: string;
  placeholder?: string;
  content?: string;
  location: string;
}

interface Selection {
  type: 'dropdown' | 'checkbox';
  field_id?: string;
  options: Option[];
  location: string;
}

interface QuestionItem {
  id?: string;
  index?: number;
  question: string;
  text_answer: TextAnswer | null;
  selection: Selection | null;
  // Keep the old fields for backward compatibility, if needed
  tag?: string;
  title?: string;
  text?: string;
  type?: string;
  placeholderText?: string;
  answer?: string;
  options?: {
    value: string;
    displayText: string;
  }[];
}

export const QuestionImportReview: React.FC = () => {
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [questions, setQuestions] = useState<QuestionItem[]>([]);
  const [documentText, setDocumentText] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [showRawOutput, setShowRawOutput] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchQuestionnaireData = async () => {
      if (!questionnaireId) {
        setError('No questionnaire ID provided');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        
        // Get the raw text content directly
        const textResponse = await apiGatewayService.request({
          method: 'GET',
          url: `/api/word/${questionnaireId}/text`
        });

        if (textResponse.data && textResponse.data.text) {
          setDocumentText(textResponse.data.text);
        } else {
          console.warn('No text content found in the response');
          setError('No text content found in the document');
        }

        // Also get the processed questions for reference
        const processResponse = await apiGatewayService.request({
          method: 'GET',
          url: `/api/word/${questionnaireId}/process`
        });

        if (processResponse.data && processResponse.data.questions) {
          setQuestions(processResponse.data.questions);
        }
      } catch (error: any) {
        console.error('Error fetching questionnaire data:', error);
        setError(error.response?.data?.message || 'Failed to process Word document');
        toast.error('Failed to process Word document');
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionnaireData();
  }, [questionnaireId]);

  const handleContinue = async () => {
    if (!questionnaireId || !questions.length) {
      toast.error('No questions found to save');
      return;
    }

    try {
      setSubmitting(true);

      // Transform the questions to the expected format for the database
      const mappedQuestions = questions.map(question => {
        // Create the excelLocation object to store field locations and IDs
        const excelLocation: Record<string, any> = {};
        
        // Add text answer location if exists
        if (question.text_answer) {
          excelLocation.answer = {
            type: question.text_answer.type,
            location: question.text_answer.location,
            ...(question.text_answer.field_id && { field_id: question.text_answer.field_id })
          };
        }
        
        // Add selection/dropdown location if exists
        if (question.selection) {
          excelLocation.dropdown = {
            type: question.selection.type,
            location: question.selection.location,
            field_id: question.selection.field_id,
            options: question.selection.options.map(opt => ({
              id: opt.id || undefined,
              text: opt.text
            }))
          };
        }
        
        // Transform the question to match the MappedQuestion interface
        return {
          questionText: question.question,
          answerText: null, // Keep empty as requested
          additionalInfo: null, // Keep empty as requested
          dropdownOptions: question.selection?.options.map(opt => opt.text) || null,
          excelLocation
        };
      });
      
      // Save the mapped questions to the database
      await questionService.confirmMapping(questionnaireId, mappedQuestions);
      
      
      
      // Invalidate the questionnaires query before navigating
      queryClient.invalidateQueries({ queryKey: ['questionnaires'] });
      
      // Navigate back to questionnaire overview page
      navigate('/questionnaires');
    } catch (error: any) {
      console.error('Error saving questions:', error);
      toast.error(error.message || 'Failed to save questions');
    } finally {
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    navigate('/questionnaires');
  };

  const handleRemoveQuestion = (idx: number) => {
    setQuestions(prevQuestions => prevQuestions.filter((_, index) => index !== idx));
  };

  return (
    <div className="bg-white h-screen flex flex-col relative">
      {loading ? (
        <LoadingOverlay message="Processing Word document..." />
      ) : submitting ? (
        <LoadingOverlay message="Saving questions..." />
      ) : error ? (
        <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-6 max-w-7xl mx-auto">
          <div className="flex">
            <div className="flex-shrink-0">
              <XMarkIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error processing document</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  onClick={handleBack}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <ArrowLeftIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  Back to Questionnaires
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full max-w-7xl mx-auto">
            <div className="flex-shrink-0 p-4 border-b">
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="text-2xl font-semibold text-gray-900">Word Document Questions</h1>
                  <p className="mt-1 text-sm text-gray-500">Review the extracted questions of your Word document and click on Confirm to continue.</p>
                </div>
                <div>
                  <button
                    onClick={handleContinue}
                    disabled={submitting}
                    className="inline-flex items-center px-6 py-2.5 rounded-lg text-sm font-medium 
                    bg-[#1e8259] hover:bg-[#1e8259]/90 text-white shadow-sm hover:shadow-md 
                    active:scale-95 transition-all duration-200 min-w-[140px] justify-center disabled:opacity-70 disabled:cursor-not-allowed"
                  >
                    {submitting ? 'Saving...' : 'Confirm Questions'}
                    <CheckBadgeIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Questions Section - Title removed */}
          <div className="w-full max-w-7xl mx-auto mt-6">
            <div className="mb-6">
              <ul className="space-y-4 px-4">
                {questions.length === 0 ? (
                  <li className="px-6 py-4 text-center text-gray-500">
                    No questions found in this document
                  </li>
                ) : (
                  questions.map((question, idx) => (
                    <li key={question.id || idx} className="px-6 py-4 bg-gray-50 rounded-lg relative">
                      {/* Order number positioned absolutely and centered vertically */}
                      <div className="absolute left-6 top-0 bottom-0 flex items-center">
                        <span className="flex-shrink-0 w-8 h-8 rounded-full border border-gray-300 flex items-center justify-center">
                          <span className="text-sm text-gray-600 font-medium">
                            {question.index !== undefined ? question.index + 1 : idx + 1}
                          </span>
                        </span>
                      </div>
                      
                      {/* Question content with padding to accommodate the number */}
                      <div className="pl-14 relative">
                        {/* X button for removing question */}
                        <button
                          onClick={() => handleRemoveQuestion(idx)}
                          className="p-1 hover:bg-gray-200 rounded-full transition-colors absolute right-0 top-0"
                          title="Remove question"
                        >
                          <XMarkIcon className="w-5 h-5 text-gray-500 hover:text-gray-700" />
                        </button>

                        {/* Display question text as prominent headline */}
                        <h3 className="text-base font-medium text-gray-800 mb-2 pr-12">
                          {question.question || question.title || question.text || "Untitled Question"}
                        </h3>
                        
                        <div className="space-y-3">
                          {/* Display selection/dropdown options in horizontal format with location */}
                          {question.selection && (
                            <div className="bg-white p-2.5 rounded text-sm">
                              <span className="font-medium text-gray-700">
                                {question.selection.type === 'dropdown' ? 'Dropdown' : 'Checkbox'}
                                {question.selection.location && (
                                  <span className="font-normal"> ({question.selection.location})</span>
                                )}:
                              </span>
                              <span className="ml-1 text-gray-600">
                                {question.selection.options && question.selection.options.length > 0 
                                  ? question.selection.options.map(opt => `"${opt.text}"`).join(', ')
                                  : 'No options available'}
                              </span>
                            </div>
                          )}
                          
                          {/* Display text answer with location */}
                          {question.text_answer && (
                            <div className="bg-white p-2.5 rounded text-sm">
                              <span className="font-medium text-gray-700">
                                {question.text_answer.type === 'form_field' ? 'Form Field' : 'Text'}
                                {question.text_answer.location && (
                                  <span className="font-normal"> ({question.text_answer.location})</span>
                                )}
                              </span>
                              
                              {question.text_answer.content && (
                                <div className="mt-1 text-gray-600">
                                  {question.text_answer.content}
                                </div>
                              )}
                              
                              {question.text_answer.placeholder && (
                                <div className="mt-1 text-gray-500 italic">
                                  Placeholder: {question.text_answer.placeholder}
                                </div>
                              )}
                            </div>
                          )}
                          
                          {/* Legacy format handling */}
                          {!question.selection && !question.text_answer && question.type && (
                            <div className="bg-white p-2.5 rounded text-sm">
                              <span className="font-medium text-gray-700">
                                {question.type.charAt(0).toUpperCase() + question.type.slice(1)}
                              </span>
                              
                              {question.options && question.options.length > 0 && (
                                <span className="ml-1 text-gray-600">
                                  : {question.options.map(opt => `"${opt.displayText || opt.value}"`).join(', ')}
                                </span>
                              )}
                              
                              {question.placeholderText && (
                                <div className="mt-1 text-gray-500 italic">
                                  Placeholder: {question.placeholderText}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>

          {/* Raw Document Text Section - Now at the bottom with toggle */}
          <div className="w-full max-w-7xl mx-auto">
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Raw Parser Output (das kommt später weg - ist nur für Testing)</h3>
                <div>
                  <button
                    onClick={() => setShowRawOutput(!showRawOutput)}
                    className="text-sm text-gray-600 hover:text-gray-900"
                  >
                    {showRawOutput ? 'Hide Raw Output' : 'Show Raw Output'}
                  </button>
                </div>
              </div>
              {showRawOutput && (
              <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
                {documentText ? (
                  <div className="bg-gray-50 p-4 rounded-md">
                    <pre className="text-sm whitespace-pre-wrap font-mono overflow-auto max-h-[70vh]">{documentText}</pre>
                  </div>
                ) : (
                  <p className="text-gray-500 italic">No text content available</p>
                )}
              </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionImportReview; 