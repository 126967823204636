import axios, { 
  AxiosInstance, 
  AxiosRequestConfig, 
  AxiosResponse,
  AxiosHeaders 
} from 'axios';

// Add these global variables at the top of the file (after imports)
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const subscribeTokenRefresh = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = (token: string) => {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
};

// Import the existing interface
interface RequestConfig extends AxiosRequestConfig {
  skipAuth?: boolean;
}

export class ApiGatewayService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL: baseURL || '',
      withCredentials: true,
      headers: new AxiosHeaders(),
      timeout: 60000,
      validateStatus: (status) => status < 500
    });

    // Add response interceptor
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        // Check if error indicates expired JWT
        if (
          error.response && 
          error.response.status === 401 && 
          error.response.data && 
          error.response.data.error && 
          error.response.data.error.toLowerCase().includes('jwt expired')
        ) {
          if (!isRefreshing) {
            isRefreshing = true;
            // Attempt to get email from localStorage (assuming it's stored as part of session info)
            let email: string | null = null;
            try {
              const userStr = localStorage.getItem('user');
              if (userStr) {
                const userObj = JSON.parse(userStr);
                email = userObj.email || null;
              }
            } catch (err) {
              console.error('[ApiGatewayService] Error parsing user from localStorage:', err);
            }

            if (!email) {
              console.error('[ApiGatewayService] No user email found in localStorage for token refresh');
              // If email is not found, dispatch session expired and reject
              const event = new CustomEvent('session-expired');
              window.dispatchEvent(event);
              return Promise.reject(error);
            }

            // Call the refresh token endpoint
            return this.api.post('/api/auth/refresh-token', { email })
              .then((response) => {
                isRefreshing = false;
                // Optionally, if the response contains a new access token, pass it along (cookies are updated by backend)
                const newToken = response.data.accessToken || '';
                console.log('[ApiGatewayService] Refreshed token received:', newToken);
                onRefreshed(newToken);
                // Retry the original request
                return this.api(originalRequest);
              })
              .catch((err) => {
                isRefreshing = false;
                console.error('[ApiGatewayService] Refresh token endpoint error:', err);
                const event = new CustomEvent('session-expired');
                window.dispatchEvent(event);
                return Promise.reject(err);
              });
          } else {
            // If refresh is already in progress, queue the request
            console.log('[ApiGatewayService] Refresh token already in progress, queuing request.');
            return new Promise((resolve) => {
              subscribeTokenRefresh((token: string) => {
                console.log('[ApiGatewayService] Token refresh subscriber callback fired with token:', token);
                // Retry original request after refresh
                resolve(this.api(originalRequest));
              });
            });
          }
        }

        // For non-token-expired errors, if status is 401 or 403, dispatch session expired
        if (error.response?.status === 401 || error.response?.status === 403) {
          const event = new CustomEvent('session-expired');
          window.dispatchEvent(event);
        }

        return Promise.reject(error);
      }
    );
  }

  setCustomTimeout(ms: number) {
    this.api.defaults.timeout = ms;
  }

  async request<T = any>(config: RequestConfig): Promise<AxiosResponse<T>> {
    try {
      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }
      
      return await this.api(config);
    } catch (error: any) {
      if (error.code === 'ERR_NETWORK') {
        console.warn('Network error, server might be unavailable');
        throw new Error('Unable to connect to server');
      }
      throw error;
    }
  }
} 