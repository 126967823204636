import React, { createContext, useContext, useState, useEffect } from 'react';
import { ApiGatewayService } from '../services/apiGatewayService';
import { CsrfService } from '../services/CsrfService';
import { AuthService } from '../services/AuthService';
import { AuthSession, SessionUser, AuthResult } from '../types/authentication';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

// Public routes that don't need session checks
const PUBLIC_ROUTES = [
  '/login',
  '/signup',
  '/forgot-password',
  '/reset-password',
  '/verify-email',
  '/set-password',  // For first-time password setup
  '/confirm-email'  // For email confirmation
];

interface AuthContextType {
  user: SessionUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  signIn: (
    email: string,
    password: string,
    rememberDevice: boolean,
    newPassword?: string,
    userInfo?: { firstName: string; lastName: string },
    mfaCode?: string,
    cognitoSession?: string | null
  ) => Promise<AuthResult>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string, userData: {
    givenName: string;
    familyName: string;
    organizationName: string;
  }) => Promise<void>;
  verifyEmail: (email: string, code: string) => Promise<void>;
  resendVerificationCode: (email: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  confirmPasswordReset: (email: string, code: string, newPassword: string) => Promise<void>;
  updateUserAttributes: (attributes: { [key: string]: string }) => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
  getCurrentSession: () => Promise<AuthSession | null>;
  setupMFA: () => Promise<{ secretCode: string; qrCodeUrl: string }>;
  verifyAndEnableMFA: (code: string) => Promise<void>;
  disableMFA: (code: string) => Promise<void>;
  getMFAStatus: () => Promise<{ enabled: boolean }>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<SessionUser | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  // Create persistent service instances using useRef
  const servicesRef = React.useRef<{
    gateway: ApiGatewayService;
    csrfService: CsrfService;
    authService: AuthService;
  } | null>(null);

  if (!servicesRef.current) {
    const gateway = new ApiGatewayService(import.meta.env.VITE_API_URL || '');
    const csrfService = new CsrfService(gateway);
    const authService = new AuthService(gateway, csrfService);
    servicesRef.current = { gateway, csrfService, authService };
  }

  const { authService } = servicesRef.current;

  // Cleanup service intervals on unmount
  useEffect(() => {
    return () => {
      servicesRef.current?.authService.clearIntervals();
    };
  }, []);

  // Check if current route is public
  const isPublicRoute = PUBLIC_ROUTES.some(route => 
    location.pathname.split('?')[0].startsWith(route)
  );

  useEffect(() => {
    // Immediately set state for public routes
    if (isPublicRoute) {
      setIsLoading(false);
      setIsAuthenticated(false);
      setUser(null);
      return;
    }

    const initializeAuth = async () => {
      // Skip if we already have a valid session
      if (isAuthenticated && user) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const session = await authService.getCurrentSession();
        if (session?.user) {
          setUser(session.user);
          setIsAuthenticated(true);
        } else {
          navigate('/login', { 
            replace: true,
            state: { from: location.pathname }
          });
        }
      } catch (error: any) {
        if (error?.response?.status !== 401) {
          console.error('Auth initialization error:', error);
        }
        navigate('/login', { 
          replace: true,
          state: { from: location.pathname }
        });
      } finally {
        setIsLoading(false);
      }
    };

    // Handle session expiry
    const handleSessionExpired = () => {
      // Skip for public routes
      if (isPublicRoute) return;

      queryClient.clear();
      setUser(null);
      setIsAuthenticated(false);
      navigate('/login', { 
        replace: true,
        state: { from: location.pathname }
      });
    };

    window.addEventListener('session-expired', handleSessionExpired);
    
    // Only run auth initialization for non-public routes
    if (!isPublicRoute) {
      initializeAuth();
    }

    return () => {
      window.removeEventListener('session-expired', handleSessionExpired);
    };
  }, [location.pathname, isPublicRoute]);

  const signIn = async (
    email: string,
    password: string,
    rememberDevice: boolean,
    newPassword?: string,
    userInfo?: { firstName: string; lastName: string },
    mfaCode?: string,
    cognitoSession?: string | null
  ): Promise<AuthResult> => {
    setIsLoading(true);
    try {
      const response = await authService.signIn(
        email, password, rememberDevice, 
        newPassword, userInfo, mfaCode, cognitoSession
      );

      // Handle device not found error from successful response
      if (response.error === 'DEVICE_NOT_FOUND') {
        localStorage.removeItem(`deviceCredentials_${email}`);
        return await authService.signIn(
          email, password, rememberDevice,
          newPassword, userInfo, mfaCode, cognitoSession
        );
      }

      if (response.error === 'NEW_PASSWORD_REQUIRED') {
        return {
          session: response.session,
          error: response.error,
          challengeName: 'NEW_PASSWORD_REQUIRED'
        };
      }
      
      if (response.error && response.pendingApproval) {
        return {
          session: response.session,
          error: response.error,
          pendingApproval: true
        };
      }

      if (response.error) {
        return {
          error: response.error
        };
      }
      
      if (response.session?.user) {
        setUser(response.session.user);
        setIsAuthenticated(true);
        return response;
      }
      
      return {
        error: 'Invalid login response'
      };
    } catch (error: any) {
      console.error('Sign in error:', error);
      
      // Handle device not found error from API response
      if (error.response?.data?.error === 'DEVICE_NOT_FOUND') {
        localStorage.removeItem(`deviceCredentials_${email}`);
        return await authService.signIn(
          email, password, false, // Force disable device remembering
          newPassword, userInfo, mfaCode, cognitoSession
        );
      }
      
      return {
        error: error.response?.data?.error || error.message || 'Authentication failed'
      };
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    setIsLoading(true);
    try {
      await authService.signOut();
      queryClient.clear();
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const signUp = async (email: string, password: string, userData: {
    givenName: string;
    familyName: string;
    organizationName: string;
  }) => {
    await authService.signUp(email, password, userData);
  };

  const verifyEmail = async (email: string, code: string) => {
    await authService.verifyEmail(email, code);
  };

  const resendVerificationCode = async (email: string) => {
    await authService.resendVerificationCode(email);
  };

  const forgotPassword = async (email: string) => {
    await authService.forgotPassword(email);
  };

  const confirmPasswordReset = async (email: string, code: string, newPassword: string) => {
    await authService.confirmPasswordReset(email, code, newPassword);
  };

  const updateUserAttributes = async (attributes: { [key: string]: string }) => {
    await authService.updateUserAttributes(attributes);
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    await authService.changePassword(currentPassword, newPassword);
  };

  const getCurrentSession = async (): Promise<AuthSession | null> => {
    return await authService.getCurrentSession();
  };

  const setupMFA = async () => {
    return await authService.setupMFA();
  };

  const verifyAndEnableMFA = async (code: string) => {
    await authService.verifyAndEnableMFA(code);
  };

  const disableMFA = async (code: string) => {
    await authService.disableMFA(code);
  };

  const getMFAStatus = async () => {
    return await authService.getMFAStatus();
  };

  const value: AuthContextType = {
    user,
    isAuthenticated,
    isLoading,
    signIn,
    signOut,
    signUp,
    verifyEmail,
    resendVerificationCode,
    forgotPassword,
    confirmPasswordReset,
    updateUserAttributes,
    changePassword,
    getCurrentSession,
    setupMFA,
    verifyAndEnableMFA,
    disableMFA,
    getMFAStatus
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}; 