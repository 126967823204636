import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './contexts/AuthContext';
import { PublicLayout } from './components/layout/PublicLayout';
import { AuthenticatedLayout } from './components/layout/AuthenticatedLayout';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { SignInForm } from './components/auth/SignInForm';
import { SignUpForm } from './components/auth/SignUpForm';
import { EmailVerification } from './components/auth/EmailVerification';
import { PasswordReset } from './components/auth/PasswordReset';
import { QuestionnaireOverview } from './pages/QuestionnaireOverview';
import { KnowledgeBase } from './pages/knowledge-base/KnowledgeBase';
import { QMapping } from './pages/QMapping';
import { QuestionnaireEditing } from './pages/QuestionnaireEditing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { KBMapping } from './pages/KBMapping';
import { Subscription } from './components/Subscription';
import { QuestionImportReview } from './pages/QuestionImportReview';
import { Chat, ChatProvider } from './pages/Chat';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data is considered fresh for 5 minutes
      retry: 1, // Only retry failed requests once
    },
  },
})

function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ChatProvider>
            <Toaster 
              position="top-center" 
              containerStyle={{
                zIndex: 10000
              }}
            />
            <Routes>
              {/* Public Routes */}
              <Route element={<PublicLayout />}>
                <Route path="/login" element={<SignInForm />} />
                <Route path="/signup" element={<SignUpForm />} />
                <Route path="/verify-email" element={<EmailVerification />} />
                <Route path="/reset-password" element={<PasswordReset />} />
              </Route>

              {/* Protected Routes */}
              <Route element={
                <ProtectedRoute>
                  <AuthenticatedLayout />
                </ProtectedRoute>
              }>
                <Route path="/questionnaires" element={<QuestionnaireOverview />} />
                <Route path="/questionnaire/:questionnaireId/edit" element={<QuestionnaireEditing />} />
                <Route path="/questionnaire/:questionnaireId/import-review" element={<QuestionImportReview />} />
                <Route path="/knowledge-base" element={<KnowledgeBase />} />
                <Route path="/qmapping/:questionnaireId" element={<QMapping />} />
                <Route path="/kb-mapping/:questionnaireId" element={<KBMapping />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/chat" element={<Chat />} />
              </Route>

              {/* Root redirect */}
              <Route path="/" element={<Navigate to="/questionnaires" replace />} />
            </Routes>
          </ChatProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App; 