import { apiGatewayService } from './index';

export interface ProductTag {
    id: string;
    name: string;
    organizationId: string;
    createdAt: string;
    updatedAt: string;
}

interface ApiResponse {
    data: ProductTag[];
    error?: string;
    success?: boolean;
    message?: string;
}

interface SingleTagResponse {
    data: ProductTag;
    error?: string;
}

export const productTagService = {
    getTags: async (): Promise<ProductTag[]> => {
        const response = await apiGatewayService.request<ApiResponse>({
            method: 'GET',
            url: '/api/product-tags'
        });
        return response.data.data;
    },

    createTag: async (name: string): Promise<ProductTag> => {
        const response = await apiGatewayService.request<SingleTagResponse>({
            method: 'POST',
            url: '/api/product-tags',
            data: { name }
        });
        return response.data.data;
    },

    updateTag: async (id: string, name: string): Promise<ProductTag> => {
        const response = await apiGatewayService.request<SingleTagResponse>({
            method: 'PUT',
            url: `/api/product-tags/tag/${id}`,
            data: { name }
        });
        return response.data.data;
    },

    deleteTag: async (id: string): Promise<void> => {
        await apiGatewayService.request({
            method: 'DELETE',
            url: `/api/product-tags/tag/${id}`
        });
    },

    updateItemTags: async (
        itemId: string | string[],
        tagIds: string[],
        itemType: 'knowledge_base' | 'file_knowledge_base' | 'questionnaire'
    ): Promise<void> => {
        const response = await apiGatewayService.request({
            method: 'PUT',
            url: `/api/product-tags/${Array.isArray(itemId) ? itemId[0] : itemId}`,
            data: {
                tagIds,
                itemType,
                ...(Array.isArray(itemId) ? { itemIds: itemId } : {})
            }
        });
        
        if (!response.data.success) {
            throw new Error(response.data.error || 'Failed to update tags');
        }
    }
}; 