import { apiGatewayService } from './index';
import { ProductTag } from './productTagService';

export interface FileDocument {
  id: string;
  fileName: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  pageCount?: number;
  productTags: ProductTag[];
}

export const fileKnowledgeService = {
  async getPresignedUrl(fileName: string, contentType: string) {
    const response = await apiGatewayService.request({
      method: 'POST',
      url: '/api/file-knowledge/presigned-url',
      data: { fileName, contentType }
    });
    return response.data;
  },

  async uploadDocument(file: File, uploadUrl: string) {
    await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    });
  },

  async processDocument(documentId: string, tags?: string[]) {
    const response = await apiGatewayService.request({
      method: 'POST',
      url: `/api/file-knowledge/file-ingest`,
      data: { documentId, tags }
    });
    return response.data;
  },

  async uploadAndProcessDocument(file: File, tags?: string[]): Promise<{ documentId: string }> {
    // Get presigned URL
    const { uploadUrl, documentId } = await this.getPresignedUrl(file.name, file.type);
    
    // Upload to S3
    await this.uploadDocument(file, uploadUrl);
    
    // Queue for processing
    await this.processDocument(documentId, tags);
    
    return { documentId };
  },

  async uploadAndProcessMultipleDocuments(
    files: File[], 
    config?: { productTags: string[] }
  ): Promise<{ 
    successCount: number, 
    failedUploads: Array<{ file: File, error: Error }> 
  }> {
    let successCount = 0;
    const failedUploads: Array<{ file: File, error: Error }> = [];

    for (const file of files) {
      try {
        await this.uploadAndProcessDocument(file, config?.productTags);
        successCount++;
      } catch (error) {
        failedUploads.push({ file, error: error as Error });
      }
    }

    return { successCount, failedUploads };
  },

  async getDocuments(): Promise<FileDocument[]> {
    const response = await apiGatewayService.request<{ data: FileDocument[] }>({
      method: 'GET',
      url: '/api/file-knowledge'
    });
    return response.data.data;
  },

  async deleteDocument(ids: string | string[]): Promise<void> {
    try {
      // Convert single ID to array for consistent handling
      const idArray = Array.isArray(ids) ? ids : [ids];
      
      // Delete all documents in parallel
      await Promise.all(
        idArray.map(id => 
          apiGatewayService.request({
            method: 'DELETE',
            url: `/api/file-knowledge/${id}`,
          })
        )
      );
    } catch (error) {
      console.error('Error deleting documents:', error);
      throw error;
    }
  },

  async getDownloadUrl(documentId: string): Promise<{ downloadUrl: string }> {
    const response = await apiGatewayService.request({
      method: 'GET',
      url: `/api/file-knowledge/${documentId}/download`
    });
    return response.data;
  },

  async downloadFile(documentId: string, fileName: string): Promise<void> {
    try {
      const response = await apiGatewayService.request({
        method: 'GET',
        url: `/api/file-knowledge/${documentId}/download`,
        responseType: 'blob'
      });

      // Create blob from response data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
      throw error;
    }
  }
}; 