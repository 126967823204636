import { useParams } from 'react-router-dom';
import { useQuestionnaire } from '../hooks/useQuestionnaire';
import { CubeTransparentIcon, CheckCircleIcon, ArrowPathIcon, XCircleIcon, BookmarkIcon, BookmarkSlashIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useRef, useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

interface AnswerOption {
  id: string;
  text: string;
}

export const QuestionnaireEditing: React.FC = () => {
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const queryClient = useQueryClient();
  
  // Add effect to invalidate questionnaires query on unmount
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries({ queryKey: ['questionnaires'] });
    };
  }, [queryClient]);
  
  const {
    questionnaire,
    currentQuestionId,
    isLoading,
    activeFilter,
    filterOptions,
    questionsInKB,
   
    setCurrentQuestionId,
    setActiveFilter,
    
    handleDropdownChange,
    handleMarkForLater,
    handleSubmit,
    handleReopen,
    handleAddToKB,
    handleRemoveSnippet,
    getFilteredQuestions,
    handleTextAnswerUpdate,
    textAnswers,
    setTextAnswers,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    handleRegenerate,
    getKnowledgeBaseSources,
    regeneratingQuestions,
    submittingQuestions,
  } = useQuestionnaire(questionnaireId);

  const observerRef = useRef<IntersectionObserver>();
  const lastQuestionRef = useCallback((node: HTMLDivElement | null) => {
    if (isFetchingNextPage) return;

    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });

    if (node) observerRef.current.observe(node);
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center space-y-4">
          <div className="animate-spin rounded-full h-9 w-9 border-[2.5px] border-gray-200 border-t-gray-600"></div>
          <div className="text-base text-gray-600 font-normal">Loading questionnaire...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto">
      <div className="bg-white rounded-lg">
        <div className="mb-8 space-y-4">
          <div>
            <h1 className="text-xl font-medium text-gray-900">Q-Wizard</h1>
          </div>
          
          <div className="border-b border-gray-200">
            <div className="flex justify-between items-center relative">
              <p className="text-sm text-gray-500 py-4">
                {questionnaire?.fileName || 'No source available'}
              </p>
              
              <nav className="flex space-x-8 ml-auto" aria-label="Tabs">
                {filterOptions.map((filter) => (
                  <button
                    key={filter.id}
                    onClick={() => setActiveFilter(filter.id)}
                    className={`
                      relative py-4 text-sm font-medium transition-colors
                      ${activeFilter === filter.id
                        ? 'text-[#1e8259]'
                        : 'text-gray-500 hover:text-gray-700'
                      }
                    `}
                  >
                    {filter.label}
                    {activeFilter === filter.id && (
                      <span className="absolute bottom-0 left-0 w-full h-[2px] bg-[#1e8259] -mb-[1px]" />
                    )}
                    {filter.count !== undefined && (
                      <span className={`ml-2 text-xs
                        ${activeFilter === filter.id
                          ? 'bg-[#1e8259]/10 text-[#1e8259]'
                          : 'bg-gray-100 text-gray-600'
                        } px-2 py-0.5 rounded-full transition-colors`}
                      >
                        {filter.count}
                      </span>
                    )}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className="divide-y divide-gray-200 pb-12">
          {getFilteredQuestions().length === 0 && activeFilter === 'open' && (
            <div className="py-48 text-center">
              <div className="max-w-sm mx-auto">
                <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                  <CubeTransparentIcon className="h-6 w-6 text-gray-600" />
                </div>
                <h3 className="text-base font-medium text-gray-900">No Open Questions</h3>
                <p className="mt-1 text-sm text-gray-500">
                  All questions have been answered or marked for later. Return to the overview page to export your completed questionnaire.
                </p>
              </div>
            </div>
          )}

          {getFilteredQuestions().length === 0 && activeFilter === 'marked' && (
            <div className="py-48 text-center">
              <div className="max-w-sm mx-auto">
                <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                  <CubeTransparentIcon className="h-6 w-6 text-gray-600" />
                </div>
                <h3 className="text-base font-medium text-gray-900">No Marked Questions</h3>
                <p className="mt-1 text-sm text-gray-500">
                  You have no questions marked for later. Use the filters to view the other questions.
                </p>
              </div>
            </div>
          )}

          {getFilteredQuestions().length === 0 && activeFilter === 'completed' && (
            <div className="py-48 text-center">
              <div className="max-w-sm mx-auto">
                <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                  <CubeTransparentIcon className="h-6 w-6 text-gray-600" />
                </div>
                <h3 className="text-base font-medium text-gray-900">No Completed Questions</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Go to the open tab and review the answers to your questions.
                </p>
              </div>
            </div>
          )}

          {getFilteredQuestions().map((question, index, array) => {
            const questionAnswerOptions: AnswerOption[] = (question.dropdownOptions || []).map((option: string) => ({
              id: option.trim(),
              text: option.trim()
            }));

            const isCurrentQuestionMarked = question.isMarked;
            const isCurrentQuestionApproved = question.isApproved;

            const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(e, question.id);
            };

            return (
              <div 
                key={question.id} 
                ref={index === array.length - 1 ? lastQuestionRef : undefined}
                className={`py-12 first:pt-6 ${
                  currentQuestionId === question.id ? 'bg-gray-50/50' : 'hover:bg-gray-50/30'
                }`}
                onClick={() => setCurrentQuestionId(question.id)}
              >
                <form onSubmit={onSubmitHandler} className="px-8">
                  <div className="flex items-start justify-between gap-4 mb-6">
                    <div className="flex items-center gap-4 pt-1">
                      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 text-gray-500 text-sm font-medium flex-shrink-0">
                        {question.order}
                      </div>
                      <h2 className="text-lg font-medium text-gray-900">
                        {question.questionText}
                      </h2>
                    </div>

                    <div className="relative group/add flex-shrink-0">
                      <button
                        type="button"
                        onClick={(e) => handleAddToKB(e, question.id)}
                        disabled={questionsInKB.has(question.id)}
                        className={`p-1 rounded-full transition-colors ${
                          questionsInKB.has(question.id)
                            ? 'text-gray-400 cursor-not-allowed'
                            : 'text-gray-400 hover:text-[#1e8259] hover:bg-[#1e8259]/15'
                        }`}
                      >
                        <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                      </button>
                      {!questionsInKB.has(question.id) && (
                        <div className="absolute bottom-full mb-2 hidden group-hover/add:block right-0">
                          <div className="bg-gray-900 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                            Add to Knowledge Base
                          </div>
                          <div className="w-2 h-2 bg-gray-900 transform rotate-45 translate-x-[0.6rem] -translate-y-1 ml-auto mr-2"></div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-6 mb-8">
                    {question.excelLocation?.answer && (
                      <div className="flex flex-col h-full">
                        <label className="block text-xs font-semibold text-gray-700 uppercase tracking-wide mb-3">
                          Detailed Answer
                        </label>
                        <textarea
                          value={textAnswers[question.id] || ''}
                          onChange={(e) => setTextAnswers(prev => ({
                            ...prev,
                            [question.id]: e.target.value
                          }))}
                          onBlur={() => handleTextAnswerUpdate(question.id, textAnswers[question.id] || '')}
                          className="flex-1 min-h-[144px] bg-white rounded-lg px-4 py-4 text-sm text-gray-600 
                            border border-gray-200 
                            focus:outline-none 
                            focus:border-[#1e8259]/50
                            focus:ring-0
                            focus:shadow-[inset_0_0_0_2px_rgba(30,130,89,0.2)]
                            transition-all 
                            duration-200 
                            resize-none"
                          placeholder={!textAnswers[question.id] ? "Provide a detailed explanation..." : undefined}
                        />
                      </div>
                    )}

                    {question.excelLocation?.dropdown && (
                      <div className="flex flex-col h-full">
                        <label className="block text-xs font-semibold text-gray-700 uppercase tracking-wide mb-3">
                          Dropdown Answer
                        </label>
                        <div className="flex-1 divide-y divide-gray-200 bg-white rounded-lg border border-gray-200">
                          {questionAnswerOptions.map((option: AnswerOption) => (
                            <label
                              key={option.id}
                              className="flex items-center w-full px-4 py-4 hover:bg-gray-50 cursor-pointer"
                            >
                              <input
                                type="radio"
                                name={`answer-${question.id}`}
                                value={option.id}
                                checked={question.dropdownValue === option.id}
                                onChange={(e) => handleDropdownChange(e.target.value)}
                                className="w-4 h-4 
                                  border-gray-300 
                                  accent-[#1e8259]
                                  focus:ring-[#1e8259]/20"
                              />
                              <span className="ml-3 text-sm text-gray-600">{option.text}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex justify-end gap-3">
                    <button
                      type="button"
                      onClick={() => {
                        handleRegenerate(question.id);
                      }}
                      disabled={regeneratingQuestions.has(question.id) || questionnaire?.status === 'PROCESSING'}
                      className={`inline-flex items-center px-4 py-2 text-sm rounded-lg transition-colors ${
                        questionnaire?.status === 'PROCESSING' 
                          ? 'text-gray-400 cursor-not-allowed'
                          : 'text-gray-600 hover:text-[#1e8259] hover:bg-[#1e8259]/5'
                      }`}
                    >
                      <ArrowPathIcon className={`w-5 h-5 mr-2 ${regeneratingQuestions.has(question.id) ? 'animate-spin' : ''}`} />
                      {regeneratingQuestions.has(question.id) 
                        ? 'Regenerating...' 
                        : questionnaire?.status === 'PROCESSING'
                          ? 'Processing...'
                          : 'Regenerate Answer'
                      }
                    </button>

                    <button
                      type="button"
                      onClick={() => handleMarkForLater(question.id)}
                      disabled={submittingQuestions.has(question.id)}
                      className={`inline-flex items-center px-4 py-2 text-sm rounded-lg transition-colors ${
                        isCurrentQuestionMarked
                          ? 'text-gray-700 hover:bg-gray-100'
                          : 'text-gray-600 hover:text-[#1e8259] hover:bg-[#1e8259]/5'
                      }`}
                    >
                      {isCurrentQuestionMarked ? (
                        <>
                          <BookmarkSlashIcon className="w-5 h-5 mr-2" />
                          Unmark
                        </>
                      ) : (
                        <>
                          <BookmarkIcon className="w-5 h-5 mr-2" />
                          Mark for Later
                        </>
                      )}
                    </button>

                    {isCurrentQuestionApproved ? (
                      <button
                        type="button"
                        onClick={() => handleReopen(question.id)}
                        disabled={submittingQuestions.has(question.id)}
                        className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ml-3"
                      >
                        <XCircleIcon className="w-5 h-5 mr-2" />
                        {submittingQuestions.has(question.id) ? 'Disapproving...' : 'Disapprove'}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={submittingQuestions.has(question.id)}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#1e8259] hover:bg-[#1e8259]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1e8259] ml-3"
                      >
                        <CheckCircleIcon className="w-5 h-5 mr-2" />
                        Approve
                      </button>
                    )}
                  </div>
                </form>

                <div className="mt-8 px-8 pt-8 border-t border-gray-100">
                  <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                      <div className="relative group/info">
                        <InformationCircleIcon className="w-5 h-5 text-gray-400 hover:text-gray-600 cursor-help" />
                        <div className="absolute bottom-full left-0 mb-2 hidden group-hover/info:block z-50">
                          <div className="bg-white rounded-lg shadow-lg border border-gray-200 p-6 w-[400px]">
                            <div className="mb-4">
                              <h4 className="font-medium text-base text-gray-900 mb-1">Knowledge Base References</h4>
                              <p className="text-sm text-gray-600 leading-relaxed">Sources used by the AI to generate this answer</p>
                            </div>
                            <div className="space-y-4">
                              <div className="flex items-center gap-3">
                                <svg className="w-5 h-5 text-gray-500 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                </svg>
                                <div>
                                  <p className="text-sm font-medium text-gray-900">Q&A Pairs</p>
                                  <p className="text-sm text-gray-500">Previously answered questions from questionnaires</p>
                                </div>
                              </div>
                              <div className="flex items-center gap-3">
                                <svg className="w-5 h-5 text-gray-500 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                                <div>
                                  <p className="text-sm font-medium text-gray-900">Documents</p>
                                  <p className="text-sm text-gray-500">Relevant content from uploaded documents</p>
                                </div>
                              </div>
                              <div className="flex items-center gap-3">
                                <svg className="w-5 h-5 text-gray-500 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                                </svg>
                                <div>
                                  <p className="text-sm font-medium text-gray-900">Relevance Score</p>
                                  <p className="text-sm text-gray-500">Indicates semantic similarity between the source and your question (0-100%)</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-4 h-4 bg-white border-l border-b border-gray-200 transform rotate-45 translate-x-6 -mt-2 ml-2"></div>
                        </div>
                      </div>
                      <span className="text-sm text-gray-500">Knowledge Base References</span>
                      <span className="text-xs text-gray-400">•</span>
                      <span className="text-xs text-gray-400">
                        {getKnowledgeBaseSources(question.id).length} references
                      </span>
                    </div>
                  </div>

                  {question.aiQueryResults && Object.keys(question.aiQueryResults).length > 0 ? (
                    <div className="space-y-6">
                      {getKnowledgeBaseSources(question.id).map((snippet) => (
                        <div key={snippet.id} className="pl-4 border-l-2 border-gray-100 hover:border-[#1e8259]/30">
                          <div className="flex items-start justify-between gap-4">
                            <h4 className="text-base text-gray-700">{snippet.title}</h4>
                            <div className="flex items-center gap-3">
                              <div className="flex items-center gap-2">
                                <span className="text-xs text-gray-500">Relevance</span>
                                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                                  snippet.relevanceScore * 100 >= 60 
                                    ? 'bg-green-50 text-green-600' 
                                    : snippet.relevanceScore * 100 >= 40 
                                    ? 'bg-yellow-50 text-yellow-600'
                                    : 'bg-red-50 text-red-600'
                                }`}>
                                  {Math.round(snippet.relevanceScore * 100)}%
                                </span>
                              </div>
                              
                              <div className="relative group/remove">
                                <button
                                  onClick={(e) => handleRemoveSnippet(e, snippet.id)}
                                  className="p-1 text-gray-400 hover:text-red-500 rounded-full hover:bg-red-50"
                                >
                                  <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </button>
                                <div className="absolute bottom-full mb-2 hidden group-hover/remove:block right-0">
                                  <div className="bg-gray-900 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                                    Remove from Knowledge Base
                                  </div>
                                  <div className="w-2 h-2 bg-gray-900 transform rotate-45 translate-x-[0.6rem] -translate-y-1 ml-auto mr-2"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="mt-2 text-sm text-gray-500">{snippet.content}</p>
                          <div className="mt-2 flex items-center gap-2 text-xs text-gray-400">
                            <span>{snippet.source_name}</span>
                            <span>•</span>
                            <span>{new Date(snippet.created_at).toLocaleDateString('en-US', {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric'
                            })}</span>
                            {snippet.source_type === 'file' && snippet.page_number && (
                              <>
                                <span>•</span>
                                <span>Page {snippet.page_number}</span>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="py-8 text-center">
                      <div className="max-w-sm mx-auto">
                        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                          <svg className="h-6 w-6 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                          </svg>
                        </div>
                        <h3 className="text-base font-medium text-gray-900 mb-1">No Knowledge Base Entries</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Answer this question and click the "+" icon to add it to the knowledge base for future reference.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {isFetchingNextPage && (
            <div className="py-4 text-center">
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
