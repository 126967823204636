import React, { useState, useRef, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender, createColumnHelper } from '@tanstack/react-table';
import toast from 'react-hot-toast';
import { ClockIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import ImportModal from './components/ImportModal';
import DocumentActionDropdown from './components/DocumentActionDropdown.tsx';
import TagCell from './components/TagCell';
import { productTagService } from '../../services/productTagService';
import { 
  useKnowledgeBaseItems, 
  useDocuments, 
  useFilteredKnowledgeBaseItems,
  useFilteredDocuments,
  useDeleteKnowledgeBaseItems,
  useDeleteDocuments,
  useDocumentUpload,
  useQAUpload,
  useBulkTagUpdate,
  useDocumentDownload,
  useFileUploadHandler,
  useUpdateKnowledgeBaseItem,
  Document
} from './hooks/useKnowledgeBase';

interface KnowledgeBaseItem {
  id: string;
  title: string;
  description: string;
  sourceName: string;
  productTags: { id: string; name: string }[];
  updatedAt: string;
}

interface TitleCellProps {
  title: string;
  description: string;
  id: string;
  onUpdate: (data: { id: string; title: string; description: string }) => void;
  isUpdating: boolean;
  isEditing: boolean;
  onStartEditing: () => void;
  onFinishEditing: () => void;
}

const TitleCell: React.FC<TitleCellProps> = React.memo(({ title: initialTitle, description: initialDescription, id, onUpdate, isUpdating, isEditing, onStartEditing, onFinishEditing }) => {
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditing) {
      const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setTitle(initialTitle);
          setDescription(initialDescription);
          onFinishEditing();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isEditing, initialTitle, initialDescription, onFinishEditing]);

  useEffect(() => {
    if (isEditing && titleRef.current) {
      titleRef.current.focus();
      setTimeout(() => {
        if (titleRef.current) {
          titleRef.current.style.height = 'auto';
          titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
        }
      }, 50);
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing && descriptionRef.current) {
      descriptionRef.current.style.height = 'auto';
      setTimeout(() => {
        if (descriptionRef.current) {
          descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`;
        }
      }, 50);
    }
  }, [isEditing]);

  // New effect: Update local state when editing is finished or props change
  useEffect(() => {
    if (!isEditing) {
      setTitle(initialTitle);
      setDescription(initialDescription);
    }
  }, [initialTitle, initialDescription, isEditing]);

  const handleSave = () => {
    onUpdate({ id, title, description });
    onFinishEditing();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.metaKey) {
      handleSave();
    } else if (e.key === 'Escape') {
      setTitle(initialTitle);
      setDescription(initialDescription);
      onFinishEditing();
    }
  };

  if (isEditing) {
    return (
      <div ref={containerRef} className="w-full px-0 space-y-2 h-auto overflow-visible">
        <textarea
          ref={titleRef}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setTimeout(() => {
              if (titleRef.current) {
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }
            }, 50);
          }}
          onFocus={(e) => {
            setTimeout(() => {
              if (titleRef.current) {
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }
            }, 50);
          }}
          onKeyDown={handleKeyDown}
          className="w-full text-sm font-medium text-gray-900 bg-white border-0 border-b border-transparent px-0 focus:outline-none focus:ring-0 resize-none overflow-hidden"
          rows={1}
          placeholder="Enter title"
        />
        <textarea
          ref={descriptionRef}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            setTimeout(() => {
              if (descriptionRef.current) {
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }
            }, 50);
          }}
          onFocus={(e) => {
            setTimeout(() => {
              if (descriptionRef.current) {
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }
            }, 50);
          }}
          onKeyDown={handleKeyDown}
          className="w-full text-sm text-gray-500 bg-white border-0 border-b border-transparent px-0 focus:outline-none focus:ring-0 resize-none overflow-hidden"
          rows={2}
          placeholder="Enter description"
        />
        <div className="flex items-center justify-end gap-2 pt-2">
          <button
            onClick={() => {
              setTitle(initialTitle);
              setDescription(initialDescription);
              onFinishEditing();
            }}
            className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={isUpdating}
            className="px-3 py-1.5 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isUpdating ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="w-full px-0 group cursor-pointer" 
      onClick={onStartEditing}
    >
      <div className="text-sm font-medium text-gray-900 mb-1">{title}</div>
      <div className="text-sm text-gray-500 whitespace-pre-line line-clamp-2 group-hover:line-clamp-none">
        {description}
      </div>
    </div>
  );
});

const DateCell: React.FC<{ date: string }> = React.memo(({ date }) => (
  <span className="text-sm text-gray-500 block">
    {new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })}
  </span>
));

const SourceCell: React.FC<{ source: string }> = React.memo(({ source }) => (
  <span className="text-sm text-gray-500 min-w-[80px] block">{source}</span>
));

TitleCell.displayName = 'TitleCell';
DateCell.displayName = 'DateCell';
SourceCell.displayName = 'SourceCell';

export const KnowledgeBase: React.FC = () => {
  // State declarations
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState<'qa' | 'documents'>('qa');
  const [documentRowSelection, setDocumentRowSelection] = useState<Record<string, boolean>>({});
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);
  const [editingRowId, setEditingRowId] = useState<string | null>(null);

  const queryClient = useQueryClient();

  // Use the deletion hooks
  const deleteKnowledgeBaseItems = useDeleteKnowledgeBaseItems();
  const deleteDocuments = useDeleteDocuments();

  // Get data using the hooks
  const { knowledgeBaseItems, error: knowledgeBaseError, isLoading } = useKnowledgeBaseItems();
  const { documents, error: documentsError } = useDocuments();

  // Use the filtering hooks
  const filteredItems = useFilteredKnowledgeBaseItems(knowledgeBaseItems, searchTerm);
  const filteredDocuments = useFilteredDocuments(documents, searchTerm);

  // Use the upload hooks - only destructure what we need
  const { isUploading: isDocumentUploading } = useDocumentUpload();
  const { isUploading: isQAUploading } = useQAUpload();

  // Add bulk tag update hook at the top level
  const { updateBulkTags } = useBulkTagUpdate(activeTab);

  // Combined upload status
  const isUploading = isDocumentUploading || isQAUploading;

  // Use the hooks
  const { downloadDocument } = useDocumentDownload();
  const { handleFileUpload } = useFileUploadHandler();

  // Add the update hook
  const { updateItem, isUpdating } = useUpdateKnowledgeBaseItem();

  // Define columns for knowledge base items
  const columnHelper = createColumnHelper<KnowledgeBaseItem>();
  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllPageRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            className="rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259]"
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            className="rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259]"
          />
        ),
      }),
      columnHelper.accessor('title', {
        header: 'Control',
        cell: (info) => (
          <TitleCell 
            key={`titleCell-${info.row.original.id}`}
            title={info.getValue()} 
            description={info.row.original.description}
            id={info.row.original.id}
            onUpdate={(data) => updateItem(data)}
            isUpdating={isUpdating}
            isEditing={editingRowId === info.row.original.id}
            onStartEditing={() => setEditingRowId(info.row.original.id)}
            onFinishEditing={() => setEditingRowId(null)}
          />
        ),
      }),
      columnHelper.accessor('productTags', {
        header: 'Tags',
        cell: (info) => (
          <TagCell 
            tags={info.getValue() || []} 
            itemId={info.row.original.id} 
            activeTab={activeTab}
            onTagsChange={async (newTagIds) => {
              try {
                await productTagService.updateItemTags(
                  info.row.original.id,
                  newTagIds,
                  'knowledge_base'
                );
                queryClient.invalidateQueries({ queryKey: ['knowledgeBase'] });
              } catch (error) {
                console.error('Failed to update tags:', error);
                toast.error('Failed to update tags');
              }
            }}
          />
        ),
      }),
      columnHelper.accessor('sourceName', {
        header: 'Source',
        cell: (info) => <SourceCell source={info.getValue()} />,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'DATE',
        cell: (info) => <DateCell date={info.getValue()} />,
      }),
    ],
    [editingRowId, isUpdating]
  );

  // Create table instance for knowledge base items
  const knowledgeBaseTable = useReactTable({
    data: filteredItems,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  // Document columns
  const documentColumnHelper = createColumnHelper<Document>();
  const documentColumns = React.useMemo(
    () => [
      documentColumnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllPageRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            className="rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259]"
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            className="rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259]"
          />
        ),
      }),
      documentColumnHelper.accessor('fileName', {
        header: 'FILE NAME',
        cell: (info) => (
          <div className="flex items-center">
            {info.row.original.status === 'PROCESSING' && (
              <div className="group relative mr-2">
                <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2C5.373 2 2 5.373 2 12h2zm2 5.291A7.962 7.962 0 014 12H2c0 3.042 1.135 5.824 3 7.938l1-2.647z"></path>
                </svg>
                <div className="invisible group-hover:visible absolute left-7 top-1/2 -translate-y-1/2 px-2 py-1 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
                  Your file is currently being processed
                </div>
              </div>
            )}
            {info.row.original.status === 'QUEUED' && (
              <div className="group relative mr-2">
                <ClockIcon className="h-5 w-5 text-gray-500" />
                <div className="invisible group-hover:visible absolute left-7 top-1/2 -translate-y-1/2 px-2 py-1 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
                  Your file is waiting in queue
                </div>
              </div>
            )}
            {info.row.original.status === 'FAILED' && (
              <div className="group relative mr-2">
                <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 5.5a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-3 0V7A1.5 1.5 0 0 1 12 5.5zm0 10a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
                </svg>
                <div className="invisible group-hover:visible absolute left-7 top-1/2 -translate-y-1/2 px-2 py-1 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
                  The document processing has failed. Please try again.
                </div>
              </div>
            )}
            <span className="text-sm font-medium text-gray-900">{info.getValue()}</span>
          </div>
        ),
      }),
      documentColumnHelper.accessor('productTags', {
        header: 'Tags',
        cell: (info) => (
          <TagCell 
            tags={info.getValue() || []} 
            itemId={info.row.original.id} 
            activeTab={activeTab}
            onTagsChange={async (newTagIds) => {
              try {
                await productTagService.updateItemTags(
                  info.row.original.id,
                  newTagIds,
                  'file_knowledge_base'
                );
                queryClient.invalidateQueries({ queryKey: ['documents'] });
              } catch (error) {
                console.error('Failed to update tags:', error);
                toast.error('Failed to update tags');
              }
            }}
          />
        ),
      }),
      documentColumnHelper.accessor('pageCount', {
        header: 'PAGES',
        cell: (info) => (
          <span className="text-sm text-gray-500">
            {info.getValue() || '-'}
          </span>
        ),
      }),
      documentColumnHelper.accessor('updatedAt', {
        header: 'DATE',
        cell: (info) => <DateCell date={info.getValue()} />,
      }),
      documentColumnHelper.display({
        id: 'actions',
        header: () => <div className="text-right"></div>,
        cell: ({ row }) => (
          <div className="text-right">
            <DocumentActionDropdown
              onDownload={() => handleDownloadDocument(row.original.id)}
              isOpen={activeDropdownId === row.original.id}
              setIsOpen={(isOpen: boolean) => setActiveDropdownId(isOpen ? row.original.id : null)}
            />
          </div>
        ),
      })
    ],
    [activeTab, activeDropdownId]
  );

  // Create table instance for documents
  const documentsTable = useReactTable({
    data: filteredDocuments,
    columns: documentColumns,
    state: {
      rowSelection: documentRowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setDocumentRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  const handleDownloadDocument = async (documentId: string) => {
    const document = filteredDocuments.find(doc => doc.id === documentId);
    if (!document) {
      throw new Error('Document not found');
    }
    await downloadDocument(documentId, document.fileName);
  };

  const handleBulkDelete = async () => {
    if (activeTab === 'qa') {
      const selectedIds = Object.keys(rowSelection).map((index) => {
        const row = knowledgeBaseTable.getRowModel().rowsById[index];
        return row?.original.id;
      }).filter(Boolean);
      
      try {
        setIsBulkDeleting(true);
        await deleteKnowledgeBaseItems.mutateAsync(selectedIds);
        setRowSelection({});
      } catch (error) {
        console.error('Error during bulk delete:', error);
        toast.error('Failed to delete some items');
      } finally {
        setIsBulkDeleting(false);
      }
    } else {
      const selectedIds = Object.keys(documentRowSelection).map((index) => {
        const row = documentsTable.getRowModel().rowsById[index];
        return row?.original.id;
      }).filter(Boolean);
      
      try {
        setIsBulkDeleting(true);
        await deleteDocuments.mutateAsync(selectedIds);
        setDocumentRowSelection({});
      } catch (error) {
        console.error('Error during bulk delete:', error);
        toast.error('Failed to delete some documents');
      } finally {
        setIsBulkDeleting(false);
      }
    }
  };

  if (knowledgeBaseError || documentsError) {
    return <div className="text-center py-12 text-red-500">
      Session expired - please login again.
    </div>;
  }

  return (
    <>
      {/* Adjust the search header alignment */}
      <div className="fixed top-0 left-64 right-0 bg-white z-30">
        <div className="px-6">
          <div className="w-full max-w-[1920px] mx-auto">
            <div className="flex h-16 items-center">
              <div className="flex flex-1 items-center">
                <div className="relative flex-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg 
                      className="h-4 w-4 text-gray-400" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={1.5} 
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="block w-full py-1.5 pl-10 pr-4 text-sm text-gray-900 
                      placeholder:text-gray-400 focus:outline-none focus:ring-0
                      bg-gray-50/50 focus:bg-white transition-colors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add spacing for fixed header */}
      <div className="h-16" />

      {/* Main Content */}
      <div className="w-full max-w-[1920px] mx-auto px-1 sm:px-3 lg:px-4">
        <div className="bg-white rounded-lg px-2 sm:px-3 lg:px-4">
          {/* Header Section */}
          <div className="pt-1 pb-2">
            <div className="flex justify-between items-center mb-3">
              <div>
                <h1 className="text-xl font-medium text-gray-900">Knowledge Base</h1>
                <p className="mt-0.5 text-sm text-gray-500">
                  Browse and search through the AI knowledge sources used in the system
                </p>
              </div>
              <button
                onClick={() => setIsImportModalOpen(true)}
                className="ml-3 inline-flex items-center px-3 py-2 border border-transparent 
                  text-sm font-medium rounded-md text-white bg-[#1e8259] hover:bg-[#1e8259]/90 
                  transition-all duration-200"
              >
                <svg 
                  className="w-4 h-4 mr-2 text-current" 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth={2} 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                  />
                </svg>
                Import Knowledge
              </button>
            </div>
          </div>

          {/* Tabs */}
          <div>
            <nav className="-mb-px flex space-x-8">
              <button
                onClick={() => setActiveTab('qa')}
                className={`${
                  activeTab === 'qa'
                    ? 'border-[#1e8259] text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm`}
              >
                Q&A Pairs
              </button>
              <button
                onClick={() => setActiveTab('documents')}
                className={`${
                  activeTab === 'documents'
                    ? 'border-[#1e8259] text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm`}
              >
                Documents
              </button>
            </nav>
          </div>

          {/* Content */}
          <div className="mt-8 relative">
            {activeTab === 'qa' ? (
              // Original Q&A pairs implementation
              <div className="overflow-visible">
                <table className="w-full divide-y divide-gray-200">
                  <colgroup>
                    <col className="w-[5%]" /><col className="w-[60%]" /><col className="w-[10%]" /><col className="w-[13%]" /><col className="w-[12%]" />
                  </colgroup>
                  <thead className="bg-gray-50">
                    {knowledgeBaseTable.getHeaderGroups().map(headerGroup => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                          <th key={header.id} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {!isLoading && (knowledgeBaseTable.getRowModel().rows.length > 0 ? (
                      knowledgeBaseTable.getRowModel().rows.map(row => (
                        <tr key={row.id} className={`${editingRowId === row.id ? 'bg-gray-100' : 'hover:bg-gray-50'} group`}>
                          {row.getVisibleCells().map(cell => (
                            <td key={cell.id} className="px-4 py-3 align-middle">
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      // Empty state inside table - only shown when data is loaded and empty
                      <tr>
                        <td colSpan={columns.length} className="px-6 py-24 text-center">
                          <div className="max-w-sm mx-auto">
                            <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                              <svg 
                                className="h-6 w-6 text-gray-600" 
                                xmlns="http://www.w3.org/2000/svg" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                strokeWidth={1.5} 
                                stroke="currentColor"
                              >
                                <path 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" 
                                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                                />
                              </svg>
                            </div>
                            <h3 className="text-base font-medium text-gray-900">Your Knowledge Base is Empty</h3>
                            <p className="mt-1 text-sm text-gray-500">
                              Upload your completed security questionnaires to build an AI-powered knowledge base
                            </p>
                            <button
                              onClick={() => setIsImportModalOpen(true)}
                              className="mt-4 inline-flex items-center px-3 py-2 border border-gray-300 
                                text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                              <svg 
                                className="w-4 h-4 mr-2" 
                                xmlns="http://www.w3.org/2000/svg" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                strokeWidth={1.5} 
                                stroke="currentColor"
                              >
                                <path 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" 
                                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                                />
                              </svg>
                              Import Knowledge
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                
                {/* Original Pagination */}
                <div className="px-6 py-3 flex items-center justify-between border-t border-gray-200">
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="flex items-center gap-4">
                      <p className="text-sm text-gray-700">
                        Showing{' '}
                        <span className="font-medium">{knowledgeBaseTable.getState().pagination.pageIndex * knowledgeBaseTable.getState().pagination.pageSize + 1}</span>
                        {' '}-{' '}
                        <span className="font-medium">
                          {Math.min((knowledgeBaseTable.getState().pagination.pageIndex + 1) * knowledgeBaseTable.getState().pagination.pageSize, filteredItems.length)}
                        </span>
                        {' '}of{' '}
                        <span className="font-medium">{filteredItems.length}</span>
                        {' '}results
                      </p>
                      <select
                        value={knowledgeBaseTable.getState().pagination.pageSize}
                        onChange={e => {
                          knowledgeBaseTable.setPageSize(Number(e.target.value));
                        }}
                        className="text-sm border-gray-300 rounded-md focus:ring-[#1e8259] focus:border-[#1e8259]"
                      >
                        {[10, 20, 50, 100].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                        <button
                          onClick={() => knowledgeBaseTable.previousPage()}
                          disabled={!knowledgeBaseTable.getCanPreviousPage()}
                          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-gray-25 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        >
                          Previous
                        </button>
                        
                        {/* Page Numbers */}
                        {Array.from(
                          { length: knowledgeBaseTable.getPageCount() },
                          (_, index) => {
                            if (
                              index === 0 ||
                              index === knowledgeBaseTable.getPageCount() - 1 ||
                              Math.abs(index - knowledgeBaseTable.getState().pagination.pageIndex) <= 1
                            ) {
                              return (
                                <button
                                  key={index}
                                  onClick={() => knowledgeBaseTable.setPageIndex(index)}
                                  className={`relative inline-flex items-center px-2 py-2 border text-sm font-medium text-gray-500
                                    ${index === knowledgeBaseTable.getState().pagination.pageIndex
                                      ? 'z-10 bg-gray-50 border-gray-300 font-semibold'
                                      : 'bg-white border-gray-300 hover:bg-gray-50'
                                    }`}
                                >
                                  {index + 1}
                                </button>
                              );
                            }
                            // Show ellipsis if there's a gap
                            if (
                              Math.abs(index - knowledgeBaseTable.getState().pagination.pageIndex) === 2
                            ) {
                              return (
                                <span
                                  key={index}
                                  className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500"
                                >
                                  ...
                                </span>
                              );
                            }
                            return null;
                          }
                        )}

                        <button
                          onClick={() => knowledgeBaseTable.nextPage()}
                          disabled={!knowledgeBaseTable.getCanNextPage()}
                          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-gray-25 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        >
                          Next
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // Documents tab implementation
              <div className="overflow-visible">
                <div className="overflow-visible relative">
                  <table className="w-full divide-y divide-gray-200">
                    <colgroup>
                      <col className="w-[5%]" /><col className="w-[35%]" /><col className="w-[25%]" /><col className="w-[15%]" /><col className="w-[15%]" /><col className="w-[5%]" />
                    </colgroup>
                    <thead className="bg-gray-50">
                      {documentsTable.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {documentsTable.getRowModel().rows.length > 0 ? (
                        documentsTable.getRowModel().rows.map((row) => (
                          <tr key={row.id} className="hover:bg-gray-50 group">
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id} className="px-4 py-3 align-middle">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ))}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={documentColumns.length} className="px-6 py-24 text-center">
                            <div className="max-w-sm mx-auto">
                              <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                                {activeTab === 'documents' ? (
                                  <DocumentTextIcon className="h-6 w-6 text-gray-600" />
                                ) : (
                                  <svg 
                                    className="h-6 w-6 text-gray-600" 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    fill="none" 
                                    viewBox="0 0 24 24" 
                                    strokeWidth={1.5} 
                                    stroke="currentColor"
                                  >
                                    <path 
                                      strokeLinecap="round" 
                                      strokeLinejoin="round" 
                                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125 1.125 1.125 1.125 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                    />
                                  </svg>
                                )}
                              </div>
                              <h3 className="text-base font-medium text-gray-900">Your Knowledge Base is Empty                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                Upload your documents to build an AI-powered knowledge base


                              </p>
                              <button
                                onClick={() => setIsImportModalOpen(true)}
                                disabled={isUploading}
                                className="mt-4 inline-flex items-center px-3 py-2 border border-gray-300 
                                  text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 
                                  disabled:opacity-50 disabled:cursor-not-allowed"
                              >
                                <svg 
                                  className="w-4 h-4 mr-2" 
                                  xmlns="http://www.w3.org/2000/svg" 
                                  fill="none" 
                                  viewBox="0 0 24 24" 
                                  strokeWidth={1.5} 
                                  stroke="currentColor"
                                >
                                  <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                                  />
                                </svg>
                                {isUploading ? 'Uploading...' : 'Import Knowledge'}
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {/* Add pagination for documents table */}
                  <div className="px-6 py-3 flex items-center justify-between border-t border-gray-200">
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="flex items-center gap-4">
                        <p className="text-sm text-gray-700">
                          Showing{' '}
                          <span className="font-medium">{documentsTable.getState().pagination.pageIndex * documentsTable.getState().pagination.pageSize + 1}</span>
                          {' '}-{' '}
                          <span className="font-medium">
                            {Math.min((documentsTable.getState().pagination.pageIndex + 1) * documentsTable.getState().pagination.pageSize, filteredDocuments.length)}
                          </span>
                          {' '}of{' '}
                          <span className="font-medium">{filteredDocuments.length}</span>
                          {' '}results
                        </p>
                        <select
                          value={documentsTable.getState().pagination.pageSize}
                          onChange={e => {
                            documentsTable.setPageSize(Number(e.target.value));
                          }}
                          className="text-sm border-gray-300 rounded-md focus:ring-[#1e8259] focus:border-[#1e8259]"
                        >
                          {[10, 20, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                          <button
                            onClick={() => documentsTable.previousPage()}
                            disabled={!documentsTable.getCanPreviousPage()}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-gray-25 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                          >
                            Previous
                          </button>
                          
                          {/* Page Numbers */}
                          {Array.from(
                            { length: documentsTable.getPageCount() },
                            (_, index) => {
                              if (
                                index === 0 ||
                                index === documentsTable.getPageCount() - 1 ||
                                Math.abs(index - documentsTable.getState().pagination.pageIndex) <= 1
                              ) {
                                return (
                                  <button
                                    key={index}
                                    onClick={() => documentsTable.setPageIndex(index)}
                                    className={`relative inline-flex items-center px-2 py-2 border text-sm font-medium text-gray-500
                                      ${index === documentsTable.getState().pagination.pageIndex
                                        ? 'z-10 bg-gray-50 border-gray-300 font-semibold'
                                        : 'bg-white border-gray-300 hover:bg-gray-50'
                                      }`}
                                  >
                                    {index + 1}
                                  </button>
                                );
                              }
                              // Show ellipsis if there's a gap
                              if (
                                Math.abs(index - documentsTable.getState().pagination.pageIndex) === 2
                              ) {
                                return (
                                  <span
                                    key={index}
                                    className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500"
                                  >
                                    ...
                                  </span>
                                );
                              }
                              return null;
                            }
                          )}

                          <button
                            onClick={() => documentsTable.nextPage()}
                            disabled={!documentsTable.getCanNextPage()}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-gray-25 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                          >
                            Next
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Bulk Action Bar */}
          {(activeTab === 'qa' ? Object.keys(rowSelection).length > 0 : Object.keys(documentRowSelection).length > 0) && (
            <div className="fixed bottom-4 left-1/2 -translate-x-1/2 bg-white rounded-lg shadow-lg border border-gray-200 p-4 flex items-center gap-4 z-50">
              <span className="text-sm text-gray-600">
                {`${activeTab === 'qa' ? Object.keys(rowSelection).length : Object.keys(documentRowSelection).length} item${(activeTab === 'qa' ? Object.keys(rowSelection).length : Object.keys(documentRowSelection).length) === 1 ? '' : 's'} selected`}
              </span>
              <div className="flex items-center gap-4 ml-auto">
                <button
                  onClick={handleBulkDelete}
                  disabled={isBulkDeleting}
                  className={`inline-flex items-center px-4 py-2 text-sm text-white bg-red-500 hover:bg-red-600 
                    rounded-md transition-colors ${isBulkDeleting ? 'opacity-75 cursor-not-allowed' : ''}`}
                >
                  {isBulkDeleting ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"></div>
                      Deleting...
                    </>
                  ) : (
                    'Delete'
                  )}
                </button>
                <div className="h-4 w-px bg-gray-200" />
                <TagCell 
                  tags={(() => {
                    // Get all selected items
                    const selectedItems = activeTab === 'qa' 
                      ? Object.keys(rowSelection)
                          .map(index => knowledgeBaseTable.getRowModel().rowsById[index]?.original)
                          .filter(Boolean)
                      : Object.keys(documentRowSelection)
                          .map(index => documentsTable.getRowModel().rowsById[index]?.original)
                          .filter(Boolean);

                    if (selectedItems.length === 0) return [];

                    // Get tags from first item
                    const firstItemTags = new Set(selectedItems[0].productTags.map(tag => tag.id));

                    // Find intersection with all other items
                    const commonTags = selectedItems.slice(1).reduce((common, item) => {
                      const itemTagIds = new Set(item.productTags.map(tag => tag.id));
                      return new Set([...common].filter(tagId => itemTagIds.has(tagId)));
                    }, firstItemTags);

                    // Return tags in the format expected by TagCell
                    return selectedItems[0].productTags.filter(tag => commonTags.has(tag.id));
                  })()} 
                  itemId="bulk" 
                  activeTab={activeTab}
                  onTagsChange={async (newTagIds) => {
                    const selectedItems = activeTab === 'qa' 
                      ? Object.keys(rowSelection)
                          .map(index => knowledgeBaseTable.getRowModel().rowsById[index]?.original)
                          .filter(Boolean)
                      : Object.keys(documentRowSelection)
                          .map(index => documentsTable.getRowModel().rowsById[index]?.original)
                          .filter(Boolean);

                    // Get current common tags
                    const firstItemTags = new Set(selectedItems[0].productTags.map(tag => tag.id));
                    const currentCommonTags = selectedItems.slice(1).reduce((common, item) => {
                      const itemTagIds = new Set(item.productTags.map(tag => tag.id));
                      return new Set([...common].filter(tagId => itemTagIds.has(tagId)));
                    }, firstItemTags);

                    // Convert to arrays for comparison
                    const currentTagsArray = Array.from(currentCommonTags).sort();
                    const newTagsArray = [...newTagIds].sort();

                    // Check if there are actual changes
                    const hasChanges = currentTagsArray.length !== newTagsArray.length || 
                      currentTagsArray.some((tagId, index) => tagId !== newTagsArray[index]);

                    if (!hasChanges) {
                      return; // Exit if no changes
                    }

                    try {
                      // Get all selected item IDs
                      const selectedIds = selectedItems.map(item => item.id);

                      // Create new set of tags for each item
                      const updatedTags = new Set<string>();
                      
                      // Handle common tags - if a common tag is unchecked, don't include it
                      selectedItems[0].productTags.forEach(tag => {
                        if (!currentCommonTags.has(tag.id) || newTagIds.includes(tag.id)) {
                          updatedTags.add(tag.id);
                        }
                      });
                      
                      // Add any new tags that were checked
                      newTagIds.forEach(tagId => {
                        if (!currentCommonTags.has(tagId)) {
                          updatedTags.add(tagId);
                        }
                      });

                      // Use the already declared updateBulkTags function
                      await updateBulkTags({ itemIds: selectedIds, tagIds: Array.from(updatedTags) });
                    } catch (error) {
                      console.error('Failed to update tags:', error);
                      toast.error('Failed to update tags', {
                        duration: 3000
                      });
                    }
                  }}
                  isBulkEdit={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <ImportModal
        isOpen={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        onImport={async (files, config) => {
          const result = await handleFileUpload(files, config);
          if (result.success) {
            setIsImportModalOpen(false);
          }
        }}
        isUploading={isUploading}
      />
    </>
  );
};
