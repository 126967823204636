import { useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, createColumnHelper } from '@tanstack/react-table';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { knowledgeBaseService } from '../../../services/knowledgebaseService';
import { fileKnowledgeService } from '../../../services/fileKnowledgeService';
import DocumentActionDropdown from '../components/DocumentActionDropdown';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { productTagService } from '../../../services/productTagService';

// Types for KnowledgeBase table
export interface KnowledgeBaseItem {
  id: string;
  title: string;
  description: string;
  sourceName: string;
  productTags: { id: string; name: string }[];
  updatedAt: string;
}

// Types for Documents table
export type DocumentStatus = 'QUEUED' | 'PROCESSING' | 'COMPLETED' | 'FAILED';

const isValidDocumentStatus = (status: string): status is DocumentStatus => {
  return ['QUEUED', 'PROCESSING', 'COMPLETED', 'FAILED'].includes(status);
};

export interface Document {
  id: string;
  fileName: string;
  status: DocumentStatus;
  productTags: { id: string; name: string }[];
  pageCount?: number;
  updatedAt: string;
  createdAt: string;
}

// Response type for Knowledge Base operations
export interface KBResponse {
  data: {
    data: KnowledgeBaseItem;
  };
}

// Add the filtering hooks
export const useFilteredKnowledgeBaseItems = (items: KnowledgeBaseItem[], searchTerm: string) => {
  return useMemo(() => 
    items.filter(item => {
      const searchLower = searchTerm.toLowerCase();
      return item.title.toLowerCase().includes(searchLower) ||
             item.description.toLowerCase().includes(searchLower) ||
             item.sourceName.toLowerCase().includes(searchLower) ||
             item.productTags.some(tag => tag.name.toLowerCase().includes(searchLower));
    }),
    [items, searchTerm]
  );
};

export const useFilteredDocuments = (documents: Document[], searchTerm: string) => {
  return useMemo(() =>
    documents
      .filter(doc => {
        const searchLower = searchTerm.toLowerCase();
        return doc.fileName.toLowerCase().includes(searchLower) ||
               doc.productTags.some(tag => tag.name.toLowerCase().includes(searchLower));
      })
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()),
    [documents, searchTerm]
  );
};

// Add the data fetching hooks at the top of the file
export const useKnowledgeBaseItems = () => {
  const { 
    data = [], 
    error,
    isLoading 
  } = useQuery({
    queryKey: ['knowledgeBase'],
    queryFn: knowledgeBaseService.getKnowledgeBaseItems,
    staleTime: 0
  });

  return {
    knowledgeBaseItems: data,
    error,
    isLoading
  };
};

export const useDocuments = () => {
  const {
    data = [],
    error,
    isLoading
  } = useQuery<Document[]>({
    queryKey: ['documents'],
    queryFn: async () => {
      const docs = await fileKnowledgeService.getDocuments();
      return docs.map(doc => {
        if (!isValidDocumentStatus(doc.status)) {
          console.warn(`Invalid document status: ${doc.status}`);
          // Default to QUEUED if invalid status
          return { ...doc, status: 'QUEUED' as DocumentStatus };
        }
        return { ...doc, status: doc.status };
      });
    },
    staleTime: 0,
    refetchInterval: (query) => 
      query.state.data?.some(doc => doc.status === 'PROCESSING') ? 10000 : false,
  });

  return {
    documents: data,
    error,
    isLoading
  };
};

export const useKnowledgeBaseTable = (data: KnowledgeBaseItem[]) => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const columnHelper = createColumnHelper<KnowledgeBaseItem>();

  const columns = useMemo(() => [
    {
      id: 'select',
      header: ({ table }: any) => (
        <input
          type="checkbox"
          checked={table.getIsAllPageRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
          className="rounded border-gray-300"
        />
      ),
      cell: ({ row }: any) => (
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          className="rounded border-gray-300"
        />
      ),
    },
    columnHelper.accessor('title', {
      header: 'Control',
      cell: (info) => (
        <div>
          <div className="text-sm font-medium">{info.getValue()}</div>
          <div className="text-sm">{info.row.original.description}</div>
        </div>
      ),
    }),
    columnHelper.accessor('productTags', {
      header: 'Tags',
      cell: (info) => (
        <div>{info.getValue().map((t: any) => t.name).join(', ')}</div>
      ),
    }),
    columnHelper.accessor('sourceName', {
      header: 'Source',
      cell: (info) => <span className="text-sm">{info.getValue()}</span>,
    }),
    columnHelper.accessor('updatedAt', {
      header: 'DATE',
      cell: (info) => (
        <span className="text-sm">
          {new Date(info.getValue()).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
        </span>
      ),
    }),
  ], [columnHelper]);

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 10 } },
  });

  return { table, rowSelection, setRowSelection };
};

export const useDocumentsTable = (
  data: Document[],
  activeDropdownId: string | null,
  setActiveDropdownId: (id: string | null) => void,
  handleDownloadDocument: (id: string) => void
) => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const columnHelper = createColumnHelper<Document>();

  const columns = useMemo(() => [
    {
      id: 'select',
      header: ({ table }: any) => (
        <input
          type="checkbox"
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          className="rounded border-gray-300"
        />
      ),
      cell: ({ row }: any) => (
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          className="rounded border-gray-300"
        />
      ),
    },
    columnHelper.accessor('fileName', {
      header: 'FILE NAME',
      cell: (info) => (
        <div className="flex items-center">
          {info.row.original.status === 'PROCESSING' && (
            <div className="group relative mr-2">
              <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2C5.373 2 2 5.373 2 12h2zm2 5.291A7.962 7.962 0 014 12H2c0 3.042 1.135 5.824 3 7.938l1-2.647z"></path>
              </svg>
              <div className="invisible group-hover:visible absolute left-7 top-1/2 -translate-y-1/2 px-2 py-1 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
                Processing
              </div>
            </div>
          )}
          <span className="text-sm font-medium">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor('productTags', {
      header: 'Tags',
      cell: (info) => <span>{info.getValue().map((t: any) => t.name).join(', ')}</span>,
    }),
    columnHelper.accessor('pageCount', {
      header: 'PAGES',
      cell: (info) => <span className="text-sm">{info.getValue() || '-'}</span>,
    }),
    columnHelper.accessor('updatedAt', {
      header: 'DATE',
      cell: (info) => (
        <span className="text-sm">
          {new Date(info.getValue()).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
        </span>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <div className="text-right"></div>,
      cell: ({ row }: any) => (
        <div className="text-right">
          <DocumentActionDropdown
            onDownload={() => handleDownloadDocument(row.original.id)}
            isOpen={activeDropdownId === row.original.id}
            setIsOpen={(isOpen: boolean) => setActiveDropdownId(isOpen ? row.original.id : null)}
          />
        </div>
      ),
    }),
  ], [activeDropdownId, columnHelper, handleDownloadDocument, setActiveDropdownId]);

  const documentsTable = useReactTable({
    data,
    columns,
    state: { rowSelection },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 10 } },
  });

  return { documentsTable, rowSelection, setRowSelection };
};

// Add mutation hooks
export const useDeleteKnowledgeBaseItems = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (ids: string[]) => knowledgeBaseService.deleteKnowledgeBaseItem(ids),
    onSuccess: (response: { message: string }) => {
      queryClient.invalidateQueries({ queryKey: ['knowledgeBase'] });
      toast.success(response.message);
    },
    onError: (error) => {
      toast.error('Failed to delete items');
      console.error('Delete error:', error);
    }
  });
};

export const useDeleteDocuments = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (ids: string[]) => fileKnowledgeService.deleteDocument(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      toast.success('Documents deleted successfully', {
        duration: 3000
      });
    },
    onError: (error) => {
      toast.error('Failed to delete documents');
      console.error('Delete documents error:', error);
    }
  });
};

// Add the upload/import hooks
export const useDocumentUpload = () => {
  const queryClient = useQueryClient();
  const [isUploading, setIsUploading] = useState(false);

  const uploadDocuments = async (files: File[], config?: ImportConfig) => {
    try {
      setIsUploading(true);
      
      // Process files one by one to maintain optimistic UI updates
      for (const file of files) {
        try {
          // Get presigned URL first to get documentId for optimistic update
          const { uploadUrl, documentId } = await fileKnowledgeService.getPresignedUrl(
            file.name, 
            file.type
          );

          // Add the new document to the list with QUEUED status immediately
          queryClient.setQueryData(['documents'], (oldData: Document[] = []) => [
            ...oldData,
            {
              id: documentId,
              fileName: file.name,
              status: 'QUEUED',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              productTags: []
            }
          ]);

          // Complete the upload and processing
          await fileKnowledgeService.uploadDocument(file, uploadUrl);
          await fileKnowledgeService.processDocument(documentId, config?.productTags);

        } catch (error) {
          console.error('Error uploading file:', file.name, error);
          toast.error(`Failed to upload ${file.name}`);
        }
      }

      // Refresh the documents list
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      
      toast.success('Documents uploaded successfully');
      
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error instanceof Error ? error.message : 'An error occurred during upload');
    } finally {
      setIsUploading(false);
    }

    return { success: true };
  };

  return { uploadDocuments, isUploading };
};

export const useQAUpload = () => {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);

  const uploadQA = async (file: File) => {
    try {
      setIsUploading(true);
      
      // Handle Q&A pairs upload (Excel files)
      const { uploadUrl, questionnaireId } = await knowledgeBaseService.getPresignedKBUrl(
        file.name, 
        file.type
      );

      // Perform the actual upload to S3
      await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      // Navigate to mapping page
      navigate(`/kb-mapping/${questionnaireId}`);
      return { success: true };
      
    } catch (error) {
      console.error('Upload failed:', error);
      toast.error('Failed to import file', {
        duration: 3000
      });
      return { success: false, error };
    } finally {
      setIsUploading(false);
    }
  };

  return { uploadQA, isUploading };
};

// Add ImportConfig type
export interface ImportConfig {
  productTags: string[];
}

// Add the tag management hooks
export interface Tag {
  id: string;
  name: string;
}

export const useTagManagement = () => {
  const queryClient = useQueryClient();

  // Fetch all available tags
  const { data: allTags = [] } = useQuery({
    queryKey: ['productTags'],
    queryFn: productTagService.getTags,
    staleTime: 60000 // Cache for 1 minute
  });

  // Create tag mutation
  const createTagMutation = useMutation({
    mutationFn: productTagService.createTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['productTags'] });
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to create tag', {
        duration: 3000
      });
    }
  });

  // Delete tag mutation
  const deleteTagMutation = useMutation({
    mutationFn: productTagService.deleteTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['productTags'] });
      queryClient.invalidateQueries({ queryKey: ['knowledgeBase'] });
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      toast.success('Tag deleted successfully', {
        duration: 3000,
        position: 'top-center',
      });
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to delete tag', {
        duration: 3000,
        position: 'top-center',
      });
    }
  });

  // Update tag mutation
  const updateTagMutation = useMutation({
    mutationFn: ({ id, name }: { id: string, name: string }) => productTagService.updateTag(id, name),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['productTags'] });
      toast.success('Tag updated successfully', {
        duration: 3000,
        position: 'top-center',
      });
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to update tag', {
        duration: 3000,
        position: 'top-center',
      });
    }
  });

  return {
    allTags,
    createTag: createTagMutation.mutate,
    deleteTag: deleteTagMutation.mutate,
    updateTag: updateTagMutation.mutate,
    isCreatingTag: createTagMutation.isPending,
    isDeletingTag: deleteTagMutation.isPending,
    isUpdatingTag: updateTagMutation.isPending
  };
};

export const useItemTags = (itemId: string, itemType: 'qa' | 'documents') => {
  const queryClient = useQueryClient();
  const { allTags } = useTagManagement();

  // Update tags mutation
  const updateTagsMutation = useMutation({
    mutationFn: (tagIds: string[]) => productTagService.updateItemTags(
      itemId,
      tagIds,
      itemType === 'qa' ? 'knowledge_base' : 'file_knowledge_base'
    ),
    onMutate: async (newTagIds) => {
      // Cancel outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['knowledgeBase'] });
      await queryClient.cancelQueries({ queryKey: ['documents'] });

      // Get the current data
      const prevKnowledgeBase = queryClient.getQueryData(['knowledgeBase']);
      const prevDocuments = queryClient.getQueryData(['documents']);

      // Optimistically update the UI
      if (itemType === 'qa') {
        queryClient.setQueryData(['knowledgeBase'], (old: KnowledgeBaseItem[] = []) =>
          old.map(item =>
            item.id === itemId
              ? {
                  ...item,
                  productTags: newTagIds.map(id => ({
                    id,
                    name: allTags.find(t => t.id === id)?.name || ''
                  }))
                }
              : item
          )
        );
      } else {
        queryClient.setQueryData(['documents'], (old: Document[] = []) =>
          old.map(doc =>
            doc.id === itemId
              ? {
                  ...doc,
                  productTags: newTagIds.map(id => ({
                    id,
                    name: allTags.find(t => t.id === id)?.name || ''
                  }))
                }
              : doc
          )
        );
      }

      return { prevKnowledgeBase, prevDocuments };
    },
    onError: (error: any, _newTagIds, context) => {
      // Revert the optimistic update on error
      if (context) {
        queryClient.setQueryData(['knowledgeBase'], context.prevKnowledgeBase);
        queryClient.setQueryData(['documents'], context.prevDocuments);
      }
      toast.error(error.response?.data?.error || 'Failed to update tags', {
        duration: 3000
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['knowledgeBase'] });
      queryClient.invalidateQueries({ queryKey: ['documents'] });
    }
  });

  return {
    updateTags: updateTagsMutation.mutate,
    isUpdatingTags: updateTagsMutation.isPending
  };
};

export const useBulkTagUpdate = (itemType: 'qa' | 'documents') => {
  const queryClient = useQueryClient();

  const updateBulkTagsMutation = useMutation({
    mutationFn: async ({ itemIds, tagIds }: { itemIds: string[], tagIds: string[] }) => {
      await productTagService.updateItemTags(
        itemIds,
        tagIds,
        itemType === 'qa' ? 'knowledge_base' : 'file_knowledge_base'
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['knowledgeBase'] });
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      toast.success('Tags updated successfully', {
        duration: 3000,
        position: 'top-center',
      });
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to update tags', {
        duration: 3000,
        position: 'top-center',
      });
    }
  });

  return {
    updateBulkTags: updateBulkTagsMutation.mutate,
    isUpdatingBulkTags: updateBulkTagsMutation.isPending
  };
};

export const useDocumentDownload = () => {
  const downloadDocument = async (documentId: string, fileName: string) => {
    try {
      toast.loading('Preparing download...', { id: 'download' });
      const { downloadUrl } = await fileKnowledgeService.getDownloadUrl(documentId);
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success('Download started', { id: 'download' });
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Failed to download document', { id: 'download' });
    }
  };

  return { downloadDocument };
};

export const useFileUploadHandler = () => {
  const { uploadDocuments } = useDocumentUpload();
  const { uploadQA } = useQAUpload();

  const handleFileUpload = async (files: File[], config?: ImportConfig) => {
    try {
      // Check if it's an Excel file
      const isExcelFile = files[0]?.name.toLowerCase().endsWith('.xlsx');
      
      if (isExcelFile) {
        // Handle Q&A pairs upload (Excel files)
        return await uploadQA(files[0]);
      } else {
        // Handle document upload
        return await uploadDocuments(files, config);
      }
    } catch (error) {
      console.error('Upload failed:', error);
      toast.error('Failed to import file');
      return { success: false, error };
    }
  };

  return { handleFileUpload };
};

export const useUpdateKnowledgeBaseItem = () => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation({
    mutationFn: ({ id, title, description }: { id: string; title: string; description: string }) =>
      knowledgeBaseService.updateKnowledgeBaseItem(id, { title, description }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['knowledgeBase'] });
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to update item', {
        duration: 3000,
        position: 'top-center',
      });
    }
  });

  return {
    updateItem: updateMutation.mutate,
    isUpdating: updateMutation.isPending
  };
};
