import { io, Socket } from 'socket.io-client';
import { Message } from '../pages/Chat';

interface ChatMessage {
  content: string;
  role?: string;
  organizationId: string;
  userId?: string;
  conversationHistory?: Array<{
    role: string;
    content: string;
  }>;
}

export class WebSocketService {
  private static instance: WebSocketService;
  private socket: Socket | null = null;
  private messageHandlers: ((message: Message) => void)[] = [];
  private typingHandlers: ((isTyping: boolean) => void)[] = [];
  private errorHandlers: ((error: any) => void)[] = [];
  private connectHandlers: (() => void)[] = [];
  private disconnectHandlers: (() => void)[] = [];

  private constructor() {}

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  public connect() {
    if (this.socket?.connected) {
      console.log('[WEBSOCKET_DEBUG] Already connected to WebSocket server');
      console.log('[WEBSOCKET_DEBUG] Existing socket ID:', this.socket.id);
      return;
    }

    const url = import.meta.env.VITE_API_URL || 'http://localhost:5000';
    console.log('[WEBSOCKET_DEBUG] Connecting to WebSocket server at:', url);

    this.socket = io(url, {
      withCredentials: true,
      transports: ['websocket', 'polling'],
      path: '/socket.io',
      auth: { token: 'cookie' }
    });

    this.setupEventListeners();
  }

  public disconnect() {
    if (this.socket) {
      console.log('Disconnecting from WebSocket server');
      this.socket.disconnect();
      this.socket = null;
    }
  }

  private setupEventListeners() {
    if (!this.socket) {
      console.error('[WEBSOCKET_DEBUG] Cannot setup listeners: socket is null');
      return;
    }

    this.socket.on('connect', () => {
      console.log('[WEBSOCKET_DEBUG] Connected to WebSocket server with ID:', this.socket?.id);
      console.log('[WEBSOCKET_DEBUG] Number of message handlers:', this.messageHandlers.length);
      console.log('[WEBSOCKET_DEBUG] Number of typing handlers:', this.typingHandlers.length);
      this.connectHandlers.forEach(handler => handler());
    });

    this.socket.on('disconnect', (reason) => {
      console.log('[WEBSOCKET_DEBUG] Disconnected from WebSocket server:', reason);
      console.log('[WEBSOCKET_DEBUG] Socket ID at disconnect:', this.socket?.id);
      this.disconnectHandlers.forEach(handler => handler());
    });

    this.socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error.message, error);
      if (error.message.includes('Authentication error')) {
        const event = new CustomEvent('session-expired');
        window.dispatchEvent(event);
      }
      this.errorHandlers.forEach(handler => handler(error));
    });

    this.socket.on('chat:message', (response: any) => {
      console.log('[WEBSOCKET_DEBUG] Received chat message response for socket:', this.socket?.id);
      console.log('[WEBSOCKET_DEBUG] Raw response:', response);
      
      const messageContent = response.content;
      if (!messageContent) {
        console.error('[WEBSOCKET_DEBUG] No content found in response:', response);
        return;
      }

      const message: Message = {
        id: response.id || Date.now().toString(),
        role: response.role || 'assistant',
        content: messageContent,
        timestamp: response.timestamp ? new Date(response.timestamp) : new Date(),
        sources: response.sources || [],
        metadata: response.metadata || {}
      };

      console.log('[WEBSOCKET_DEBUG] Formatted message for display:', message);
      console.log('[WEBSOCKET_DEBUG] Number of handlers to notify:', this.messageHandlers.length);
      this.messageHandlers.forEach(handler => handler(message));
    });

    this.socket.on('chat:typing', (isTyping: boolean) => {
      console.log('[WEBSOCKET_DEBUG] Typing indicator for socket:', this.socket?.id, isTyping);
      console.log('[WEBSOCKET_DEBUG] Number of typing handlers:', this.typingHandlers.length);
      this.typingHandlers.forEach(handler => handler(isTyping));
    });

    this.socket.on('chat:error', (error) => {
      console.error('Chat error for socket:', this.socket?.id, error);
      this.errorHandlers.forEach(handler => handler(error));
      // Also stop typing indicator on error
      this.typingHandlers.forEach(handler => handler(false));
    });
  }

  public sendMessage(message: ChatMessage) {
    if (!this.socket?.connected) {
      console.error('[WEBSOCKET_DEBUG] Cannot send message: socket not connected');
      return;
    }

    const messageContent = typeof message === 'string' ? message : message.content;

    if (!messageContent) {
      console.error('[WEBSOCKET_DEBUG] Cannot send empty message');
      return;
    }

    const formattedMessage = {
      message: messageContent,
      context: {
        organizationId: typeof message === 'string' ? undefined : message.organizationId,
        timestamp: new Date().toISOString(),
        conversationHistory: typeof message === 'string' ? undefined : message.conversationHistory
      }
    };

    console.log('[WEBSOCKET_DEBUG] Sending message from socket:', this.socket.id);
    console.log('[WEBSOCKET_DEBUG] Formatted message:', formattedMessage);
    this.socket.emit('chat:message', formattedMessage);
  }

  public onMessage(handler: (message: Message) => void) {
    console.log('[WEBSOCKET_DEBUG] Adding message handler, current count:', this.messageHandlers.length);
    this.messageHandlers.push(handler);
    return () => {
      console.log('[WEBSOCKET_DEBUG] Removing message handler');
      this.messageHandlers = this.messageHandlers.filter(h => h !== handler);
      console.log('[WEBSOCKET_DEBUG] Remaining handlers:', this.messageHandlers.length);
    };
  }

  public onTyping(handler: (isTyping: boolean) => void) {
    this.typingHandlers.push(handler);
    return () => {
      this.typingHandlers = this.typingHandlers.filter(h => h !== handler);
    };
  }

  public onError(handler: (error: any) => void) {
    this.errorHandlers.push(handler);
    return () => {
      this.errorHandlers = this.errorHandlers.filter(h => h !== handler);
    };
  }

  public onConnect(handler: () => void) {
    this.connectHandlers.push(handler);
    return () => {
      this.connectHandlers = this.connectHandlers.filter(h => h !== handler);
    };
  }

  public onDisconnect(handler: () => void) {
    this.disconnectHandlers.push(handler);
    return () => {
      this.disconnectHandlers = this.disconnectHandlers.filter(h => h !== handler);
    };
  }
} 