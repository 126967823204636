import React, { useState, useRef, useEffect, createContext, useContext } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { WebSocketService } from '../services/WebSocketService';
import { useNavigate } from 'react-router-dom';

// Define message types
export type MessageRole = 'user' | 'assistant' | 'system';

export interface Message {
  id: string;
  role: MessageRole;
  content: string;
  timestamp: Date;
  sources?: string[];
  metadata?: any;
}

// Create Chat Context
interface ChatContextType {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const ChatContext = createContext<ChatContextType | null>(null);

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  return (
    <ChatContext.Provider value={{ messages, setMessages }}>
      {children}
    </ChatContext.Provider>
  );
};

const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

export const Chat: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { messages, setMessages } = useChatContext();
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [copiedMessageId, setCopiedMessageId] = useState<string | null>(null);
  const webSocketService = WebSocketService.getInstance();
  const messageCounterRef = useRef(0);

  // Function to generate unique message ID
  const generateMessageId = (role: MessageRole) => {
    messageCounterRef.current += 1;
    return `${role}-${messageCounterRef.current}`;
  };

  // Check authentication
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
  }, [user, navigate]);

  // Auto-scroll to bottom of messages
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages, isTyping]);

  // Auto-resize textarea based on content
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [input]);

  // Setup WebSocket connection and event handlers
  useEffect(() => {
    if (!user) {
      console.log('No user found - redirecting to login');
      navigate('/login');
      return;
    }

    // Connect to WebSocket server - no need to pass token as we're using cookies
    webSocketService.connect();

    // Setup connection status handler
    const connectionHandler = () => {
      setIsConnected(true);
    };

    const disconnectionHandler = () => {
      setIsConnected(false);
    };

    // Setup message handler
    const messageHandler = (message: Message) => {
      setMessages(prev => [...prev, {
        ...message,
        id: generateMessageId('assistant'),
        timestamp: new Date(message.timestamp)
      }]);
      setIsTyping(false);
    };

    // Setup typing indicator handler
    const typingHandler = (isTyping: boolean) => {
      setIsTyping(isTyping);
    };

    // Setup error handler
    const errorHandler = (error: any) => {
      console.error('WebSocket error details:', {
        error,
        user: user?.organizationId,
        connectionStatus: isConnected
      });
      
      let errorMessage = 'Sorry, there was an error processing your message. ';
      if (!user?.organizationId) {
        errorMessage += 'Organization ID is missing. ';
      }
      if (!isConnected) {
        errorMessage += 'WebSocket is not connected. ';
      }
      errorMessage += 'Please try again.';

      setMessages(prev => [...prev, {
        id: generateMessageId('system'),
        role: 'system',
        content: errorMessage,
        timestamp: new Date()
      }]);
      setIsTyping(false);
    };

    // Subscribe to events and store cleanup functions
    const cleanupFunctions = [
      webSocketService.onConnect(connectionHandler),
      webSocketService.onDisconnect(disconnectionHandler),
      webSocketService.onMessage(messageHandler),
      webSocketService.onTyping(typingHandler),
      webSocketService.onError(errorHandler)
    ];

    // Listen for session expiry
    const handleSessionExpired = () => {
      console.log('Session expired - redirecting to login');
      navigate('/login');
    };
    window.addEventListener('session-expired', handleSessionExpired);

    // Cleanup
    return () => {
      // Clean up all event handlers
      cleanupFunctions.forEach(cleanup => cleanup());
      window.removeEventListener('session-expired', handleSessionExpired);
      webSocketService.disconnect();
    };
  }, [user, navigate]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || !isConnected || !user?.organizationId) return;

    // Create user message
    const userMessage: Message = {
      id: generateMessageId('user'),
      role: 'user',
      content: input.trim(),
      timestamp: new Date(),
    };

    // Add user message to chat
    setMessages(prev => [...prev, userMessage]);
    setInput('');

    // Format conversation history
    const conversationHistory = messages.map(msg => ({
      role: msg.role,
      content: msg.content
    }));

    // Send message through WebSocket with history
    webSocketService.sendMessage({
      content: input.trim(),
      organizationId: user.organizationId,
      conversationHistory: conversationHistory
    });
  };

  const handleCopy = async (text: string, messageId: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedMessageId(messageId);
      setTimeout(() => setCopiedMessageId(null), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Chat Header */}
      <div className="border-b border-gray-200 p-4">
        <h1 className="text-xl font-semibold text-gray-800">Assistant</h1>
        <div className="text-sm text-gray-500">
          {isConnected ? 'Connected' : 'Disconnected'}
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 bg-white">
        {messages.length === 0 ? (
          <div className="h-full flex flex-col items-center justify-center -mt-32">
            <h2 className="text-2xl font-semibold text-gray-800 mb-8">How can I help you?</h2>
            <div className="w-full max-w-2xl">
              <form onSubmit={handleSendMessage}>
                <div className="relative">
                  <textarea
                    ref={inputRef}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Ask anything..."
                    className="w-full bg-gray-50 rounded-lg py-4 px-4 pr-14 border border-transparent focus:outline-none focus:border focus:border-[#1e8259] resize-none overflow-y-auto max-h-32 text-base"
                    rows={2}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage(e);
                      }
                    }}
                    disabled={!isConnected}
                  />
                  <button
                    type="submit"
                    className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors bg-gray-50 flex items-center"
                    disabled={!input.trim() || !isConnected || isTyping}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                    </svg>
                  </button>
                </div>
                <div className="text-xs text-gray-500 mt-2 text-center">
                  Press Enter to send, Shift+Enter for a new line
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="max-w-6xl mx-auto px-8">
            {messages.map((message, index, arr) => (
              <div
                key={message.id}
                className={`${
                  message.role === 'user' ? 'flex justify-end' : ''
                } ${
                  index > 0 && arr[index - 1].role === message.role
                    ? 'mt-2'
                    : 'mt-6'
                }`}
              >
                <div className={`flex items-start ${message.role === 'user' ? 'max-w-[75%] flex-row-reverse' : 'w-full'}`}>
                  <div
                    className={`${
                      message.role === 'user'
                        ? 'bg-gray-50 text-gray-800 rounded-lg px-4 py-3 max-w-lg'
                        : 'text-gray-800 px-1'
                    }`}
                  >
                    <div className={`whitespace-pre-wrap break-words ${message.role === 'assistant' ? 'text-gray-800 leading-relaxed' : ''}`}>
                      {message.content}
                    </div>
                    {message.role === 'assistant' && (
                      <div className="mt-2 flex items-center">
                        <button
                          onClick={() => handleCopy(message.content, message.id)}
                          className="text-gray-300 hover:text-gray-500 transition-colors"
                        >
                          {copiedMessageId === message.id ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                            </svg>
                          )}
                        </button>
                      </div>
                    )}
                    {message.sources && message.sources.length > 0 && (
                      <div className="mt-3 space-y-2">
                        <div className="text-xs text-gray-400">Sources:</div>
                        {Object.entries(message.metadata || {}).map(([sourceId, meta]: [string, any]) => (
                          <div key={sourceId} className="text-xs text-gray-400">
                            <div className="font-medium text-gray-500">{meta.title}</div>
                            <div className="text-gray-400">
                              {meta.content && (
                                <div className="line-clamp-2 mt-0.5">{meta.content}</div>
                              )}
                              <div className="mt-1 flex items-center gap-2">
                                <span className="text-gray-400">{meta.source_name}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {isTyping && (
              <div className="mt-2">
                <div className="flex items-start">
                  <div className="bg-white text-gray-800 rounded-lg px-4 py-3 max-w-lg">
                    <div className="flex flex-row gap-2">
                      <div className="w-2 h-2 rounded-full bg-gray-500 animate-bounce"></div>
                      <div className="w-2 h-2 rounded-full bg-gray-500 animate-bounce [animation-delay:-.3s]"></div>
                      <div className="w-2 h-2 rounded-full bg-gray-500 animate-bounce [animation-delay:-.5s]"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        )}
      </div>

      {/* Input Area */}
      {messages.length > 0 && (
        <div className="p-4">
          <form onSubmit={handleSendMessage} className="max-w-6xl mx-auto px-8">
            <div className="relative">
              <textarea
                ref={inputRef}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Ask anything..."
                className="w-full bg-gray-50 rounded-lg py-4 px-4 pr-14 border border-transparent focus:outline-none focus:border focus:border-[#1e8259] resize-none overflow-y-auto max-h-32 text-base"
                rows={2}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage(e);
                  }
                }}
                disabled={!isConnected}
              />
              <button
                type="submit"
                className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors bg-gray-50 flex items-center"
                disabled={!input.trim() || !isConnected || isTyping}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                </svg>
              </button>
            </div>
            <div className="text-xs text-gray-500 mt-2 text-center">
              Press Enter to send, Shift+Enter for a new line
            </div>
          </form>
        </div>
      )}
    </div>
  );
}; 