import React, { useState, useEffect, useMemo } from 'react';
import questionnaireService from '../services/questionnaireService';
import { useNavigate } from 'react-router-dom';
import { CubeTransparentIcon, ArrowDownTrayIcon, TrashIcon, CheckCircleIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { useReactTable, getCoreRowModel, flexRender, createColumnHelper, getPaginationRowModel } from '@tanstack/react-table';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { productTagService } from '../services/productTagService';

interface QuestionnaireResponse {
  id: string;
  fileName: string;
  uploadedBy: string;
  uploadDate: string;
  modifiedDate: string;
  totalQuestions: number;
  answeredQuestions: number;
  status: string;
  productTags: { id: string; name: string }[];
}

interface QuestionnaireUsage {
  current: number;
  allowed: number;
  status: string;
}

interface QuestionnairesApiResponse {
  data: QuestionnaireResponse[];
  usage: QuestionnaireUsage;
}

interface Questionnaire {
  id: string;
  fileName: string;
  uploadedBy: string;
  uploadDate: string;
  modifiedDate: string;
  progress: number;
  answeredQuestions: number;
  totalQuestions: number;
  status: string;
  productTags: { id: string; name: string }[];
}

interface DropdownProps {
  onExport: () => void;
  onExportCsv: () => void;
  onDelete: (e: React.MouseEvent) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface ImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImport: (file: File, config: ImportConfig) => void;
  isUploading: boolean;
}

interface ImportConfig {
  productTags: string[];
}

interface Tag {
  id: string;
  name: string;
}

const ActionDropdown: React.FC<DropdownProps> = ({ onExport, onExportCsv, onDelete, isOpen, setIsOpen }) => {
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && !(event.target as Element).closest('.dropdown-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen, setIsOpen]);

  return (
    <div className="relative inline-block text-left dropdown-container">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="p-2 rounded-lg hover:bg-gray-50 transition-colors"
      >
        <svg className="h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="py-1">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onExport();
                setIsOpen(false);
              }}
              className="group flex w-full px-4 py-3 text-sm items-center hover:bg-gray-50 text-gray-700"
            >
              <ArrowDownTrayIcon className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-500 shrink-0" aria-hidden="true" />
              <div className="flex-1 text-left">
                <p className="font-medium text-sm leading-5">Export Questionnaire</p>
                <p className="text-xs text-gray-500">Download the completed questionnaire in original format.</p>
              </div>
            </button>

            <button
              onClick={(e) => {
                e.stopPropagation();
                onExportCsv();
                setIsOpen(false);
              }}
              className="group flex w-full px-4 py-3 text-sm items-center hover:bg-gray-50 text-gray-700"
            >
              <ArrowDownTrayIcon className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-500 shrink-0" aria-hidden="true" />
              <div className="flex-1 text-left">
                <p className="font-medium text-sm leading-5">Export as CSV</p>
                <p className="text-xs text-gray-500">Download the completed questionnaire in CSV format.</p>
              </div>
            </button>

            <button
              onClick={(e) => {
                onDelete(e);
                setIsOpen(false);
              }}
              className="group flex w-full px-4 py-3 text-sm items-center hover:bg-gray-50 text-gray-700"
            >
              <TrashIcon className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-500 shrink-0" aria-hidden="true" />
              <div className="flex-1 text-left">
                <p className="font-medium text-sm leading-5">Delete</p>
                <p className="text-xs text-gray-500">Remove this questionnaire</p>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Add the Tag component from KnowledgeBase
const Tag: React.FC<{ tag: { id: string; name: string } }> = React.memo(({ tag }) => (
  <span
    className="inline-flex items-center px-1.5 py-0.5 text-xs text-gray-600 border border-gray-200 rounded w-fit mb-1 max-w-[120px]"
  >
    <span className="truncate" title={tag.name}>{tag.name}</span>
  </span>
));

Tag.displayName = 'Tag';

const ImportModal: React.FC<ImportModalProps> = ({ isOpen, onClose, onImport, isUploading }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  
  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setSelectedFile(null);
      setSelectedTags([]);
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const tags = await productTagService.getTags();
        setAvailableTags(tags);
      } catch (error) {
        console.error('Failed to fetch tags:', error);
        toast.error('Failed to load product tags');
      }
    };
    
    if (isOpen) {
      fetchTags();
    }
  }, [isOpen]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
      if (file.size > MAX_FILE_SIZE) {
        toast.error('File too large. Maximum size is 10MB');
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleTagToggle = (tagId: string) => {
    setSelectedTags(prev => {
      const newTags = prev.includes(tagId)
        ? prev.filter(id => id !== tagId)
        : [...prev, tagId];
      return newTags;
    });
  };

  const handleImport = () => {
    if (selectedFile) {
      onImport(selectedFile, { productTags: selectedTags });
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/25 backdrop-blur-sm z-[9999] flex items-center justify-center overflow-hidden"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-xl shadow-xl w-full max-w-lg mx-4 relative">
        {/* Header */}
        <div className="flex items-center justify-between px-8 py-10">
          <h3 className="text-lg font-semibold text-gray-900">Import Questionnaire</h3>
        </div>

        {/* Content */}
        <div className="px-8 pb-10 space-y-10">
          {/* File Upload Section */}
          <div>
            <label className="block text-sm font-medium text-gray-900 mb-2">
              Questionnaire File
            </label>
            <p className="text-sm text-gray-500 mb-5">Upload an Excel or Word file containing your questionnaire data.</p>
            {selectedFile ? (
              <div className="flex items-center justify-between p-6 bg-gray-50 rounded-lg">
                <div className="flex items-center space-x-4 min-w-0 flex-1">
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 truncate">{selectedFile.name}</p>
                    <p className="text-xs text-gray-500 mt-1">
                      {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => setSelectedFile(null)}
                  className="ml-4 text-sm text-gray-500 hover:text-gray-700 whitespace-nowrap flex-shrink-0"
                >
                  Change
                </button>
              </div>
            ) : (
              <button
                onClick={() => {
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = '.xlsx,.docx';
                  input.onchange = (e) => handleFileSelect(e as any);
                  input.click();
                }}
                className="flex items-center justify-center w-full p-10 border-2 border-dashed
                  border-gray-200 rounded-lg hover:border-gray-300 transition-colors"
              >
                <div className="text-center">
                  <ArrowUpTrayIcon className="h-6 w-6 mx-auto text-gray-400 mb-4" />
                  <p className="text-sm font-medium text-gray-900">Click to upload</p>
                  <p className="text-xs text-gray-500 mt-2">Supported formats: .xlsx, .docx</p>
                </div>
              </button>
            )}
          </div>

          {/* Tags Section */}
          <div>
            <label className="block text-sm font-medium text-gray-900 mb-2">
              Product Tags
            </label>
            <p className="text-sm text-gray-500 mb-5">
              Select tags to limit the AI to use knowledge from specific areas only. Leave empty to use all available knowledge.
            </p>
            {availableTags.length > 0 ? (
              <div className="flex flex-wrap gap-3">
                {availableTags.map((tag) => (
                  <button
                    key={tag.id}
                    onClick={() => handleTagToggle(tag.id)}
                    className={`inline-flex items-center px-2.5 py-1 text-sm rounded w-fit min-h-[28px]
                      transition-all border ${
                      selectedTags.includes(tag.id)
                        ? 'text-[#1e8259] border-[#1e8259]/20 bg-[#1e8259]/10'
                        : 'text-gray-600 border-gray-200 hover:border-gray-300'
                    }`}
                  >
                    <span className="truncate max-w-[160px]">{tag.name}</span>
                  </button>
                ))}
              </div>
            ) : (
              <div className="flex items-center">
                <p className="text-xs text-gray-500">No tags created yet.</p>
              </div>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="px-8 py-6 flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
          >
            Cancel
          </button>
          <button
            onClick={handleImport}
            disabled={!selectedFile || isUploading}
            className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium
              text-[#1e8259] border border-[#1e8259] hover:bg-[#1e8259] hover:text-white transition-all duration-200
              disabled:opacity-50 disabled:cursor-not-allowed min-w-[100px] justify-center"
          >
            {isUploading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-current border-t-transparent mr-2.5"></div>
                Importing...
              </>
            ) : (
              'Import'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export const QuestionnaireOverview: React.FC = () => {
  const queryClient = useQueryClient();
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [usage, setUsage] = useState<QuestionnaireUsage | null>(null);
  const pollingInterval = 10000;
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const { data: questionnaireData, isLoading } = useQuery<QuestionnairesApiResponse, Error>({
    queryKey: ['questionnaires'],
    queryFn: async () => {
      const response = await questionnaireService.getQuestionnaires();
      return response as QuestionnairesApiResponse;
    },
    refetchInterval: (query) => {
      const data = query.state.data;
      if (!data) return false;
      return data.data.some(q => q.status.startsWith('PROCESSING')) ? pollingInterval : false;
    },
  });

  useEffect(() => {
    if (questionnaireData?.data) {
      const formattedQuestionnaires = questionnaireData.data.map((q) => ({
        id: q.id,
        fileName: q.fileName,
        uploadedBy: q.uploadedBy,
        uploadDate: q.uploadDate,
        modifiedDate: q.modifiedDate,
        progress: Math.round((q.answeredQuestions / q.totalQuestions) * 100) || 0,
        answeredQuestions: q.answeredQuestions,
        totalQuestions: q.totalQuestions,
        status: q.status,
        productTags: q.productTags || []
      }));

      setQuestionnaires(formattedQuestionnaires);
      setUsage(questionnaireData.usage);
    } else {
      setQuestionnaires([]);
    }
  }, [questionnaireData]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleFileUpload = async (file: File, config: ImportConfig) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('productTags', JSON.stringify(config.productTags));

      const response = await questionnaireService.uploadFile(formData);
      console.log('Upload successful:', response);
      // Instead of calling refreshQuestionnaires, invalidate the query
      queryClient.invalidateQueries({ queryKey: ['questionnaires'] });
      setIsImportModalOpen(false);
      toast.success('Questionnaire imported successfully');
    } catch (error) {
      console.error('Upload failed:', error);
      toast.error('Failed to import questionnaire');
    } finally {
      setIsUploading(false);
    }
  };

  const handleQuestionnaireClick = (questionnaire: Questionnaire, event: React.MouseEvent) => {
    // Check if clicking on menu or its children
    const target = event.target as HTMLElement;
    if (target.closest('.menu-button') || target.closest('[role="menu"]')) {
      return;
    }
    
    // Check subscription status first
    if (usage?.status === 'canceled') {
      toast.error('Your subscription has expired. Please renew your subscription.');
      return;
    }

    // Then check questionnaire limit
    if (usage && usage.current >= usage.allowed) {
      toast.error('You have reached your questionnaire limit. Please upgrade your plan.');
      return;
    }
    
    // Check if the file is a Word document
    const isWordDocument = questionnaire.fileName.toLowerCase().endsWith('.docx');
    
    // Route based on file type and status with forceRefresh state parameter
    if (isWordDocument && questionnaire.status !== 'MAPPED' && !questionnaire.status.startsWith('PROCESSING')) {
      // For Word documents that haven't been mapped yet, go to the import review page
      navigate(`/questionnaire/${questionnaire.id}/import-review`);
    } else if (questionnaire.status === 'MAPPED' || questionnaire.status.startsWith('PROCESSING')) {
      // For both Excel and Word files that are already mapped or being processed
      navigate(`/questionnaire/${questionnaire.id}/edit`, { 
        state: { forceRefresh: true } 
      });
    } else {
      // For unmapped Excel files
      navigate(`/qmapping/${questionnaire.id}`);
    }
  };

  const handleExport = async (questionnaire: Questionnaire) => {
    try {
      toast.loading('Exporting questionnaire...');
      const response = await questionnaireService.exportQuestionnaire(questionnaire.id);
      
      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      link.href = response.downloadUrl;
      link.download = questionnaire.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.dismiss();
      toast.success('Export successful');
    } catch (error) {
      console.error('Export failed:', error);
      toast.dismiss();
      toast.error('Failed to export questionnaire');
    }
  };

  // Add the CSV export handler
  const handleExportCsv = async (questionnaire: Questionnaire) => {
    try {
      toast.loading('Exporting questionnaire to CSV...');
      await questionnaireService.exportQuestionnaireAsCsv(questionnaire.id);
      toast.dismiss();
      toast.success('CSV export successful');
    } catch (error) {
      console.error('CSV export failed:', error);
      toast.dismiss();
      toast.error('Failed to export questionnaire to CSV');
    }
  };

  // Add the delete handler
  const handleDelete = async (questionnaire: Questionnaire) => {
    try {
      await questionnaireService.deleteQuestionnaire(questionnaire.id);
      // Instead of calling refreshQuestionnaires, invalidate the query
      queryClient.invalidateQueries({ queryKey: ['questionnaires'] });
      toast.dismiss();
      toast.success('Questionnaire deleted');
    } catch (error) {
      console.error('Delete failed:', error);
      toast.dismiss();
      toast.error('Failed to delete questionnaire.');
    }
  };

  // Define columns
  const columnHelper = createColumnHelper<Questionnaire>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('fileName', {
        header: 'File Name',
        cell: (info: any) => {
          const fileName = info.getValue();
          
          return (
            <div className="flex items-center">
              <div className="text-sm font-medium text-gray-900">
                {fileName}
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor('progress', {
        header: 'Progress',
        cell: (info) => {
          const questionnaire = info.row.original;
          
          if (questionnaire.status.startsWith('PROCESSING')) {
            const processingMatch = questionnaire.status.match(/PROCESSING\((\d+)\)/);
            const processingPercentage = processingMatch ? processingMatch[1] : null;
            
            return (
              <div className="group relative">
                <div className="flex items-center">
                  <svg className="animate-spin h-5 w-5 text-gray-500 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2C5.373 2 2 5.373 2 12h2zm2 5.291A7.962 7.962 0 014 12H2c0 3.042 1.135 5.824 3 7.938l1-2.647z"></path>
                  </svg>
                  <span className="text-sm text-gray-500">
                    Processing{processingPercentage ? ` (${processingPercentage}%)` : '...'}
                  </span>
                </div>
                <div className="invisible group-hover:visible absolute left-7 top-1/2 -translate-y-1/2 px-2 py-1 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
                  Your file is currently being processed
                </div>
              </div>
            );
          }
          
          if (questionnaire.status !== 'MAPPED') {
            return (
              <span className="text-sm text-gray-500 whitespace-nowrap">
                Pending
              </span>
            );
          }

          const isComplete = questionnaire.answeredQuestions === questionnaire.totalQuestions;

          return (
            <div className="flex items-center gap-3">
              <div className="flex-grow w-24 h-1.5 bg-gray-100 rounded-full overflow-hidden">
                <div 
                  className={`h-full rounded-full transition-all duration-300 ${
                    isComplete 
                      ? 'bg-[#1e8259]' 
                      : 'bg-gradient-to-r from-[#1e8259]/70 to-[#1e8259]'
                  }`}
                  style={{ width: `${(questionnaire.answeredQuestions / questionnaire.totalQuestions) * 100}%` }}
                />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-500 whitespace-nowrap">
                  {questionnaire.answeredQuestions} of {questionnaire.totalQuestions}
                </span>
                {isComplete && (
                  <CheckCircleIcon className="w-5 h-5 text-[#1e8259]" />
                )}
              </div>
            </div>
          );
        }
      }),
      columnHelper.accessor('productTags', {
        header: 'Tags',
        cell: (info) => (
          <div className="flex flex-row flex-wrap items-center gap-1.5">
            {info.getValue()?.slice(0, 2).map((tag, index) => (
              <div key={tag.id} className="flex items-center">
                <Tag tag={tag} />
                {index === 1 && info.getValue().length > 2 && (
                  <span className="ml-1.5 text-xs text-gray-500">
                    +{info.getValue().length - 2}
                  </span>
                )}
              </div>
            ))}
            {(!info.getValue() || info.getValue().length === 0) && (
              <span className="text-sm text-gray-500">-</span>
            )}
          </div>
        ),
      }),
      columnHelper.accessor('uploadedBy', {
        header: 'Uploaded By',
        cell: (info) => <span className="text-sm text-gray-500">{info.getValue()}</span>,
      }),
      columnHelper.accessor('modifiedDate', {
        header: 'Last Modified',
        cell: (info) => (
          <span className="text-sm text-gray-500">
            {formatDate(info.getValue())}
          </span>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: () => <div className="text-right"></div>,
        cell: ({ row }) => (
          <div className="text-right">
            <ActionDropdown
              onExport={() => handleExport(row.original)}
              onExportCsv={() => handleExportCsv(row.original)}
              onDelete={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete(row.original);
              }}
              isOpen={activeDropdownId === row.original.id}
              setIsOpen={(isOpen) => setActiveDropdownId(isOpen ? row.original.id : null)}
            />
          </div>
        ),
      }),
    ],
    []
  );

  // Add memoized filtered questionnaires (add this after the searchTerm state)
  const filteredQuestionnaires = useMemo(() => 
    questionnaires.filter(questionnaire => {
      const searchLower = searchTerm.toLowerCase();
      const matchesFileName = questionnaire.fileName.toLowerCase()
        .includes(searchLower);
      const matchesTags = questionnaire.productTags.some(tag => 
        tag.name.toLowerCase().includes(searchLower)
      );
      return matchesFileName || matchesTags;
    }),
    [questionnaires, searchTerm]
  );

  // Update the table initialization to use filtered data
  const table = useReactTable({
    data: filteredQuestionnaires, // Changed from questionnaires to filteredQuestionnaires
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return (
    <>
      {/* Adjust the search header alignment */}
      <div className="fixed top-0 left-64 right-0 bg-white z-30">
        <div className="px-6">
          <div className="w-full max-w-[1920px] mx-auto">
            <div className="flex h-16 items-center">
              <div className="flex flex-1 items-center">
                <div className="relative flex-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg 
                      className="h-4 w-4 text-gray-400" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={1.5} 
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="block w-full py-1.5 pl-10 pr-4 text-sm text-gray-900 
                      placeholder:text-gray-400 focus:outline-none focus:ring-0
                      bg-gray-50/50 focus:bg-white transition-colors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add spacing for fixed header */}
      <div className="h-16" />

      {/* Main Content */}
      <div className="w-full max-w-[1920px] mx-auto px-1 sm:px-3 lg:px-4">
        <div className="bg-white rounded-lg px-2 sm:px-3 lg:px-4">
          {/* Header Section - Further reduced spacing */}
          <div className="pt-1 pb-2">
            <div className="flex justify-between items-center mb-3">
              <div>
                <h1 className="text-xl font-medium text-gray-900">Questionnaire Overview</h1>
                <p className="mt-0.5 text-sm text-gray-500">
                  Manage and track your questionnaire progress
                </p>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-sm text-gray-500">
                  {usage?.current || 0} / {usage?.allowed || 0} questionnaires
                </span>
                <button
                  onClick={() => setIsImportModalOpen(true)}
                  disabled={isUploading}
                  className="ml-3 inline-flex items-center px-3 py-2 border border-transparent 
                    text-sm font-medium rounded-md shadow-sm text-white bg-[#1e8259] 
                    hover:bg-[#1e8259]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 
                    focus:ring-[#1e8259] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <svg 
                    className="w-4 h-4 mr-2 text-current"
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    strokeWidth={2}
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                    />
                  </svg>
                  Import Questionnaire
                </button>
              </div>
            </div>

            {/* Stats cards */}
            <div className="grid grid-cols-3 gap-6 mb-6">
              <div className="bg-white rounded-xl p-6 border border-gray-200 hover:border-gray-300 transition-colors">
                <div className="text-3xl font-bold text-gray-900">{filteredQuestionnaires.length}</div>
                <div className="mt-1 text-sm font-medium text-gray-500">Total Questionnaires</div>
              </div>
              
              <div className="bg-white rounded-xl p-6 border border-gray-200 hover:border-gray-300 transition-colors">
                <div className="text-3xl font-bold text-gray-900">
                  {filteredQuestionnaires.filter(q => q.progress < 100).length}
                </div>
                <div className="mt-1 text-sm font-medium text-gray-500">In Progress</div>
              </div>
              
              <div className="bg-white rounded-xl p-6 border border-gray-200 hover:border-gray-300 transition-colors">
                <div className="text-3xl font-bold text-gray-900">
                  {filteredQuestionnaires.filter(q => q.progress === 100).length}
                </div>
                <div className="mt-1 text-sm font-medium text-gray-500">Completed</div>
              </div>
            </div>
          </div>

          {/* Table section */}
          <div className="w-full rounded-lg overflow-visible">
            <table className="min-w-full divide-y divide-gray-200">
              <colgroup>
                <col className="w-[30%]" /><col className="w-[15%]" /><col className="w-[20%]" /><col className="w-[15%]" /><col className="w-[15%]" /><col className="w-[5%]" />
              </colgroup>
              <thead className="bg-gray-50">
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {!isLoading && (filteredQuestionnaires.length > 0 ? (
                  table.getRowModel().rows.map(row => (
                    <tr 
                      key={row.id} 
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={(e) => handleQuestionnaireClick(row.original, e)}
                    >
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className="px-6 py-4">
                          {cell.column.id === 'actions' ? (
                            <div className="text-right">
                              <ActionDropdown
                                onExport={() => handleExport(row.original)}
                                onExportCsv={() => handleExportCsv(row.original)}
                                onDelete={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleDelete(row.original);
                                }}
                                isOpen={activeDropdownId === row.original.id}
                                setIsOpen={(isOpen) => setActiveDropdownId(isOpen ? row.original.id : null)}
                              />
                            </div>
                          ) : (
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="px-6 py-24 text-center">
                      <div className="max-w-lg mx-auto">
                        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                          <CubeTransparentIcon className="h-6 w-6 text-gray-600" />
                        </div>
                        <h3 className="text-base font-medium text-gray-900 mb-8">Get Started with Questionnaires</h3>
                        
                        <div className="space-y-6">
                          {/* Step 1 */}
                          <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                              <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-600">1</span>
                              </div>
                            </div>
                            <div className="flex-1 text-left">
                              <p className="text-sm text-gray-900 font-medium">Build Knowledge Base</p>
                              <p className="text-sm text-gray-500">Import your completed questionnaires to train the AI</p>
                            </div>
                          </div>

                          {/* Step 2 */}
                          <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                              <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-600">2</span>
                              </div>
                            </div>
                            <div className="flex-1 text-left">
                              <p className="text-sm text-gray-900 font-medium">Import New Questionnaire</p>
                              <p className="text-sm text-gray-500">Upload the questionnaire you need help with</p>
                            </div>
                          </div>

                          {/* Step 3 */}
                          <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                              <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-600">3</span>
                              </div>
                            </div>
                            <div className="flex-1 text-left">
                              <p className="text-sm text-gray-900 font-medium">Review & Export</p>
                              <p className="text-sm text-gray-500">Verify AI-generated answers and download your completed questionnaire</p>
                            </div>
                          </div>
                        </div>

                        {/* Import Button */}
                        <div className="mt-8">
                          <button
                            onClick={() => {
                              if (isUploading) return;
                              const input = document.createElement('input');
                              input.type = 'file';
                              input.accept = '.xlsx,.docx';
                              input.onchange = (e) => {
                                const file = (e.target as HTMLInputElement).files?.[0];
                                if (file) handleFileUpload(file, { productTags: [] });
                              };
                              input.click();
                            }}
                            disabled={isUploading}
                            className="inline-flex items-center px-3 py-2 border border-gray-300 
                              text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            <svg 
                              className="w-4 h-4 mr-2 text-current"
                              xmlns="http://www.w3.org/2000/svg" 
                              fill="none" 
                              viewBox="0 0 24 24" 
                              strokeWidth={2}
                              stroke="currentColor"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                              />
                            </svg>
                            Import Questionnaire
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="px-6 py-3 flex items-center justify-between border-t border-gray-200">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="flex items-center gap-4">
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <span className="font-medium">{table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}</span>
                    {' '}-{' '}
                    <span className="font-medium">
                      {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, filteredQuestionnaires.length)}
                    </span>
                    {' '}of{' '}
                    <span className="font-medium">{filteredQuestionnaires.length}</span>
                    {' '}results
                  </p>
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value));
                    }}
                    className="text-sm border-gray-300 rounded-md focus:ring-[#1e8259] focus:border-[#1e8259]"
                  >
                    {[10, 20, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                    <button
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    >
                      Previous
                    </button>
                    
                    {/* Page Numbers */}
                    {Array.from(
                      { length: table.getPageCount() },
                      (_, index) => {
                        if (
                          index === 0 ||
                          index === table.getPageCount() - 1 ||
                          Math.abs(index - table.getState().pagination.pageIndex) <= 1
                        ) {
                          return (
                            <button
                              key={index}
                              onClick={() => table.setPageIndex(index)}
                              className={`relative inline-flex items-center px-2 py-2 border text-sm font-medium text-gray-500
                                ${index === table.getState().pagination.pageIndex
                                  ? 'z-10 bg-gray-50 border-gray-300 font-semibold'
                                  : 'bg-white border-gray-300 hover:bg-gray-50'
                                }`}
                            >
                              {index + 1}
                            </button>
                          );
                        }
                        if (Math.abs(index - table.getState().pagination.pageIndex) === 2) {
                          return (
                            <span
                              key={index}
                              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500"
                            >
                              ...
                            </span>
                          );
                        }
                        return null;
                      }
                    )}

                    <button
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImportModal
        isOpen={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        onImport={handleFileUpload}
        isUploading={isUploading}
      />
    </>
  );
};
