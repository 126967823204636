import { apiGatewayService } from './index';
import { AxiosResponse } from 'axios';
import { ProductTag } from './productTagService';

export interface KnowledgeBaseItem {
  id: string;
  organizationId: string;
  title: string;
  description: string;
  sourceName: string;
  createdAt: string;
  updatedAt: string;
  productTags: ProductTag[];
}

interface ApiResponse {
  data: KnowledgeBaseItem[];
  error?: string;
  success?: boolean;
  message?: string;
}

interface KBResponse {
  data: KnowledgeBaseItem;
  success?: boolean;
}

interface DeleteResponse {
  success: boolean;
  message: string;
  data: {
    deletedCount: number;
    deletedIds: string[];
  };
}

const getKnowledgeBaseItems = async (): Promise<KnowledgeBaseItem[]> => {
  try {
    const response: AxiosResponse<ApiResponse> = await apiGatewayService.request({
      method: 'GET',
      url: '/api/knowledge-base/items',
    });

    if (response.status >= 400) {
      throw new Error(response.data.error || 'Failed to fetch knowledge base items');
    }

    return response.data.data;
  } catch (error) {
    console.error('Error fetching knowledge base items:', error);
    throw error;
  }
};

const deleteKnowledgeBaseItem = async (ids: string | string[]): Promise<DeleteResponse> => {
  try {
    const response = await apiGatewayService.request<DeleteResponse>({
      method: 'POST',
      url: '/api/knowledge-base/delete',
      data: { ids }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting knowledge base items:', error);
    throw error;
  }
};

const addToKnowledgeBase = async (questionId: string): Promise<KnowledgeBaseItem> => {
  try {
    const response = await apiGatewayService.request<KBResponse>({
      method: 'POST',
      url: `/api/knowledge-base/questions/${questionId}`,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error adding to knowledge base:', error);
    throw error;
  }
};

const getPresignedKBUrl = async (fileName: string, contentType: string): Promise<{
  uploadUrl: string;
  questionnaireId: string;
}> => {
  try {
    const response = await apiGatewayService.request({
      method: 'POST',
      url: '/api/knowledge-base/upload',
      data: { fileName, contentType }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting presigned URL for KB import:', error);
    throw error;
  }
};

const getWorkbook = async (id: string) => {
  try {
    const response = await apiGatewayService.request({
      method: 'GET',
      url: `/api/knowledge-base/${id}/workbook`,
      timeout: 30000  // Increased to 60 seconds for very large Excel files
    });

    return response.data;
  } catch (error) {
    console.error('Failed to fetch workbook:', error);
    throw error;
  }
};

const confirmMapping = async (
  questionnaireId: string, 
  questions: { questionText: string; answerText: string }[],
  tags?: string[]
) => {
  try {
    const response = await apiGatewayService.request<{ data: KnowledgeBaseItem[] }>({
      method: 'POST',
      url: `/api/knowledge-base/${questionnaireId}/mapping`,
      data: { 
        mappedQuestions: questions.map(q => ({
          ...q,
          productTagIds: tags || []  // Ensure tags are sent with each question
        })),
      },
      timeout: 120000  // Increased to 120 seconds for large batches
    });
    return response.data.data;
  } catch (error) {
    console.error('Error confirming KB mapping:', error);
    throw error;
  }
};

const updateKnowledgeBaseItem = async (id: string, data: { title: string; description: string }): Promise<KnowledgeBaseItem> => {
  try {
    const response = await apiGatewayService.request<KBResponse>({
      method: 'PATCH',
      url: `/api/knowledge-base/${id}`,
      data
    });
    return response.data.data;
  } catch (error) {
    console.error('Error updating knowledge base item:', error);
    throw error;
  }
};

export const knowledgeBaseService = {
  getKnowledgeBaseItems,
  deleteKnowledgeBaseItem,
  addToKnowledgeBase,
  getPresignedKBUrl,
  getWorkbook,
  confirmMapping,
  updateKnowledgeBaseItem,
};