import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { productTagService } from '../../../services/productTagService';
import toast from 'react-hot-toast';
import { ImportConfig } from '../hooks/useKnowledgeBase';

export interface ImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImport: (files: File[], config: ImportConfig) => void;
  isUploading: boolean;
}

type ImportStep = 'type-selection' | 'file-upload' | 'tag-selection';

const ImportModal: React.FC<ImportModalProps> = ({ isOpen, onClose, onImport, isUploading }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState<ImportStep>('type-selection');
  const [selectedType, setSelectedType] = useState<'qa' | 'document' | null>(null);

  // Fetch available tags
  const { data: availableTags = [] } = useQuery({
    queryKey: ['productTags'],
    queryFn: productTagService.getTags,
    staleTime: 60000
  });

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setSelectedFiles([]);
      setSelectedTags([]);
      setCurrentStep('type-selection');
      setSelectedType(null);
    }
  }, [isOpen]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

    if (selectedType === 'qa') {
      // Handle Excel files for Q&A pairs
      const excelFiles = files.filter(file => file.name.toLowerCase().endsWith('.xlsx'));

      if (files.length !== excelFiles.length) {
        toast.error('Only Excel (.xlsx) files are allowed');
        return;
      }

      // Only allow single file for Q&A
      if (excelFiles.length > 1) {
        toast.error('Please select only one Excel file');
        return;
      }

      setSelectedFiles(excelFiles);
    } else {
      // Handle PDF files for documents
      const pdfFiles = files.filter(file => file.type === 'application/pdf');

      if (files.length !== pdfFiles.length) {
        toast.error('Only PDF files are allowed');
        return;
      }

      // Filter out files that are too large
      const validFiles = pdfFiles.filter(file => {
        if (file.size > MAX_FILE_SIZE) {
          toast.error(`${file.name} is too large. Maximum size is 10MB`);
          return false;
        }
        return true;
      });

      setSelectedFiles(validFiles);
    }
  };

  const handleTagToggle = (tagId: string) => {
    setSelectedTags(prev => (
      prev.includes(tagId) ? prev.filter(id => id !== tagId) : [...prev, tagId]
    ));
  };

  const handleImport = () => {
    if (selectedFiles.length > 0) {
      onImport(selectedFiles, { productTags: selectedTags });
    }
  };

  const renderFileUpload = () => (
    <div>
      <div className="text-center mb-6">
        <h3 className="text-lg font-medium text-gray-900">
          {selectedType === 'qa' ? 'Upload Q&A File' : 'Upload Documents'}
        </h3>
        <p className="mt-2 text-sm text-gray-500">
          {selectedType === 'qa'
            ? 'Upload an Excel file containing your Q&A pairs'
            : 'Upload one or more PDF documents to add to your knowledge base'}
        </p>
      </div>

      {selectedFiles.length > 0 ? (
        <div className="space-y-2">
          {selectedFiles.map((file, index) => (
            <div key={index} className="flex items-center justify-between p-4 border border-gray-200 rounded-lg">
              <div>
                <p className="text-sm font-medium text-gray-900">{file.name}</p>
                <p className="text-xs text-gray-500 mt-1">
                  {(file.size / 1024 / 1024).toFixed(2)} MB
                </p>
              </div>
              <button
                onClick={() => {
                  setSelectedFiles(prev => prev.filter((_, i) => i !== index));
                }}
                className="text-sm text-gray-500 hover:text-gray-700"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      ) : (
        <button
          onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.multiple = selectedType === 'document';
            input.accept = selectedType === 'qa' ? '.xlsx' : '.pdf';
            input.onchange = (e) => handleFileSelect(e as any);
            input.click();
          }}
          className="flex flex-col items-center justify-center w-full p-8 border border-dashed border-gray-200 rounded-lg hover:border-[#1e8259] transition-colors"
        >
          <p className="text-sm font-medium text-gray-900">Click to upload</p>
          <p className="text-xs text-gray-500 mt-2">
            {selectedType === 'qa' ? 'Supported format: .xlsx' : 'Supported format: PDF'}
          </p>
        </button>
      )}
    </div>
  );

  const renderStepIndicator = () => (
    <div className="flex items-center justify-center mb-6 mt-4">
      <div className="flex items-center space-x-3">
        {['Type', 'File', 'Tags'].map((stepName, index) => (
          <React.Fragment key={index}>
            <div
              className={`flex items-center justify-center w-6 h-6 rounded-full border ${
                currentStep === ['type-selection', 'file-upload', 'tag-selection'][index]
                  ? 'border-gray-900 text-gray-900'
                  : 'border-gray-300 text-gray-300'
              }`}
              title={stepName}
            >
              {index + 1}
            </div>
            {index < 2 && (
              <div
                className={`w-12 h-px ${
                  (index === 0 && currentStep !== 'type-selection') ||
                  (index === 1 && currentStep === 'tag-selection')
                    ? 'bg-gray-900'
                    : 'bg-gray-300'
                }`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  const renderTypeSelection = () => (
    <div className="space-y-6">
      <div className="text-center mb-6">
        <h3 className="text-lg font-medium text-gray-900">Select Import Type</h3>
        <p className="mt-2 text-sm text-gray-500">Choose the type of content you want to import</p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <button
          onClick={() => {
            setSelectedType('qa');
            setCurrentStep('file-upload');
          }}
          className={`flex flex-col items-center p-6 border rounded-lg transition-all ${
            selectedType === 'qa'
              ? 'border-[#1e8259] bg-[#1e8259]/5'
              : 'border-gray-200 hover:border-[#1e8259]'
          }`}
        >
          <h4 className="text-base font-medium text-gray-900">Q&A Pairs</h4>
          <p className="mt-2 text-sm text-gray-500">
            Import prefilled Excel questionnaires to start a knowledge base
          </p>
        </button>

        <button
          onClick={() => {
            setSelectedType('document');
            setCurrentStep('file-upload');
          }}
          className={`flex flex-col items-center p-6 border rounded-lg transition-all ${
            selectedType === 'document'
              ? 'border-[#1e8259] bg-[#1e8259]/5'
              : 'border-gray-200 hover:border-[#1e8259]'
          }`}
        >
          <h4 className="text-base font-medium text-gray-900">Document</h4>
          <p className="mt-2 text-sm text-gray-500">
            Turn PDF documents into intelligent knowledge assets
          </p>
        </button>
      </div>
    </div>
  );

  const renderTagSelection = () => (
    <div>
      <div className="text-center mb-6">
        <h3 className="text-lg font-medium text-gray-900">
          {selectedType === 'qa' ? 'Start Mapping Process' : 'Add Tags (Optional)'}
        </h3>
        <p className="mt-2 text-sm text-gray-500 max-w-md mx-auto">
          {selectedType === 'qa'
            ? "In the next step, you will map your Excel file's questions and answers to ensure accurate knowledge extraction. This helps maintain high-quality data in your knowledge base."
            : 'Select tags to limit AI responses to knowledge from specific products. Without tags selected, the AI will use knowledge from the entire knowledge base.'}
        </p>
      </div>

      {selectedType !== 'qa' && availableTags.length > 0 && (
        <div className="flex flex-wrap justify-center gap-2 max-w-lg mx-auto">
          {availableTags.map((tag) => (
            <button
              key={tag.id}
              onClick={() => handleTagToggle(tag.id)}
              className={`px-2.5 py-1.5 text-sm rounded-md border transition-all ${
                selectedTags.includes(tag.id)
                  ? 'border-[#1e8259] bg-[#1e8259]/5 text-[#1e8259]'
                  : 'border-gray-200 text-gray-600 hover:border-[#1e8259]'
              }`}
            >
              <span className="truncate max-w-[160px]">{tag.name}</span>
            </button>
          ))}
        </div>
      )}
      {selectedType !== 'qa' && availableTags.length === 0 && (
        <div className="flex items-center justify-center py-4">
          <p className="text-xs text-gray-500">No tags created yet.</p>
        </div>
      )}
    </div>
  );

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black/25 backdrop-blur-sm z-[9999] flex items-center justify-center overflow-hidden"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-xl mx-4">
        {/* Header */}
        <div className="px-6 py-6">
          {renderStepIndicator()}
        </div>

        {/* Content */}
        <div className="px-6 py-6 h-[280px] overflow-y-auto">
          {currentStep === 'type-selection' && renderTypeSelection()}
          {currentStep === 'file-upload' && renderFileUpload()}
          {currentStep === 'tag-selection' && renderTagSelection()}
        </div>

        {/* Footer */}
        <div className="px-8 py-6 flex justify-between">
          {currentStep !== 'type-selection' && (
            <button
              onClick={() => {
                if (currentStep === 'file-upload') {
                  // Reset everything when going back to step 1
                  setCurrentStep('type-selection');
                  setSelectedType(null);
                  setSelectedFiles([]);
                  setSelectedTags([]);
                } else if (currentStep === 'tag-selection') {
                  setCurrentStep('file-upload');
                }
              }}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
            >
              Back
            </button>
          )}
          {currentStep === 'type-selection' && <div />}
          <div>
            {currentStep === 'tag-selection' ? (
              <button
                onClick={handleImport}
                disabled={!selectedFiles.length || isUploading}
                className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-[#1e8259] border border-[#1e8259] hover:bg-[#1e8259] hover:text-white transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed min-w-[100px] justify-center"
              >
                {isUploading ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-current border-t-transparent mr-2.5"></div>
                    {selectedType === 'qa' ? 'Starting...' : 'Importing...'}
                  </>
                ) : (
                  selectedType === 'qa' ? 'Start Mapping' : 'Import'
                )}
              </button>
            ) : (
              <button
                onClick={() => {
                  if (currentStep === 'type-selection' && selectedType) {
                    setCurrentStep('file-upload');
                  } else if (currentStep === 'file-upload' && selectedFiles.length > 0) {
                    setCurrentStep('tag-selection');
                  }
                }}
                disabled={
                  (currentStep === 'type-selection' && !selectedType) ||
                  (currentStep === 'file-upload' && selectedFiles.length === 0)
                }
                className="px-4 py-2 text-sm font-medium text-[#1e8259] border border-[#1e8259] rounded-md hover:bg-[#1e8259] hover:text-white transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportModal;
