import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useTagManagement, useItemTags, Tag } from '../hooks/useKnowledgeBase';
import toast from 'react-hot-toast';

interface TagCellProps {
  tags: { id: string; name: string }[];
  itemId: string;
  activeTab: 'qa' | 'documents';
  onTagsChange?: (newTagIds: string[]) => Promise<void>;
  isBulkEdit?: boolean;
}

export const TagCell = React.memo(({ tags, itemId, activeTab, onTagsChange, isBulkEdit }: TagCellProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingTag, setEditingTag] = useState<{ id: string, name: string } | null>(null);
  const [newTagName, setNewTagName] = useState('');
  const [pendingTags, setPendingTags] = useState<Set<string>>(new Set());
  const cellRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [shouldOpenUpward, setShouldOpenUpward] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // Use the tag management hooks
  const { allTags, createTag, deleteTag, updateTag, isCreatingTag } = useTagManagement();
  const { updateTags, isUpdatingTags } = useItemTags(itemId, activeTab);

  // Determine dropdown direction
  useLayoutEffect(() => {
    if (isOpen && cellRef.current) {
      const cellRect = cellRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - cellRect.bottom;
      const spaceAbove = cellRect.top;
      const dropdownHeight = 350; // Approximate height of dropdown

      setShouldOpenUpward(spaceBelow < dropdownHeight && spaceAbove > spaceBelow);
    }
  }, [isOpen]);

  // Initialize pendingTags when dropdown opens
  useEffect(() => {
    if (isOpen) {
      setPendingTags(new Set(tags.map(t => t.id)));
    }
  }, [isOpen, tags]);

  // Handle click outside with save
  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (cellRef.current && !cellRef.current.contains(event.target as Node)) {
        handleSaveChanges();
        setIsOpen(false);
        setIsAddingTag(false);
        setNewTagName('');
        setIsEditMode(false);
        setEditingTag(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, pendingTags]);

  // Reset all states when dropdown closes
  useEffect(() => {
    if (!isOpen) {
      setIsAddingTag(false);
      setNewTagName('');
      setIsEditMode(false);
      setEditingTag(null);
    }
  }, [isOpen]);

  // Focus input when adding new tag
  useEffect(() => {
    if (isAddingTag && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingTag]);

  const handleCreateTag = async () => {
    const trimmedName = newTagName.trim();
    if (!trimmedName) return;

    // Check if tag already exists
    const tagExists = allTags.some(
      tag => tag.name.toLowerCase() === trimmedName.toLowerCase()
    );

    if (tagExists) {
      toast.error('Tag already exists', {
        duration: 3000
      });
      return;
    }

    createTag(trimmedName);
  };

  const handleTagToggle = (tagId: string) => {
    setPendingTags(prev => {
      const newSet = new Set(prev);
      if (newSet.has(tagId)) {
        newSet.delete(tagId);
      } else {
        newSet.add(tagId);
      }
      return newSet;
    });
  };

  const handleSaveChanges = async () => {
    const newTagIds = Array.from(pendingTags);
    const currentTagIds = tags.map(t => t.id).sort();
    const newTagIdsSorted = [...newTagIds].sort();

    // Only update if there are actual changes
    if (!arraysEqual(newTagIdsSorted, currentTagIds)) {
      if (onTagsChange) {
        await onTagsChange(newTagIds);
      } else {
        updateTags(newTagIds);
      }
    }
    setIsOpen(false);
  };

  // Helper function to compare arrays
  const arraysEqual = (a: string[], b: string[]) => {
    if (a.length !== b.length) return false;
    return a.every((val, index) => val === b[index]);
  };

  return (
    <div ref={cellRef} className="relative">
      <div 
        onClick={() => setIsOpen(!isOpen)} 
        className={`flex flex-col gap-1 cursor-pointer hover:bg-gray-50 p-1 rounded min-w-[100px] max-w-[200px] ${isBulkEdit ? 'text-sm text-gray-600 hover:text-gray-900' : ''}`}
      >
        {isBulkEdit ? (
          <span>Manage tags</span>
        ) : (
          <div className={`flex ${activeTab === 'qa' ? 'flex-col' : 'flex-row'} items-start gap-1 min-w-[100px] overflow-hidden`}>
            {tags.slice(0, activeTab === 'qa' ? 3 : 2).map((tag, index) => (
              <div key={tag.id} className="flex items-center">
                <TagBadge tag={tag} />
                {index === (activeTab === 'qa' ? 2 : 1) && tags.length > (activeTab === 'qa' ? 3 : 2) && (
                  <span className="text-xs text-gray-500">
                    +{tags.length - (activeTab === 'qa' ? 3 : 2)}
                  </span>
                )}
              </div>
            ))}
            {tags.length === 0 && (
              <span className="text-sm text-gray-500">-</span>
            )}
          </div>
        )}
      </div>
      
      {isOpen && (
        <div 
          ref={dropdownRef}
          className={`absolute right-0 w-72 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999] p-4
            ${shouldOpenUpward ? 'bottom-full mb-2' : 'top-full mt-2'}`}
        >
          <div className="flex justify-between items-center px-2 mb-3">
            <h3 className="text-sm font-medium text-gray-900">Manage Tags</h3>
          </div>

          {/* Tags list with edit mode */}
          <div className="space-y-0.5 max-h-60 overflow-y-auto">
            {allTags.length > 0 ? (
              allTags.map((tag) => (
                <div key={tag.id} className="relative flex items-center py-1.5 px-2 hover:bg-gray-50 rounded-md group">
                  {!isEditMode ? (
                    // Normal mode
                    <label className="flex items-center cursor-pointer flex-1 min-w-0">
                      <div className="flex-shrink-0">
                        <input
                          type="checkbox"
                          checked={pendingTags.has(tag.id)}
                          onChange={() => handleTagToggle(tag.id)}
                          className="h-3.5 w-3.5 rounded border-gray-300 text-[#1e8259] focus:ring-[#1e8259]"
                          disabled={isUpdatingTags}
                        />
                      </div>
                      <span className="ml-2 text-sm text-gray-600 group-hover:text-gray-900 truncate" title={tag.name}>{tag.name}</span>
                    </label>
                  ) : (
                    // Edit mode
                    <div className="flex items-center justify-between w-full min-w-0">
                      {editingTag?.id === tag.id ? (
                        <input
                          type="text"
                          value={editingTag.name}
                          onChange={(e) => setEditingTag({ ...editingTag, name: e.target.value })}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              updateTag({ id: tag.id, name: editingTag.name });
                              setEditingTag(null);
                            } else if (e.key === 'Escape') {
                              setEditingTag(null);
                            }
                          }}
                          onBlur={() => {
                            updateTag({ id: tag.id, name: editingTag.name });
                            setEditingTag(null);
                          }}
                          className="flex-1 text-sm border-0 bg-gray-50/50 rounded p-1 focus:outline-none min-w-0"
                          autoFocus
                        />
                      ) : (
                        <span className="text-sm text-gray-600 truncate flex-1 mr-4 min-w-0" title={tag.name}>{tag.name}</span>
                      )}
                      <div className="flex items-center gap-2 flex-shrink-0">
                        <>
                          <button
                            onClick={() => setEditingTag({ id: tag.id, name: tag.name })}
                            className="text-xs text-gray-500 hover:text-gray-900"
                          >
                            Edit
                          </button>
                          <span className="text-gray-300">|</span>
                          <button
                            onClick={() => deleteTag(tag.id)}
                            className="text-xs text-gray-500 hover:text-red-600"
                          >
                            Delete
                          </button>
                        </>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="py-8 text-center">
                <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                  <svg 
                    className="w-6 h-6 text-gray-600" 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    strokeWidth={1.5} 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z M6 6h.008v.008H6V6z" 
                    />
                  </svg>
                </div>
                <h3 className="text-sm font-medium text-gray-900 mb-1">No Tags Created</h3>
                <p className="text-xs text-gray-500 mb-4">
                  Create tags to organize your knowledge base 
                </p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsAddingTag(true);
                  }}
                  className="inline-flex items-center px-2.5 py-1.5 text-xs font-medium rounded-md
                    text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 hover:text-gray-900
                    hover:border-gray-400 transition-colors"
                >
                  Create First Tag
                </button>
              </div>
            )}
          </div>

          {/* Add new tag section */}
          <div className="mt-4 pt-4 border-t border-gray-100">
            {isAddingTag ? (
              <div className="flex items-center gap-2">
                <input
                  ref={inputRef}
                  type="text"
                  value={newTagName}
                  onChange={(e) => setNewTagName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && newTagName.trim()) {
                      e.preventDefault();
                      handleCreateTag();
                    } else if (e.key === 'Escape') {
                      setIsAddingTag(false);
                      setNewTagName('');
                    }
                  }}
                  placeholder="Enter tag name..."
                  className="flex-1 text-sm border-0 bg-gray-50/50 rounded p-1.5 placeholder:text-gray-400 focus:outline-none"
                  disabled={isCreatingTag}
                />
                <button
                  onClick={handleCreateTag}
                  disabled={!newTagName.trim() || isCreatingTag}
                  className="text-xs text-gray-600 hover:text-gray-900 disabled:text-gray-400 border border-gray-200 rounded px-2 py-1 hover:border-gray-300 inline-flex items-center gap-1.5"
                >
                  <svg 
                    className="w-3.5 h-3.5" 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    strokeWidth={2} 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                  </svg>
                  Add
                </button>
              </div>
            ) : (
              <div className="flex gap-2 text-xs pl-2">
                {!isEditMode && allTags.length > 0 && (
                  <>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsAddingTag(true);
                      }}
                      className="text-gray-500 hover:text-gray-900"
                    >
                      Add Tags
                    </button>
                    <span className="text-gray-300">|</span>
                  </>
                )}
                {allTags.length > 0 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditMode(!isEditMode);
                    }}
                    className="text-gray-500 hover:text-gray-900"
                  >
                    {isEditMode ? 'Done Editing' : 'Edit Tags'}
                  </button>
                )}
                {allTags.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsAddingTag(true);
                    }}
                    className="text-gray-500 hover:text-gray-900"
                  >
                    Add Tags
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

TagCell.displayName = 'TagCell';

const TagBadge: React.FC<{ tag: Tag }> = React.memo(({ tag }) => (
  <span
    className="inline-flex items-center px-1.5 py-0.5 text-xs text-gray-600 border border-gray-200 rounded w-fit mr-1 max-w-[120px]"
  >
    <span className="truncate" title={tag.name}>{tag.name}</span>
  </span>
));

TagBadge.displayName = 'TagBadge';

export default TagCell; 