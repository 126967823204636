import { ApiGatewayService } from './index';
import { RawAxiosRequestHeaders } from 'axios';

interface CsrfResponse {
  csrfToken: string;
}

interface TestResponse {
  message: string;
}

export class CsrfService {
  private tokenPromise: Promise<string> | null;
  private apiGateway: ApiGatewayService;
  private lastTokenRefresh: number = 0;
  private readonly TOKEN_REFRESH_INTERVAL = 20 * 60 * 1000; // 20 minutes, more reasonable interval

  constructor(apiGateway: ApiGatewayService) {
    this.tokenPromise = null;
    this.apiGateway = apiGateway;
  }

  async getCsrfToken(): Promise<string> {
    try {
      // Prevent multiple simultaneous token requests
      if (this.tokenPromise) {
        return await this.tokenPromise;
      }

      this.tokenPromise = (async () => {
        const response = await this.apiGateway.request<CsrfResponse>({
          method: 'GET',
          url: '/api/auth/csrf-token',
          withCredentials: true // Ensure credentials are sent
        });
        
        if (!response.data?.csrfToken) {
          throw new Error('No CSRF token received');
        }
        
        return response.data.csrfToken;
      })();
      
      const token = await this.tokenPromise;
      this.tokenPromise = null; // Clear promise after resolution
      return token;
    } catch (error) {
      console.warn('CSRF token fetch failed:', error);
      this.tokenPromise = null;
      return '';
    }
  }

  getStoredToken(): string {
    try {
      const token = document.cookie
        .split('; ')
        .find(row => row.startsWith('XSRF-TOKEN='))
        ?.split('=')[1];
      
      return token ? decodeURIComponent(token) : '';
    } catch (error) {
      console.warn('Error reading CSRF token from cookie:', error);
      return '';
    }
  }

  async ensureValidToken(): Promise<string> {
    const currentToken = this.getStoredToken();
    const now = Date.now();

    // Add debounce for rapid refreshes and check token existence
    if (currentToken && (now - this.lastTokenRefresh < 1000)) {
      return currentToken;
    }

    // Refresh if no token or token is old
    if (!currentToken || (now - this.lastTokenRefresh > this.TOKEN_REFRESH_INTERVAL)) {
      try {
        const newToken = await this.getCsrfToken();
        if (newToken) {
          this.lastTokenRefresh = now;
          return newToken;
        }
        // Fall back to current token if refresh fails but we have a valid one
        if (currentToken) {
          return currentToken;
        }
        throw new Error('Failed to get new CSRF token');
      } catch (error) {
        console.error('Failed to refresh CSRF token:', error);
        if (currentToken) {
          return currentToken;
        }
        throw error; // Throw error if we have no valid token
      }
    }
    return currentToken;
  }

  async addTokenToHeaders(headers: RawAxiosRequestHeaders = {}): Promise<RawAxiosRequestHeaders> {
    try {
      const token = await this.ensureValidToken();
      if (!token) {
        throw new Error('No valid CSRF token available');
      }
      return {
        ...headers,
        'X-XSRF-TOKEN': token
      };
    } catch (error) {
      console.error('Failed to add CSRF token to headers:', error);
      throw error; // Throw error instead of returning empty headers
    }
  }

  async testCsrf(): Promise<boolean> {
    try {
      await this.getCsrfToken();
      
      const headers = await this.addTokenToHeaders({});
      const response = await this.apiGateway.request<TestResponse>({
        method: 'POST',
        url: '/api/auth/test-csrf',
        headers
      });
      
      if (!response.data) {
        return false;
      }
      
      return response.data.message === 'CSRF test successful';
    } catch (error) {
      console.error('CSRF Test Failed:', error);
      throw error;
    }
  }
} 