import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CheckBadgeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { knowledgeBaseService } from '../services/knowledgebaseService';
import { Workbook } from '@fortune-sheet/react';
import '@fortune-sheet/react/dist/index.css';
import type { WorkbookInstance } from '@fortune-sheet/react';
import { useQuery } from '@tanstack/react-query';
import { productTagService } from '../services/productTagService';

interface LoadingOverlayProps {
  message?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ message = 'Processing...' }) => (
  <div className="absolute inset-0 bg-white flex items-center justify-center z-50">
    <div className="flex flex-col items-center space-y-4">
      <div className="animate-spin rounded-full h-9 w-9 border-[2.5px] border-gray-200 border-t-gray-600"></div>
      <div className="text-base text-gray-600 font-normal">{message}</div>
    </div>
  </div>
);

interface MappingData {
  data: string | string[];
  excelLocation: {
    type: 'question' | 'answer' | 'dropdown';
    cells: string;
    worksheetId: string;
  };
}

interface FormattedQuestion {
  questionText: string;
  answerText: string | null;
  dropdownOptions: string[] | null;
  excelLocation: {
    question?: { cells: string; worksheetId: string; type: string };
    answer?: { cells: string; worksheetId: string; type: string };
    dropdown?: { cells: string; worksheetId: string; type: string };
  };
  order: number;
}

interface ApiQuestion {
  questionText: string;
  answerText: string;
}

const transformToApiFormat = (questions: FormattedQuestion[]): ApiQuestion[] => {
  return questions.map(question => ({
    questionText: question.questionText,
    answerText: [
      question.dropdownOptions?.[0],
      question.answerText
    ].filter(Boolean).join(' | ')
  }));
};

const convertToExcelNotation = (row: number, col: number): string => {
  const colStr = String.fromCharCode(65 + col); // Convert 0-based column to A, B, C, etc.
  return `${colStr}${row + 1}`; // Convert 0-based row to 1-based
};

export const KBMapping: React.FC = () => {
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formattedQuestions, setFormattedQuestions] = useState<FormattedQuestion[]>([]);
  const navigate = useNavigate();
  const workbookRef = useRef<WorkbookInstance>(null);
  const [workbookData, setWorkbookData] = useState<any[] | null>(null);
  const [questionData, setQuestionData] = useState<MappingData[]>([]);
  const [answerData, setAnswerData] = useState<MappingData[]>([]);
  const [dropdownData, setDropdownData] = useState<MappingData[]>([]);
  const [mappedQuestions, setMappedQuestions] = useState<MappingData[]>([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isTagSelectionOpen, setIsTagSelectionOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  // Add query for tags
  const { data: availableTags = [] } = useQuery({
    queryKey: ['productTags'],
    queryFn: productTagService.getTags,
    staleTime: 60000
  });

  console.log("questions", questionData);
  console.log("answers", answerData);
  console.log("dropdowns", dropdownData);

  useEffect(() => {
    // Group mappings by type
    const questionMappings = questionData;
    const answerMappings = answerData;
    const dropdownMappings = dropdownData;

    console.group(`Current Mapped Fields (Total: ${questionMappings.length + answerMappings.length + dropdownMappings.length})`);
    
    if (questionMappings.length) {
      console.group(`Current mapped questions (${questionMappings.length}):`);
      questionMappings.forEach(m => console.log('Question:', m));
      console.groupEnd();
    }
    
    if (answerMappings.length) {
      console.group(`Current mapped answers (${answerMappings.length}):`);
      answerMappings.forEach(m => console.log('Answer:', m));
      console.groupEnd();
    }
    
    if (dropdownMappings.length) {
      console.group(`Current mapped dropdowns (${dropdownMappings.length}):`);
      dropdownMappings.forEach(m => console.log('Dropdown:', m));
      console.groupEnd();
    }

    console.groupEnd();
  }, [questionData, answerData, dropdownData]);

  useEffect(() => {
    const loadWorkbook = async () => {
      if (!questionnaireId) return;

      try {
        setIsLoading(true);
        const sheets = await knowledgeBaseService.getWorkbook(questionnaireId);
        if (Array.isArray(sheets)) {
          setWorkbookData(sheets);
        } else {
          throw new Error('Invalid workbook data format');
        }
      } catch (error) {
        console.error('Failed to load workbook:', error);
        toast.error('Failed to load workbook data');
      } finally {
        setIsLoading(false);
      }
    };

    loadWorkbook();
  }, [questionnaireId]);

  const getMergedCellInfo = (ref: React.RefObject<WorkbookInstance>, row: number, col: number) => {
    const sheet = ref.current?.getSheet();
    if (!sheet?.data) return null;

    const cell = sheet.data[row]?.[col];
    if (!cell) return null;

    if (cell.mc) {
      const mainCell = sheet.data[cell.mc.r]?.[cell.mc.c];
      if (!mainCell) return null;

      return {
        isMerged: true,
        mainCellRow: cell.mc.r,
        mainCellCol: cell.mc.c,
        value: mainCell.v,
        mergeRange: {
          rs: cell.mc.rs,
          cs: cell.mc.cs
        }
      };
    }

    return {
      isMerged: false,
      value: cell.v
    };
  };

  const processQuestionRow = (ref: React.RefObject<WorkbookInstance>, rowIndex: number, selection: any) => {
    const sheet = ref.current?.getSheet();
    if (!sheet || !sheet.data) {
      console.log('No sheet data available');
      return null;
    }

    const questionParts = [];
    const coordinates = [];

    try {
      const startCol = selection.column[0];
      const endCol = selection.column[1];

      for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
        const cellInfo = getMergedCellInfo(ref, rowIndex, colIndex);
        
        if (!cellInfo) continue;

        if (cellInfo.isMerged && cellInfo.mergeRange) {
          const value = cellInfo.value;
          if (value) {
            questionParts.push(value.toString().trim());
            
            // Use the main cell's coordinates for merged cells
            const actualRow = cellInfo.mainCellRow;
            const actualCol = cellInfo.mainCellCol;
            const numRepeats = cellInfo.mergeRange?.cs ?? 1;
            for (let i = 0; i < numRepeats; i++) {
              coordinates.push(`${actualRow},${actualCol + i}`);
            }
          }
          
          colIndex += (cellInfo.mergeRange?.cs ?? 1) - 1;
        } else {
          if (cellInfo.value) {
            questionParts.push(cellInfo.value.toString().trim());
            coordinates.push(`${rowIndex},${colIndex}`);
          }
        }
      }

      if (questionParts.length === 0) {
        return null;
      }

      return {
        data: questionParts.join(' | '),
        cells: coordinates.join(' | ')
      };

    } catch (error) {
      console.error('Error in processQuestionRow:', error);
      return null;
    }
  };

  const processAnswerRow = (ref: React.RefObject<WorkbookInstance>, rowIndex: number, selection: any) => {
    const sheet = ref.current?.getSheet();
    if (!sheet || !sheet.data) {
      console.log('No sheet data available');
      return null;
    }

    try {
      const startCol = selection.column[0];
      const cellInfo = getMergedCellInfo(ref, rowIndex, startCol);
      
      if (!cellInfo) return null;
      
      const value = cellInfo.value || "";
      if (!value || !value.toString().trim()) {
        return { 
          noValue: true,
          coordinates: convertToExcelNotation(rowIndex, startCol)
        };
      }

      let coordinates;
      if (cellInfo.isMerged && cellInfo.mergeRange) {
        // For merged cells, use the main cell's coordinates
        const actualRow = cellInfo.mainCellRow;
        const actualCol = cellInfo.mainCellCol;
        const numRepeats = cellInfo.mergeRange.cs ?? 1;
        const coordArray = [];
        
        for (let i = 0; i < numRepeats; i++) {
          coordArray.push(`${actualRow},${actualCol + i}`);
        }
        coordinates = coordArray.join(' | ');
      } else {
        coordinates = `${rowIndex},${startCol}`;
      }

      return {
        data: value.toString(),
        cells: coordinates
      };

    } catch (error) {
      console.error('Error in processAnswerRow:', error);
      return null;
    }
  };

  const processDropdownRow = (ref: React.RefObject<WorkbookInstance>, rowIndex: number, selection: any) => {
    const sheet = ref.current?.getSheet();
    if (!sheet || !sheet.data) {
      console.log('No sheet data available');
      return null;
    }

    try {
      const startCol = selection.column[0];
      const endCol = selection.column[1];
      console.log('Processing dropdown at row:', rowIndex, 'columns:', startCol, '-', endCol);

      let dropdownFound = false;

      for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
        const cellInfo = getMergedCellInfo(ref, rowIndex, colIndex);
        if (!cellInfo) continue;
        
        const actualRow = cellInfo.isMerged ? cellInfo.mainCellRow : rowIndex;
        const actualCol = cellInfo.isMerged ? cellInfo.mainCellCol : colIndex;

        const validationKey = `${actualRow}_${actualCol}`;
        const dataVerification = (sheet.config as any)?.dataVerification?.[validationKey] || 
                                (sheet as any)?.dataVerification?.[validationKey];

        if (dataVerification?.type === "dropdown") {
          dropdownFound = true;
          
          const cellValue = ref.current?.getCellValue(actualRow || 0, actualCol || 0);
          if (!cellValue || !cellValue.toString().trim()) {
            return { noValue: true };
          }
          
          let coordinates;
          if (cellInfo.isMerged && cellInfo.mergeRange) {
            const coordArray = [];
            const numRepeats = cellInfo.mergeRange.cs || 1;
            for (let i = 0; i < numRepeats; i++) {
              coordArray.push(`${actualRow},${actualCol + i}`);
            }
            coordinates = coordArray.join(' | ');
            
            colIndex += (numRepeats - 1);
          } else {
            coordinates = `${actualRow},${actualCol}`;
          }

          return {
            data: cellValue.toString(),
            cells: coordinates
          };
        }
      }

      if (!dropdownFound) {
        return { noDropdown: true };
      }

      return null;

    } catch (error) {
      console.error('Error in processDropdownRow:', error);
      return null;
    }
  };

  const handleMapping = (type: 'question' | 'answer' | 'dropdown') => {
    if (!workbookRef?.current) {
      toast.error('Spreadsheet is not ready');
      return;
    }

    const selection = workbookRef.current.getSelection();
    if (!selection || !selection[0]) {
      toast.error('Please select cells first');
      return;
    }

    let bg;
    let stateUpdater;
    let currentState;
    let processor;

    switch (type) {
      case 'question':
        bg = '#FFBFBF';
        stateUpdater = setQuestionData;
        currentState = questionData;
        processor = processQuestionRow;
        break;
      case 'answer':
        bg = '#D3ECE2';
        stateUpdater = setAnswerData;
        currentState = answerData;
        processor = processAnswerRow;
        break;
      case 'dropdown':
        bg = '#FFECDD';
        stateUpdater = setDropdownData;
        currentState = dropdownData;
        processor = processDropdownRow;
        break;
      default:
        return;
    }

    const getSelectedCells = (selections: any[]): Set<string> => {
      const currentWorksheetId = (workbookRef.current?.getSheet()?.order ?? 0).toString();
      const cells = new Set<string>();
      
      // Process each selection range
      selections.forEach(selection => {
        for (let row = selection.row[0]; row <= selection.row[1]; row++) {
          for (let col = selection.column[0]; col <= selection.column[1]; col++) {
            const cellInfo = getMergedCellInfo(workbookRef, row, col);
            if (cellInfo) {
              if (cellInfo.isMerged && cellInfo.mergeRange) {
                // For merged cells, add all cells in the merge range starting from the main cell
                const actualRow = cellInfo.mainCellRow;
                const actualCol = cellInfo.mainCellCol;
                const numRepeats = cellInfo.mergeRange.cs ?? 1;
                
                for (let i = 0; i < numRepeats; i++) {
                  cells.add(`${currentWorksheetId}:${actualRow},${actualCol + i}`);
                }
                // Skip the remaining columns in this merged cell
                col += (cellInfo.mergeRange.cs ?? 1) - 1;
              } else {
                cells.add(`${currentWorksheetId}:${row},${col}`);
              }
            }
          }
        }
      });
      
      return cells;
    };

    const selectedCells = getSelectedCells(selection);

    const getMappedCells = (item: MappingData) => {
      // Split by | to handle multiple cell coordinates
      const cells = new Set<string>();
      const cellCoords = item.excelLocation.cells.split(" | ");
      
      cellCoords.forEach(coord => {
        const [row, col] = coord.trim().split(",").map(Number);
        const cellInfo = getMergedCellInfo(workbookRef, row, col);
        
        if (cellInfo && cellInfo.isMerged && cellInfo.mergeRange) {
          // For merged cells, add all cells in the merge range
          const actualRow = cellInfo.mainCellRow;
          const actualCol = cellInfo.mainCellCol;
          const numRepeats = cellInfo.mergeRange.cs ?? 1;
          
          for (let i = 0; i < numRepeats; i++) {
            cells.add(`${item.excelLocation.worksheetId}:${actualRow},${actualCol + i}`);
          }
        } else {
          cells.add(`${item.excelLocation.worksheetId}:${coord.trim()}`);
        }
      });
      
      return cells;
    };

    const isOtherTypeMapped = (selectedCells: Set<string>) => {
      const checkOverlap = (dataArray: MappingData[]) => {
        return dataArray.some(item => {
          const mappedCells = getMappedCells(item);
          return Array.from(selectedCells).some(cell => mappedCells.has(cell));
        });
      };

      if (type !== 'question' && checkOverlap(questionData)) return true;
      if (type !== 'answer' && checkOverlap(answerData)) return true;
      if (type !== 'dropdown' && checkOverlap(dropdownData)) return true;

      return false;
    };

    if (isOtherTypeMapped(selectedCells)) {
      toast.error('Some cells are already mapped as a different type. Please unmap them first.');
      return;
    }

    const isUnmapping = currentState.some(item => {
      const mappedCells = getMappedCells(item);
      return Array.from(selectedCells).some(cell => mappedCells.has(cell));
    });

    if (isUnmapping) {
      const currentWorksheetId = (workbookRef.current?.getSheet()?.order ?? 0).toString();

      // Reset background colors for selected cells
      Array.from(selectedCells).forEach(cellCoord => {
        const [_, coords] = cellCoord.split(':');
        const [row, col] = coords.split(',').map(Number);
        let originalBg = "#FFFFFF";
        if (workbookData && workbookData[Number(currentWorksheetId)]?.celldata) {
          const cell = workbookData[Number(currentWorksheetId)].celldata.find((c: any) => c.r === row && c.c === col);
          if (cell?.v?.bg) {
            originalBg = cell.v.bg;
          }
        }
        workbookRef.current?.setCellFormat(row, col, "bg", originalBg);
      });

      // Update states
      stateUpdater(prevData => 
        prevData.filter(item => {
          const mappedCells = getMappedCells(item);
          return !Array.from(selectedCells).some(cell => mappedCells.has(cell));
        })
      );

      // Clear formattedQuestions when unmapping
      setFormattedQuestions([]);
      setIsModalOpen(false);

      // Update mappedQuestions state
      setMappedQuestions(prev => 
        prev.filter(item => {
          const mappedCells = getMappedCells(item);
          return !Array.from(selectedCells).some(cell => mappedCells.has(cell));
        })
      );

      return;
    }

    const newData: MappingData[] = [];
    const processedRows = new Set();

    if (type === 'dropdown') {
      let hasAnyDropdown = false;
      
      // Process each selection range
      selection.forEach(selectionRange => {
        for (let rowIndex = selectionRange.row[0]; rowIndex <= selectionRange.row[1]; rowIndex++) {
          if (processedRows.has(rowIndex)) continue;

          const processedData = processDropdownRow(workbookRef, rowIndex, selectionRange);
          if (processedData) {
            if (processedData.noDropdown) {
              // Silently skip cells without dropdowns
              continue;
            } else if (processedData.noValue) {
              // Skip dropdown cells without a value
              continue;
            } else {
              hasAnyDropdown = true;
              newData.push({
                data: processedData.data,
                excelLocation: {
                  type: 'dropdown',
                  cells: processedData.cells || '',
                  worksheetId: (workbookRef.current?.getSheet()?.order ?? 0).toString()
                }
              });
              processedRows.add(rowIndex);
            }
          }
        }
      });

      // Only show error if no valid dropdowns found
      if (!hasAnyDropdown) {
        toast.error('No dropdown menu found in selected cells');
        return;
      }
    } else if (type === 'answer') {
      // Process each selection range
      selection.forEach(selectionRange => {
        for (let rowIndex = selectionRange.row[0]; rowIndex <= selectionRange.row[1]; rowIndex++) {
          if (processedRows.has(rowIndex)) continue;

          const processedData = processAnswerRow(workbookRef, rowIndex, selectionRange);
          if (processedData) {
            if (!processedData.noValue) {  // Only add if there's a value
              newData.push({
                data: processedData.data || '',
                excelLocation: {
                  type: 'answer',
                  cells: processedData.cells || '',
                  worksheetId: (workbookRef.current?.getSheet()?.order ?? 0).toString()
                }
              });
              processedRows.add(rowIndex);
            }
            // Silently skip cells with no value (removed error message)
          }
        }
      });
    } else {
      // Process each selection range
      selection.forEach(selectionRange => {
        for (let rowIndex = selectionRange.row[0]; rowIndex <= selectionRange.row[1]; rowIndex++) {
          if (processedRows.has(rowIndex)) continue;

          const processedData = processor(workbookRef, rowIndex, selectionRange);
          if (processedData) {
            newData.push({
              data: processedData.data,
              excelLocation: {
                type: type,
                cells: processedData.cells || '',
                worksheetId: (workbookRef.current?.getSheet()?.order ?? 0).toString()
              }
            });
            processedRows.add(rowIndex);
          }
        }
      });
    }

    if (newData.length > 0) {
      // Instead of coloring all selected cells, only color cells with data
      newData.forEach(mapping => {
        const cells = mapping.excelLocation.cells.split(" | ");
        cells.forEach(cellCoord => {
          const [row, col] = cellCoord.split(",").map(Number);
          workbookRef.current?.setCellFormat(row, col, "bg", bg);
        });
      });
      
      setMappedQuestions(prev => [...prev, ...newData]);
      
      // Convert MappedField to MappingData
      const newMappingData = newData;
      
      // Update the corresponding state based on type
      switch (type) {
        case 'question':
          setQuestionData(prev => [...prev, ...newMappingData]);
          break;
        case 'answer':
          setAnswerData(prev => [...prev, ...newMappingData]);
          break;
        case 'dropdown':
          setDropdownData(prev => [...prev, ...newMappingData]);
          break;
      }
      
      
    } else {
      toast.error(`Cannot map empty cells as ${type}`);
    }
  };

  const prepareQuestions = () => {
    if (!questionnaireId) {
      toast.error('Questionnaire ID is required');
      return;
    }

    if (mappedQuestions.length === 0) {
      toast.error("Please include at least one question before proceeding.");
      return;
    }

    // Check if number of questions exceeds limit
    if (questionData.length > 400) {
      toast.error("Maximum of 400 questions can be imported at once. Please reduce the number of mapped questions.");
      return;
    }

    // Group mappings by worksheet and row for validation and formatting
    const questionsByWorksheetAndRow: Record<string, MappingData[]> = {};
    
    // First, group questions by their worksheet and row
    questionData.forEach(question => {
      // For merged cells, we need to handle all rows that are part of the merge
      const cells = question.excelLocation.cells.split(" | ");
      cells.forEach(cell => {
        const [row] = cell.split(",");
        const worksheetId = question.excelLocation.worksheetId;
        const key = `${worksheetId}:${row}`;
        if (!questionsByWorksheetAndRow[key]) {
          questionsByWorksheetAndRow[key] = [];
        }
        // Only add if not already present
        if (!questionsByWorksheetAndRow[key].some(q => q.excelLocation.cells === question.excelLocation.cells)) {
          questionsByWorksheetAndRow[key].push(question);
        }
      });
    });

    // Now add corresponding answers and dropdowns
    Object.keys(questionsByWorksheetAndRow).forEach(key => {
      const [worksheetId, row] = key.split(":");
      
      // Find matching answers
      answerData.forEach(answer => {
        if (answer.excelLocation.worksheetId === worksheetId) {
          const answerCells = answer.excelLocation.cells.split(" | ");
          if (answerCells.some(cell => cell.split(",")[0] === row)) {
            questionsByWorksheetAndRow[key].push(answer);
          }
        }
      });

      // Find matching dropdowns
      dropdownData.forEach(dropdown => {
        if (dropdown.excelLocation.worksheetId === worksheetId) {
          const dropdownCells = dropdown.excelLocation.cells.split(" | ");
          if (dropdownCells.some(cell => cell.split(",")[0] === row)) {
            questionsByWorksheetAndRow[key].push(dropdown);
          }
        }
      });
    });

    // Filter out entries without answers or dropdowns
    const validQuestionGroups = Object.values(questionsByWorksheetAndRow).filter(group => {
      return group.some(item => item.excelLocation.type === 'question') &&
             (group.some(item => item.excelLocation.type === 'answer') ||
              group.some(item => item.excelLocation.type === 'dropdown'));
    });

    if (validQuestionGroups.length === 0) {
      toast.error("Please map at least one valid question with an answer or dropdown field.");
      return;
    }

    // Format the questions
    const formattedQuestions = validQuestionGroups.map((group, index) => {
      let questionText = '';
      let answerText: string | null = null;
      let dropdownOptions: string[] | null = null;
      const excelLocation: any = {};

      group.forEach(item => {
        switch (item.excelLocation.type) {
          case 'question':
            questionText = item.data.toString();
            excelLocation.question = item.excelLocation;
            break;
          case 'answer':
            answerText = item.data.toString();
            excelLocation.answer = item.excelLocation;
            break;
          case 'dropdown':
            dropdownOptions = Array.isArray(item.data) ? item.data : [item.data.toString()];
            excelLocation.dropdown = item.excelLocation;
            break;
        }
      });

      return {
        questionText,
        answerText,
        dropdownOptions,
        excelLocation,
        order: index + 1
      };
    });

    setFormattedQuestions(formattedQuestions);
    setIsModalOpen(true);
  };

  const handleTagToggle = (tagId: string) => {
    setSelectedTags(prev => {
      const newTags = prev.includes(tagId)
        ? prev.filter(id => id !== tagId)
        : [...prev, tagId];
      return newTags;
    });
  };

  const handleConfirmMapping = async () => {
    if (!questionnaireId) return;

    try {
      setIsConfirming(true);
      setIsLoading(true);
      const apiFormattedQuestions = transformToApiFormat(formattedQuestions);
      
      // Pass selected tags to the API and await the response
      const response = await knowledgeBaseService.confirmMapping(
        questionnaireId, 
        apiFormattedQuestions, 
        selectedTags
      );
      
      if (response && response.length > 0) {
        toast.success(`Successfully added ${response.length} items to knowledge base`);
        navigate('/knowledge-base');
      } else {
        throw new Error('No items were created');
      }
    } catch (error: any) {
      console.error('Error saving questions:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        toast.error(error.response.data.error || 'Failed to save knowledge base entries');
      } else {
        toast.error(error.message || 'Failed to save knowledge base entries');
      }
    } finally {
      setIsConfirming(false);
      setIsLoading(false);
      setIsModalOpen(false);
      setIsTagSelectionOpen(false);
    }
  };

  const handleUnmapQuestion = (question: FormattedQuestion) => {
    // Get all cells that need to be unmapped
    const allCells = new Set<string>();
    const worksheetId = question.excelLocation.question?.worksheetId || '';

    // Add question cells
    if (question.excelLocation.question?.cells) {
      question.excelLocation.question.cells.split(" | ").forEach(cell => 
        allCells.add(`${worksheetId}:${cell}`)
      );
    }

    // Add answer cells
    if (question.excelLocation.answer?.cells) {
      question.excelLocation.answer.cells.split(" | ").forEach(cell => 
        allCells.add(`${worksheetId}:${cell}`)
      );
    }

    // Add dropdown cells
    if (question.excelLocation.dropdown?.cells) {
      question.excelLocation.dropdown.cells.split(" | ").forEach(cell => 
        allCells.add(`${worksheetId}:${cell}`)
      );
    }

    const targetWorksheetId = question.excelLocation.question?.worksheetId || '';
    const targetSheetIndex = Number(targetWorksheetId);

    const activeSheet = workbookRef.current?.getSheet();
    const currentActiveSheetIndex = activeSheet?.order || 0;

    // Switch to target sheet if not already active
    if (currentActiveSheetIndex !== targetSheetIndex) {
      workbookRef.current?.activateSheet({ index: targetSheetIndex });
    }

    Array.from(allCells).forEach(cellCoord => {
      const [_, coords] = cellCoord.split(':');
      const [row, col] = coords.split(',').map(Number);
      let originalBg = "#FFFFFF";
      if (workbookData && workbookData[targetSheetIndex]?.celldata) {
        const cell = workbookData[targetSheetIndex].celldata.find((c: any) => c.r === row && c.c === col);
        if (cell?.v?.bg) {
          originalBg = cell.v.bg;
        }
      }
      workbookRef.current?.setCellFormat(row, col, "bg", originalBg);
    });

    // Update states to remove the unmapped question
    setQuestionData(prev => prev.filter(q => !(
      q.excelLocation.cells === question.excelLocation.question?.cells &&
      q.excelLocation.worksheetId === question.excelLocation.question?.worksheetId
    )));
    
    setAnswerData(prev => prev.filter(a => !(
      a.excelLocation.cells === question.excelLocation.answer?.cells &&
      a.excelLocation.worksheetId === question.excelLocation.answer?.worksheetId
    )));
    
    setDropdownData(prev => prev.filter(d => !(
      d.excelLocation.cells === question.excelLocation.dropdown?.cells &&
      d.excelLocation.worksheetId === question.excelLocation.dropdown?.worksheetId
    )));

    // Update mappedQuestions state to remove all related mappings
    setMappedQuestions(prev => prev.filter(mapping => {
      if (
        mapping.excelLocation.worksheetId === question.excelLocation.question?.worksheetId &&
        (
          question.excelLocation.question?.cells === mapping.excelLocation.cells ||
          question.excelLocation.answer?.cells === mapping.excelLocation.cells ||
          question.excelLocation.dropdown?.cells === mapping.excelLocation.cells
        )
      ) return false;
      return true;
    }));

    // Update formatted questions
    setFormattedQuestions(prev => {
      const updatedQuestions = prev.filter(q => !(
        q.excelLocation.question?.cells === question.excelLocation.question?.cells &&
        q.excelLocation.question?.worksheetId === question.excelLocation.question?.worksheetId
      ));
      if (updatedQuestions.length === 0) {
        setIsModalOpen(false);
      }
      return updatedQuestions;
    });
  };

  const mappingFields = [
    {
      id: 'question',
      label: 'Question Text',
      description: 'Select cells containing questions',
      bgHover: 'hover:border-red-500 hover:bg-red-50 active:bg-red-100',
      border: 'border-red-200'
    },
    {
      id: 'answer',
      label: 'Text Answer',
      description: 'Select cells for answers',
      bgHover: 'hover:border-green-500 hover:bg-green-50 active:bg-green-100',
      border: 'border-green-200'
    },
    {
      id: 'dropdown',
      label: 'Dropdown Answer',
      description: 'Select cells containing dropdown options',
      bgHover: 'hover:border-yellow-500 hover:bg-yellow-50 active:bg-yellow-100',
      border: 'border-yellow-200'
    }
  ];

  return (
    <div className="bg-white h-screen flex flex-col relative">
      {isLoading && <LoadingOverlay />}
      
      <div className="w-full max-w-7xl mx-auto">
        <div className="flex-shrink-0 p-4 border-b">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">Knowledge Base Mapping</h1>
              <p className="mt-1 text-sm text-gray-500">
                Select cells or rows in the spreadsheet, then choose a category to assign it.
              </p>
            </div>
            <button
              onClick={prepareQuestions}
              className="inline-flex items-center px-6 py-2.5 rounded-lg text-sm font-medium 
                bg-[#1e8259] hover:bg-[#1e8259]/90 text-white shadow-sm hover:shadow-md 
                active:scale-95 transition-all duration-200 min-w-[140px] justify-center"
            >
              Review Mapping
            </button>
          </div>
        </div>

        <div className="flex-shrink-0 space-y-4 p-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="grid grid-cols-4 gap-4">
              {mappingFields.map((field) => (
                <button
                  key={field.id}
                  onClick={() => handleMapping(field.id as 'question' | 'answer' | 'dropdown')}
                  className={`p-4 rounded-lg border ${field.border}
                    ${field.bgHover}
                    transform transition-all duration-150 ease-in-out
                    bg-white text-left focus:outline-none relative`}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">
                        {field.label}
                      </h3>
                      <p className="text-xs text-gray-500 mt-1">{field.description}</p>
                    </div>
                    {(field.id === 'question' || field.id === 'answer' || field.id === 'dropdown') && (
                      <span className="ml-2 px-2 py-0.5 bg-gray-100 text-gray-600 text-xs font-medium rounded-full">
                        {field.id === 'question' && questionData.length}
                        {field.id === 'answer' && answerData.length}
                        {field.id === 'dropdown' && dropdownData.length}
                      </span>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Review Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999] p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-6xl w-full max-h-[80vh] flex flex-col relative">
            <div className="p-6">
              <h3 className="text-xl font-semibold text-gray-900">
                {isTagSelectionOpen ? 'Add Tags (Optional)' : 'Review Mapped Questions'}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {isTagSelectionOpen 
                  ? 'Select tags to categorize your knowledge base entries. Tags help organize and filter content effectively.'
                  : 'Please review the mapped questions and their answers before proceeding. Once you confirm, the Q&A pairs will be added to your knowledge base.'}
              </p>
            </div>
            
            <div className="flex-1 overflow-auto p-6">
              {isTagSelectionOpen ? (
                <div className="flex flex-col items-center">
                  {availableTags.length > 0 ? (
                    <div className="flex flex-wrap justify-center gap-2 max-w-lg">
                      {availableTags.map((tag) => (
                        <button
                          key={tag.id}
                          onClick={() => handleTagToggle(tag.id)}
                          className={`px-2.5 py-1.5 text-sm rounded-md border transition-all
                            ${selectedTags.includes(tag.id)
                              ? 'border-[#1e8259] bg-[#1e8259]/5 text-[#1e8259]'
                              : 'border-gray-200 text-gray-600 hover:border-[#1e8259]'}`}
                        >
                          <span className="truncate max-w-[160px]">{tag.name}</span>
                        </button>
                      ))}
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <p className="text-xs text-gray-500">No tags created yet.</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="space-y-4">
                  {formattedQuestions.map((q, index) => (
                    <div key={index} className="p-4 bg-gray-50 rounded-lg">
                      <div className="flex items-start gap-8">
                        <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                          <span className="text-sm text-gray-600 font-medium">{index + 1}</span>
                        </div>
                        <div className="flex-grow space-y-3 relative min-w-0">
                          <div className="flex items-start">
                            <div className="flex items-start gap-2 min-w-0 pr-12">
                              <span className="text-xs text-gray-500 pt-1 w-[80px] shrink-0">Question:</span>
                              <span className="text-sm text-gray-900 break-words min-w-0 flex-1">{q.questionText}</span>
                            </div>
                            <button
                              onClick={() => handleUnmapQuestion(q)}
                              className="p-1 hover:bg-gray-200 rounded-full transition-colors absolute right-0 top-0"
                              title="Unmap question"
                            >
                              <XMarkIcon className="w-5 h-5 text-gray-500 hover:text-gray-700" />
                            </button>
                          </div>
                          
                          <div className="space-y-2">
                            {(q.answerText || q.dropdownOptions) && (
                              <div className="flex items-start gap-2 min-w-0 pr-12">
                                <span className="text-xs text-gray-500 pt-1 w-[80px] shrink-0">Answer:</span>
                                <span className="text-sm text-gray-900 break-words min-w-0 flex-1">
                                  {[
                                    q.dropdownOptions ? q.dropdownOptions[0] : null,
                                    q.excelLocation.answer ? q.answerText : null
                                  ].filter(Boolean).join(' | ')}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            <div className="p-6 flex justify-end gap-3">
              <button
                onClick={() => {
                  if (isTagSelectionOpen) {
                    setIsTagSelectionOpen(false);
                  } else {
                    setIsModalOpen(false);
                  }
                }}
                className="px-6 py-2.5 rounded-lg text-sm font-medium text-gray-700 hover:text-gray-900"
                disabled={isConfirming}
              >
                {isTagSelectionOpen ? 'Back' : 'Cancel'}
              </button>
              <button
                onClick={() => {
                  if (isTagSelectionOpen) {
                    handleConfirmMapping();
                  } else {
                    setIsTagSelectionOpen(true);
                  }
                }}
                disabled={isConfirming}
                className={`inline-flex items-center px-6 py-2.5 rounded-lg text-sm font-medium text-white 
                  bg-[#1e8259] hover:bg-[#1e8259]/90 active:scale-95 transition-all duration-200 
                  min-w-[140px] justify-center ${isConfirming ? 'opacity-75 cursor-not-allowed' : ''}`}
              >
                {isConfirming ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"></div>
                    Adding to Knowledge Base...
                  </>
                ) : isTagSelectionOpen ? (
                  <>
                    <CheckBadgeIcon className="w-4 h-4 mr-2 stroke-[2]" />
                    Confirm & Add to Knowledge Base
                  </>
                ) : (
                  'Continue to Tag Selection'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex-grow overflow-hidden w-full max-w-8xl mx-auto px-10">
        {workbookData ? (
          <Workbook 
            ref={workbookRef}
            data={workbookData}
            showToolbar={false}
            showFormulaBar={false}
            cellContextMenu={[]}
            sheetTabContextMenu={[]}
          />
        ) : (
          <div className="h-full flex items-center justify-center text-gray-500">
            Loading workbook...
          </div>
        )}
      </div>
    </div>
  );
};
  