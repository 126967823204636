import { ApiGatewayService } from './index';
import { CsrfService } from './CsrfService';
import { AuthSession, User } from '../types/authentication';

// Public routes that don't need session checks
const PUBLIC_ROUTES = [
  '/login',
  '/signup',
  '/forgot-password',
  '/reset-password',
  '/verify-email',
  '/set-password',
  '/confirm-email',
  '/mfa-challenge',
  '/force-password-change',
  '/new-password'
] as const;

interface LoginResponse {
  user: User;
  error?: string;
  pendingApproval?: boolean;
  session?: string;
  deviceCredentials?: {
    deviceKey: string;
    deviceGroupKey: string;
    devicePassword: string;
  };
}

interface AuthResult {
  session?: AuthSession;
  error?: string;
  pendingApproval?: boolean;
  challengeName?: string;
  cognitoSession?: string;
}

export class AuthService {
  private sessionCheckInterval: NodeJS.Timeout | null = null;
  private refreshTokenTimeout: NodeJS.Timeout | null = null;
  private signupEmail: string | null = null;
  private readonly DEVICE_CREDENTIALS_KEY = 'deviceCredentials';
  private cachedSession: AuthSession | null = null;
  private lastSessionCheck: number = 0;
  private readonly SESSION_CACHE_DURATION = 15 * 60 * 1000; // 15 minutes cache
  private readonly TOKEN_REFRESH_INTERVAL = 45 * 60 * 1000; // 45 minutes
  private readonly TOKEN_REFRESH_RETRY_DELAY = 30 * 1000;  // 30 seconds retry delay

  constructor(
    private apiGateway: ApiGatewayService,
    private csrfService: CsrfService
  ) {
    this.initializeSessionCheck();
    this.scheduleTokenRefresh();
    this.setupVisibilityChangeHandler();
  }

  private setupVisibilityChangeHandler() {
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          // Only refresh if we're close to token expiration
          const lastRefresh = localStorage.getItem('lastTokenRefresh');
          const now = Date.now();
          
          if (lastRefresh && (now - parseInt(lastRefresh) > this.TOKEN_REFRESH_INTERVAL - 5 * 60 * 1000)) {
            // Only refresh if we're within 5 minutes of expiration
            this.refreshToken().then(refreshed => {
              if (refreshed) {
                localStorage.setItem('lastTokenRefresh', now.toString());
                this.scheduleTokenRefresh();
              }
            }).catch(console.error);
          }
        }
      });
    }
  }

  private getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()!.split(';').shift() || null;
    return null;
  }

  private getAccessTokenExpiry(): number | null {
    const token = this.getCookie('token');
    if (!token) return null;
    try {
      // JWT: header.payload.signature; decode payload which is base64 encoded
      const payloadBase64 = token.split('.')[1];
      const payloadJson = atob(payloadBase64);
      const payload = JSON.parse(payloadJson);
      if (payload.exp) {
        return payload.exp * 1000; // convert seconds to ms
      }
      return null;
    } catch (error) {
      console.error('[AuthService] Error decoding token:', error);
      return null;
    }
  }

  private scheduleTokenRefresh(): void {
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
    }

    // Only schedule if running in browser
    if (typeof window !== 'undefined') {
      const expiry = this.getAccessTokenExpiry();
      if (expiry) {
        const now = Date.now();
        // Refresh 30 seconds before the token expires
        let delay = expiry - now - 30000;
        // If the delay is negative, refresh immediately
        if (delay < 0) {
          delay = 0;
        }
        this.refreshTokenTimeout = setTimeout(async () => {
          try {
            const refreshed = await this.refreshToken();
            if (!refreshed) {
              this.clearIntervals();
              window.location.href = '/login';
              return;
            }
            // Reschedule next refresh
            this.scheduleTokenRefresh();
          } catch (error) {
            console.error('[AuthService] Token refresh error during scheduled refresh:', error);
            // Retry after a short delay if error occurs
            setTimeout(() => this.scheduleTokenRefresh(), this.TOKEN_REFRESH_RETRY_DELAY);
          }
        }, delay);
        console.log(`[AuthService] Scheduled token refresh in ${delay} ms`);
      } else {
        // If no token expiry available, fallback to a default interval
        this.refreshTokenTimeout = setTimeout(async () => {
          try {
            const refreshed = await this.refreshToken();
            if (!refreshed) {
              this.clearIntervals();
              window.location.href = '/login';
              return;
            }
            this.scheduleTokenRefresh();
          } catch (error) {
            console.error('[AuthService] Token refresh error in fallback scheduling:', error);
            setTimeout(() => this.scheduleTokenRefresh(), this.TOKEN_REFRESH_RETRY_DELAY);
          }
        }, this.TOKEN_REFRESH_INTERVAL);
        console.log(`[AuthService] No token expiry found, using default refresh interval of ${this.TOKEN_REFRESH_INTERVAL} ms`);
      }
    }
  }

  public clearIntervals() {
    if (this.sessionCheckInterval) {
      clearInterval(this.sessionCheckInterval);
      this.sessionCheckInterval = null;
    }
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
      this.refreshTokenTimeout = null;
    }
  }

  private async refreshToken(): Promise<boolean> {
    try {
      // Skip refresh on public routes
      const currentPath = window.location.pathname;
      const isPublicRoute = PUBLIC_ROUTES.some(route => currentPath.split('?')[0].startsWith(route));
      if (isPublicRoute) {
        return true;
      }

      const headers = await this.csrfService.addTokenToHeaders();
      
      // Get current session to get email
      const session = await this.getCurrentSession();
      if (!session?.user?.email) {
        console.error('No active session found for token refresh');
        return false;
      }

      const email = session.user.email;
      const deviceCredentialsKey = `${this.DEVICE_CREDENTIALS_KEY}_${email}`;
      const deviceCredentialsStr = localStorage.getItem(deviceCredentialsKey);
      const deviceCredentials = deviceCredentialsStr ? JSON.parse(deviceCredentialsStr) : null;

      const response = await this.apiGateway.request<{
        message: string;
        accessToken: string;
        idToken: string;
        deviceCredentials?: {
          deviceKey: string;
          deviceGroupKey: string;
          devicePassword: string;
        };
      }>({
        method: 'POST',
        url: '/api/auth/refresh-token',
        headers,
        data: {
          email,
          deviceCredentials
        }
      });

      // If we got new device credentials, store them
      if (response.data?.deviceCredentials) {
        this.storeDeviceCredentials(email, response.data.deviceCredentials);
      }

      if (response.status === 200 && response.data?.accessToken) {
        localStorage.setItem('lastTokenRefresh', Date.now().toString());
        return true;
      }

      return false;
    } catch (error: any) {
      console.error('Token refresh error:', error);
      
      // Handle various error cases
      if (error.response?.status === 401) {
        const errorMessage = error.response?.data?.error;
        
        // Handle JWT expiration specifically
        if (errorMessage === 'jwt expired') {
          try {
            // Clear any cached session data
            this.clearSessionCache();
            
            // Wait before retrying
            await new Promise(resolve => setTimeout(resolve, this.TOKEN_REFRESH_RETRY_DELAY));
            
            // Retry the refresh
            return this.refreshToken();
          } catch (retryError) {
            console.error('Token refresh retry failed:', retryError);
          }
        }
        
        // Handle clock sync issues
        if (error.response?.data?.expiredAt) {
          const expiredAt = new Date(error.response.data.expiredAt);
          const now = new Date();
          
          // If expiration is in the future, there might be a clock sync issue
          if (expiredAt > now) {
            console.warn('Possible clock synchronization issue detected', {
              serverExpiredAt: expiredAt,
              clientNow: now,
              timeDiff: expiredAt.getTime() - now.getTime()
            });
            
            // Force an immediate refresh attempt
            await new Promise(resolve => setTimeout(resolve, 1000));
            return this.refreshToken();
          }
        }
      }
      
      if (error.response?.data?.requiresLogin) {
        window.location.href = '/login';
      }
      return false;
    }
  }

  private initializeSessionCheck() {
    // Clear any existing interval
    if (this.sessionCheckInterval) {
      clearInterval(this.sessionCheckInterval);
    }

    // Only start session checks if we're in a browser environment
    if (typeof window !== 'undefined') {
      this.sessionCheckInterval = setInterval(async () => {
        // Skip check if on a public route
        const currentPath = window.location.pathname;
        const isPublicRoute = PUBLIC_ROUTES.some(route => currentPath.split('?')[0].startsWith(route));
        if (isPublicRoute) {
          return;
        }

        try {
          // Clear cache to force a fresh check
          this.clearSessionCache();
          const response = await this.apiGateway.request<{ user: User }>({
            method: 'GET',
            url: '/api/auth/session'
          });

          if (!response.data?.user) {
            throw new Error('Invalid session response');
          }
        } catch (error: any) {
          console.error('Session check error:', error);
          if (error.response?.status === 401 || error.response?.status === 403) {
            const refreshed = await this.refreshToken();
            if (!refreshed) {
              this.clearIntervals();
              window.location.href = '/login';
            }
          }
        }
      }, 15 * 60 * 1000); // Changed from 5 minutes to 15 minutes
    }
  }

  async getCurrentSession(): Promise<AuthSession | null> {
    // Skip session check on public routes
    const currentPath = window.location.pathname;
    const isPublicRoute = PUBLIC_ROUTES.some(route => currentPath.split('?')[0].startsWith(route));
    if (isPublicRoute) {
      return null;
    }

    const now = Date.now();
    // Return cached session if it's still valid
    if (this.cachedSession && (now - this.lastSessionCheck < this.SESSION_CACHE_DURATION)) {
      return this.cachedSession;
    }
    
    try {
      const response = await this.apiGateway.request<{ 
        user: User;
        subscription?: {
          status: string;
          trialDaysRemaining?: number | null;
        };
      }>({
        method: 'GET',
        url: '/api/auth/session'
      });

      if (!response.data?.user) {
        this.cachedSession = null;
        return null;
      }

      this.cachedSession = {
        user: response.data.user,
        subscription: response.data.subscription
      };
      this.lastSessionCheck = now;

      return this.cachedSession;
    } catch (error) {
      console.error('Error getting current session:', error);
      this.cachedSession = null;
      return null;
    }
  }

  private getStoredDeviceCredentials(email: string) {
    const key = `${this.DEVICE_CREDENTIALS_KEY}_${email}`;
    const stored = localStorage.getItem(key);
    console.log('[AUTH] Retrieving device credentials:', {
      email,
      key,
      hasStoredValue: !!stored,
      storedValue: stored
    });
    
    if (!stored) return null;
    try {
      const credentials = JSON.parse(stored);
      console.log('[AUTH] Successfully parsed device credentials:', {
        deviceKey: credentials?.deviceKey,
        hasDeviceGroupKey: !!credentials?.deviceGroupKey,
        hasDevicePassword: !!credentials?.devicePassword
      });
      return credentials;
    } catch (error) {
      console.error('[AUTH] Error parsing device credentials:', error);
      localStorage.removeItem(key);
      return null;
    }
  }

  private storeDeviceCredentials(email: string, credentials: LoginResponse['deviceCredentials']) {
    if (credentials) {
      const key = `${this.DEVICE_CREDENTIALS_KEY}_${email}`;
      console.log('[AUTH] Storing device credentials:', {
        email,
        key,
        deviceKey: credentials.deviceKey,
        hasDeviceGroupKey: !!credentials.deviceGroupKey,
        hasDevicePassword: !!credentials.devicePassword
      });
      localStorage.setItem(key, JSON.stringify(credentials));
      
      // Verify storage was successful
      const stored = localStorage.getItem(key);
      console.log('[AUTH] Verified stored credentials:', {
        wasStored: !!stored,
        storedValue: stored
      });
    }
  }

  async signIn(
    email: string, 
    password: string, 
    rememberDevice: boolean,
    newPassword?: string,
    userInfo?: { firstName: string; lastName: string },
    mfaCode?: string,
    cognitoSession?: string | null
  ): Promise<AuthResult> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();
      const deviceCredentials = this.getStoredDeviceCredentials(email);
      console.log('[AUTH] Sign in attempt:', {
        email,
        rememberDevice,
        hasDeviceCredentials: !!deviceCredentials,
        deviceKey: deviceCredentials?.deviceKey
      });

      const response = await this.apiGateway.request<LoginResponse>({
        method: 'POST',
        url: '/api/auth/login',
        headers,
        data: { 
          email, 
          password, 
          newPassword,
          mfaCode,
          session: cognitoSession,
          rememberDevice,
          deviceCredentials,
          ...(userInfo && {
            givenName: userInfo.firstName,
            familyName: userInfo.lastName
          })
        }
      });

      // Store device credentials if they're returned
      if (response.data.deviceCredentials) {
        console.log('[AUTH] Received device credentials from backend:', {
          email,
          deviceKey: response.data.deviceCredentials.deviceKey,
          hasDeviceGroupKey: !!response.data.deviceCredentials.deviceGroupKey,
          hasDevicePassword: !!response.data.deviceCredentials.devicePassword
        });
        this.storeDeviceCredentials(email, response.data.deviceCredentials);
      } else {
        console.log('[AUTH] No device credentials received from backend');
      }

      // Handle NEW_PASSWORD_REQUIRED challenge
      if (response.data.error === 'NEW_PASSWORD_REQUIRED') {
        return {
          error: 'NEW_PASSWORD_REQUIRED'
        };
      }

      // Handle MFA challenge
      if (response.data.error === 'MFA_CODE_REQUIRED') {
        return {
          error: 'MFA_CODE_REQUIRED',
          cognitoSession: response.data.session
        };
      }

      // Handle MFA verification error
      if (response.data.error === 'Invalid MFA code. Please try again.' || response.data.error === 'MFA verification failed') {
        return {
          error: response.data.error,
          cognitoSession: response.data.session
        };
      }

      // Handle pending approval
      if (response.data.error && response.data.pendingApproval) {
        return {
          error: response.data.error,
          pendingApproval: true
        };
      }

      if (response.data.error) {
        return { error: response.data.error };
      }

      if (!response.data?.user) {
        return { error: 'Invalid login response' };
      }

      return {
        session: {
          user: {
            email: response.data.user.email,
            givenName: response.data.user.givenName,
            familyName: response.data.user.familyName,
            organizationId: response.data.user.organizationId,
            role: response.data.user.role
          }
        }
      };
    } catch (error: any) {
      console.error('Login error:', error);
      throw error;
    }
  }

  async signOut(): Promise<void> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();
      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/signout',
        headers
      });
      
      // Clear session cache
      this.clearSessionCache();
      
      // Instead of clearing all storage, only clear non-device related items
      const deviceCredentialsKeys = Object.keys(localStorage)
        .filter(key => key.startsWith(this.DEVICE_CREDENTIALS_KEY));
      
      // Store device credentials temporarily
      const storedDeviceCredentials = deviceCredentialsKeys.reduce((acc, key) => {
        acc[key] = localStorage.getItem(key);
        return acc;
      }, {} as { [key: string]: string | null });
      
      // Clear localStorage
      localStorage.clear();
      
      // Restore device credentials
      Object.entries(storedDeviceCredentials).forEach(([key, value]) => {
        if (value) {
          localStorage.setItem(key, value);
        }
      });
      
      // Clear all intervals
      this.clearIntervals();
    } catch (error) {
      console.error('Signout error:', error);
      throw error;
    }
  }

  async signUp(email: string, password: string, userData: {
    givenName: string;
    familyName: string;
    organizationName: string;
  }): Promise<void> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/signup',
        headers,
        data: {
          email,
          password,
          ...userData
        }
      });

      // Redirect to verification page with email parameter
      window.location.href = `/verify-email?email=${encodeURIComponent(email)}`;
    } catch (error: any) {
      console.error('Signup error:', error);
      throw error;
    }
  }

  async verifyEmail(email: string, code: string): Promise<void> {
    if (!email || !code) {
      throw new Error('Email and verification code are required');
    }

    try {
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/verify-email',
        headers,
        data: { 
          email: email.trim(),
          code: code.trim()
        }
      });
    } catch (error: any) {
      if (error.response?.status === 429) {
        throw new Error('Too many verification attempts. Please try again later.');
      }
      if (error.response?.status === 400) {
        throw new Error('Invalid verification code. Please check and try again.');
      }
      console.error('Email verification error:', error);
      throw error;
    }
  }

  async resendVerificationCode(email: string): Promise<void> {
    if (!email) {
      throw new Error('Email is required');
    }

    try {
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/resend-code',
        headers,
        data: { email: email.trim() }
      });
    } catch (error: any) {
      if (error.response?.status === 429) {
        throw new Error('Please wait before requesting another code.');
      }
      console.error('Resend verification code error:', error);
      throw error;
    }
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/forgot-password',
        headers,
        data: { email }
      });
    } catch (error: any) {
      console.error('Forgot password error:', error);
      throw error;
    }
  }

  async confirmPasswordReset(email: string, code: string, newPassword: string): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/confirm-password-reset',
        headers,
        data: { 
          email,
          code,
          newPassword
        }
      });
    } catch (error: any) {
      console.error('Password reset error:', error);
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw new Error('Failed to reset password. Please try again.');
    }
  }

  async updateUserAttributes(attributes: { [key: string]: string }): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      // Convert firstName/lastName to given_name/family_name if present
      const mappedAttributes = {
        given_name: attributes.givenName || attributes.firstName || '',
        family_name: attributes.familyName || attributes.lastName || ''
      };

      await this.apiGateway.request({
        method: 'PUT',
        url: '/api/users/profile',
        headers,
        data: mappedAttributes,
        withCredentials: true  // Ensure credentials are sent
      });
    } catch (error: any) {
      console.error('Update attributes error:', error);
      if (error.response?.status === 401) {
        throw new Error('Session expired. Please log in again.');
      }
      throw error;
    }
  }

  async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    try {
      // Get CSRF headers for the request
      const headers = await this.csrfService.addTokenToHeaders();

      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/change-password',
        headers,
        data: {
          currentPassword,
          newPassword
        }
      });
    } catch (error: any) {
      console.error('Change password error:', error);
      throw error;
    }
  }

  getSignupEmail(): string | null {
    return this.signupEmail;
  }

  async setupMFA(): Promise<{ secretCode: string; qrCodeUrl: string }> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();
      const response = await this.apiGateway.request<{ secretCode: string; qrCodeUrl: string }>({
        method: 'POST',
        url: '/api/auth/mfa/setup',
        headers
      });

      if (!response.data) {
        throw new Error('Invalid MFA setup response');
      }

      return response.data;
    } catch (error: any) {
      console.error('MFA setup error:', error);
      throw error;
    }
  }

  async verifyAndEnableMFA(code: string): Promise<void> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();
      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/mfa/verify',
        headers,
        data: { code }
      });
    } catch (error: any) {
      console.error('MFA verification error:', error);
      throw error;
    }
  }

  async disableMFA(code: string): Promise<void> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();
      await this.apiGateway.request({
        method: 'POST',
        url: '/api/auth/mfa/disable',
        headers,
        data: { code }
      });
    } catch (error: any) {
      console.error('MFA disable error:', error);
      throw error;
    }
  }

  async getMFAStatus(): Promise<{ enabled: boolean }> {
    try {
      const headers = await this.csrfService.addTokenToHeaders();
      const response = await this.apiGateway.request<{ enabled: boolean }>({
        method: 'GET',
        url: '/api/auth/mfa/status',
        headers
      });

      return response.data;
    } catch (error: any) {
      console.error('MFA status check error:', error);
      throw error;
    }
  }

  // Add method to clear session cache
  clearSessionCache() {
    this.cachedSession = null;
    this.lastSessionCheck = 0;
  }
} 