import React, { useEffect } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

export interface DocumentActionDropdownProps {
  onDownload: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const DocumentActionDropdown: React.FC<DocumentActionDropdownProps> = ({ onDownload, isOpen, setIsOpen }) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && !(event.target as Element).closest('.dropdown-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen, setIsOpen]);

  return (
    <div className="relative inline-block text-left dropdown-container">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="p-2 rounded-lg hover:bg-gray-50 transition-colors"
      >
        <svg className="h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="py-1">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDownload();
                setIsOpen(false);
              }}
              className="group flex w-full px-4 py-3 text-sm items-center hover:bg-gray-50 text-gray-700"
            >
              <ArrowDownTrayIcon className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-500 shrink-0" aria-hidden="true" />
              <div className="flex-1 text-left">
                <p className="font-medium text-sm leading-5">Download File</p>
                <p className="text-xs text-gray-500">Download the unmodified document.</p>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentActionDropdown; 