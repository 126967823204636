// This file manages all the hooks for the QuestionnaireEditing.jsx page

import { useMutation, useQueryClient, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { questionService } from '../services/questionService';
import type { Question as ServiceQuestion } from '../services/questionService';
import { knowledgeBaseService } from '../services/knowledgebaseService';
import { aiService } from '../services/aiService';
import toast from 'react-hot-toast';
import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export interface RelevantSnippet {
  id: string;
  title: string;
  content: string;
  relevanceScore: number;
  source_name: string;
  created_at: string;
  source_type: string;
  page_number?: number;
}

interface QuestionsResponse {
  questions: Question[];
  nextCursor?: string;
}

interface AIQueryResult {
  relevance_score: number;
  title: string;
  content: string;
  source_name: string;
  source_type: string;
  created_at: string;
  page_number?: number;
}

interface Question extends ServiceQuestion {
  id: string;
  questionText: string;
  answerText?: string;
  dropdownValue?: string;
  isApproved: boolean;
  isMarked: boolean;
  order: number;
  organizationId: string;
  additionalInfo?: string;
  dropdownOptions?: string[];
  excelLocation?: {
    answer?: {
      type: string;
      cells: string;
      worksheetId: string;
    };
    dropdown?: {
      type: string;
      cells: string;
      worksheetId: string;
    };
    question?: {
      type: string;
      cells: string;
      worksheetId: string;
    };
  };
  aiQueryResults?: {
    [kb_item_id: string]: AIQueryResult;
  };
}

export function useQuestionnaire(questionnaireId: string | undefined) {
  const location = useLocation();
  const forceRefresh = location.state?.forceRefresh;
  const queryClient = useQueryClient();
  const [currentQuestionId, setCurrentQuestionId] = useState<string>('');
  const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<string>('open');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questionsInKB, setQuestionsInKB] = useState<Set<string>>(new Set());
  const [relevantSnippetsByQuestion, setRelevantSnippetsByQuestion] = useState<{
    [questionId: string]: RelevantSnippet[];
  }>({});
  const [textAnswers, setTextAnswers] = useState<Record<string, string>>({});
  const [regeneratingQuestions, setRegeneratingQuestions] = useState<Set<string>>(new Set());
  const [submittingQuestions, setSubmittingQuestions] = useState<Set<string>>(new Set());

  // Main query
  const {
    data: questionsData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<QuestionsResponse>({
    queryKey: ['questions', questionnaireId],
    queryFn: async ({ pageParam }) => 
      questionService.getQuestions(questionnaireId!, pageParam as string | undefined) as Promise<QuestionsResponse>,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    enabled: !!questionnaireId,
    staleTime: 30000,
    refetchOnWindowFocus: false,
    initialPageParam: undefined,
    getPreviousPageParam: () => undefined // Prevent backwards pagination
  });

  // Initial counts from API
  const { data: initialCounts, refetch: refetchCounts } = useQuery({
    queryKey: ['questionCounts', questionnaireId],
    queryFn: () => questionService.getQuestionCounts(questionnaireId!),
    enabled: !!questionnaireId,
    staleTime: Infinity // Only fetch once
  });

  // Refetch data if forceRefresh is true
  useEffect(() => {
    if (forceRefresh) {
      refetch();
      refetchCounts(); // Also refetch the counts
    }
  }, [forceRefresh, refetch, refetchCounts]);

  // Combine all pages of questions
  const questions = questionsData?.pages.flatMap(page => page.questions) ?? [];
  // Get questionnaire info from the counts response
  const questionnaire = initialCounts?.questionnaire;

  // Track count changes locally
  const [countDeltas, setCountDeltas] = useState({
    open: 0,
    completed: 0,
    marked: 0
  });

  // Update values when questions data changes
  useEffect(() => {
    if (questions.length > 0) {
      // Set initial question ID if not set
      if (!currentQuestionId) {
        const firstQuestion = questions[0];
        setCurrentQuestionId(firstQuestion.id);
      }
      
      // Initialize text answers only for questions that don't have an answer yet
      setTextAnswers(prev => {
        const updates: Record<string, string> = {};
        let hasUpdates = false;
        
        questions.forEach(question => {
          if (prev[question.id] === undefined && question.answerText !== undefined) {
            updates[question.id] = question.answerText;
            hasUpdates = true;
          }
        });
        
        return hasUpdates ? { ...prev, ...updates } : prev;
      });
    }
  }, [questions, currentQuestionId]); // Add currentQuestionId as dependency

  const filterOptions = [
    { 
      id: 'all', 
      label: 'All Questions', 
      count: initialCounts?.total ?? 0
    },
    { 
      id: 'open', 
      label: 'Open', 
      count: (initialCounts?.open ?? 0) + countDeltas.open
    },
    { 
      id: 'completed', 
      label: 'Completed', 
      count: (initialCounts?.completed ?? 0) + countDeltas.completed
    },
    { 
      id: 'marked', 
      label: 'Marked for Later', 
      count: (initialCounts?.marked ?? 0) + countDeltas.marked
    },
  ];

  // Your existing mutations
  const updateQuestion = useMutation({
    mutationFn: ({ questionId, data }: { questionId: string; data: Partial<Question> }) =>
      questionService.updateQuestion(questionnaireId!, questionId, data),
    onSuccess: (_, { questionId, data }) => {
      // Update the questions in the cache
      queryClient.setQueryData(['questions', questionnaireId], (oldData: any) => {
        if (!oldData) return oldData;
        
        const updatedData = {
          ...oldData,
          pages: oldData.pages.map((page: any) => ({
            ...page,
            questions: page.questions.map((q: Question) => 
              q.id === questionId ? { ...q, ...data } : q
            )
          }))
        };
        return updatedData;
      });
      
      // Update local counts based on the action
      const question = questions.find(q => q.id === questionId);
      if (question) {
        setCountDeltas(prev => {
          const deltas = { ...prev };
          
          // Handle approval status changes
          if (data.isApproved !== undefined) {
            if (data.isApproved) {
              // When approving
              if (!question.isApproved) {  // Only if not already approved
                deltas.completed += 1;
                // Only decrease open counter if it was in open state (not marked)
                if (!question.isMarked) {
                  deltas.open -= 1;
                }
              }
            } else {
              // When disapproving
              if (question.isApproved) {  // Only if was previously approved
                deltas.completed -= 1;
                // Only increase open counter if it's not marked
                if (!question.isMarked) {
                  deltas.open += 1;
                }
              }
            }
          }
          
          // Handle mark status changes
          if (data.isMarked !== undefined) {
            const wasMarked = question.isMarked;
            const willBeMarked = data.isMarked;
            
            if (!wasMarked && willBeMarked) {
              // When marking
              deltas.marked += 1;
              // Only decrease open if it was in open state (not approved)
              if (!question.isApproved) {
                deltas.open -= 1;
              }
            } else if (wasMarked && !willBeMarked) {
              // When unmarking
              deltas.marked -= 1;
              // Only increase open if it's not approved
              if (!question.isApproved) {
                deltas.open += 1;
              }
            }
          }
          
          return deltas;
        });
      }
    },
    onError: (error) => {
      console.error('Failed to update question:', error);
      toast.error('Failed to update question');
    },
  });

  // Helper functions
  const getFilteredQuestions = useCallback(() => {
    let filteredQuestions = questions;
    
    switch (activeFilter) {
      case 'completed':
        filteredQuestions = questions.filter(q => q.isApproved);
        break;
      case 'open':
        filteredQuestions = questions.filter(q => !q.isApproved && !q.isMarked);
        break;
      case 'marked':
        filteredQuestions = questions.filter(q => q.isMarked);
        break;
    }
    
    return filteredQuestions.sort((a, b) => a.order - b.order);
  }, [questions, activeFilter]);

  // Handlers
  const handleDropdownChange = async (value: string) => {
    if (!currentQuestionId || !questionnaireId) return;
    
    try {
      await updateQuestion.mutateAsync({
        questionId: currentQuestionId,
        data: { dropdownValue: value }
      });
      
      // Optionally show success toast
      
    } catch (error) {
      console.error('Error updating dropdown value:', error);
      toast.error('Failed to update answer');
    }
  };

  const handleMarkForLater = async (questionId: string) => {
    const question = questions.find(q => q.id === questionId);
    if (!question) return;
    
    try {
      await updateQuestion.mutateAsync({
        questionId,
        data: { isMarked: !question.isMarked }
      });
    } catch (error) {
      console.error('Error marking question:', error);
      toast.error('Failed to mark question');
    }
  };

  const handleSubmit = async (e: React.FormEvent, questionId: string) => {
    e.preventDefault();
    setSubmittingQuestions(prev => new Set(prev).add(questionId));
    try {
      await updateQuestion.mutateAsync({
        questionId,
        data: {
          isApproved: true
        }
      });
    } finally {
      setSubmittingQuestions(prev => {
        const next = new Set(prev);
        next.delete(questionId);
        return next;
      });
    }
  };

  const handleReopen = async (questionId: string) => {
    setIsSubmitting(true);
    try {
      await updateQuestion.mutateAsync({
        questionId,
        data: {
          isApproved: false
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Knowledge Base handlers
  const handleAddToKB = async (e: React.MouseEvent, questionId: string) => {
    e.stopPropagation();
    try {
      // Find the question data
      const question = questions.find(q => q.id === questionId);
      if (!question) {
        toast.error('Question not found');
        return;
      }

      // First, add to knowledge base
      const kbItem = await knowledgeBaseService.addToKnowledgeBase(questionId);
      console.log('KB Response:', kbItem); // Debug log

      if (kbItem && kbItem.id) {
        try {
          console.log('Attempting AI ingestion with KB ID:', kbItem.id);
          const aiResponse = await aiService.ingestQuestion({
            id: kbItem.id,
            title: question.questionText || '',
            description: question.answerText || '',
            source_name: questionnaire?.fileName || 'Unknown Source'
          });

          console.log('AI Ingestion Response:', aiResponse);

          if (aiResponse.ingested_count > 0) {
            setQuestionsInKB(prev => new Set(prev).add(questionId));
            toast.success('Added to Knowledge Base');
          } else {
            console.warn('AI ingestion returned 0 count');
            throw new Error('AI ingestion failed - no items ingested');
          }
        } catch (aiError) {
          console.error('AI ingestion failed:', aiError);
          toast.success('Added to Knowledge Base');
          toast.error('AI system update failed - will retry later');
        }
      } else {
        console.error('KB Response invalid:', kbItem);
        throw new Error('Invalid KB response');
      }
    } catch (error) {
      console.error('Error managing knowledge base:', error);
      toast.error('Failed to add to Knowledge Base');
      setQuestionsInKB(prev => {
        const next = new Set(prev);
        next.delete(questionId);
        return next;
      });
    }
  };

  const handleRemoveSnippet = async (e: React.MouseEvent, snippetId: string) => {
    e.stopPropagation();
    try {
      console.log('Removing snippet:', snippetId);

      // Find the current question and get the snippet type
      const currentQuestion = questions.find(q => q.aiQueryResults && snippetId in q.aiQueryResults);
      const snippetType = currentQuestion?.aiQueryResults?.[snippetId]?.source_type;

      // Only delete from KB service if it's a qa_pair
      if (snippetType === 'qa_pair') {
        await knowledgeBaseService.deleteKnowledgeBaseItem(snippetId);
      }

      // Always delete from AI service
      try {
        const aiResponse = await aiService.deleteDocument(snippetId);
        console.log('AI Delete Response:', aiResponse);

        if (aiResponse.deleted_count > 0) {
          if (currentQuestion) {
            const updatedAiQueryResults = { ...currentQuestion.aiQueryResults };
            delete updatedAiQueryResults[snippetId];
            
            // Update the cache immediately
            queryClient.setQueryData(['questions', questionnaireId], (oldData: any) => {
              if (!oldData) return oldData;
              
              return {
                ...oldData,
                pages: oldData.pages.map((page: any) => ({
                  ...page,
                  questions: page.questions.map((q: Question) => 
                    q.id === currentQuestion.id 
                      ? {
                          ...q,
                          aiQueryResults: updatedAiQueryResults
                        }
                      : q
                  )
                }))
              };
            });

            // Also update the question in the database
            await updateQuestion.mutateAsync({
              questionId: currentQuestion.id,
              data: {
                aiQueryResults: updatedAiQueryResults
              }
            });
          }

          toast.success('Removed from Knowledge Base');
        } else {
          console.warn('AI deletion returned 0 count');
          throw new Error('AI deletion failed - no items deleted');
        }
      } catch (aiError) {
        console.error('AI deletion failed:', aiError);
        // Still remove from UI if it's a qa_pair since KB deletion was successful
        if (snippetType === 'qa_pair') {
          if (currentQuestion) {
            const updatedAiQueryResults = { ...currentQuestion.aiQueryResults };
            delete updatedAiQueryResults[snippetId];
            
            // Update the cache immediately even if AI service fails
            queryClient.setQueryData(['questions', questionnaireId], (oldData: any) => {
              if (!oldData) return oldData;
              
              return {
                ...oldData,
                pages: oldData.pages.map((page: any) => ({
                  ...page,
                  questions: page.questions.map((q: Question) => 
                    q.id === currentQuestion.id 
                      ? {
                          ...q,
                          aiQueryResults: updatedAiQueryResults
                        }
                      : q
                  )
                }))
              };
            });

            // Also update the question in the database
            await updateQuestion.mutateAsync({
              questionId: currentQuestion.id,
              data: {
                aiQueryResults: updatedAiQueryResults
              }
            });
          }
          toast.success('Removed from Knowledge Base');
          toast.error('AI system update failed - will retry later');
        } else {
          throw aiError;
        }
      }
    } catch (error) {
      console.error('Error removing snippet:', error);
      toast.error('Failed to remove from Knowledge Base');
    }
  };

  const handleTextAnswerUpdate = async (questionId: string, value: string) => {
    try {
      setTextAnswers(prev => ({
        ...prev,
        [questionId]: value
      }));
      await updateQuestion.mutateAsync({
        questionId,
        data: {
          answerText: value
        }
      });
    } catch (error) {
      console.error('Error updating answer text:', error);
      toast.error('Failed to save answer');
    }
  };

  const handleRegenerate = async (questionId: string) => {
    try {
      setRegeneratingQuestions(prev => new Set(prev).add(questionId));
      const question = questions?.find(q => q.id === questionId);
      if (!question || !questionnaireId) return;

      const response = await aiService.generateAnswer({
        question_id: questionId,
        organization_id: question.organizationId,
        question_text: question.questionText,
        additional_info: question.additionalInfo,
        dropdown_options: question.dropdownOptions
      });

      // Update text answers state with new answer
      setTextAnswers(prev => ({
        ...prev,
        [questionId]: response.text_answer
      }));

      // Update the question with all response data
      await updateQuestion.mutateAsync({
        questionId,
        data: {
          answerText: response.text_answer,
          dropdownValue: response.dropdown_answer || undefined,
          aiQueryResults: response.aiQueryResults
        }
      });

      // Update the cache immediately with the new data
      queryClient.setQueryData(['questions', questionnaireId], (oldData: any) => {
        if (!oldData) return oldData;
        
        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => ({
            ...page,
            questions: page.questions.map((q: Question) => 
              q.id === questionId 
                ? {
                    ...q,
                    answerText: response.text_answer,
                    dropdownValue: response.dropdown_answer || undefined,
                    aiQueryResults: response.aiQueryResults
                  }
                : q
            )
          }))
        };
      });

    } catch (error) {
      console.error('Error regenerating answer:', error);
      toast.error('Failed to regenerate answer');
    } finally {
      setRegeneratingQuestions(prev => {
        const next = new Set(prev);
        next.delete(questionId);
        return next;
      });
    }
  };

  // Add a function to get KB sources from aiQueryResults
  const getKnowledgeBaseSources = useCallback((questionId: string) => {
    const question = questions?.find(q => q.id === questionId);
    if (!question?.aiQueryResults) return [];

    return Object.entries(question.aiQueryResults).map(([id, source]) => ({
      id,
      title: source.title,
      content: source.content,
      source_name: source.source_name,
      relevanceScore: source.relevance_score,
      source_type: source.source_type,
      created_at: source.created_at,
      page_number: source.page_number
    })).sort((a, b) => b.relevanceScore - a.relevanceScore);
  }, [questions]);

  // Add effect to fetch next page when filter changes if no questions are visible
  useEffect(() => {
    const filteredQuestions = getFilteredQuestions();
    if (filteredQuestions.length === 0 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [activeFilter, hasNextPage, isFetchingNextPage]);

  return {
    // Data
    questions,
    questionnaire,
    currentQuestionId,
    isLoading,
    activeFilter,
    filterOptions,
    questionsInKB,
    relevantSnippetsByQuestion,
    isRegenerating,
    textAnswers,
    setTextAnswers,
    
    // States
    isSubmitting,
    isUpdating: updateQuestion.isPending,
    regeneratingQuestions,
    submittingQuestions,
    
    // Actions
    setCurrentQuestionId,
    setActiveFilter,
    setIsRegenerating,
    handleDropdownChange,
    handleMarkForLater,
    handleSubmit,
    handleReopen,
    handleAddToKB,
    handleRemoveSnippet,
    getFilteredQuestions,
    setRelevantSnippetsByQuestion,
    handleTextAnswerUpdate,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    handleRegenerate,
    getKnowledgeBaseSources,
  };
}